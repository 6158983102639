import instance from '@/services/api'
import authHeader from '@/services/auth_header'
export class RegisterService {
  constructor () {
    this.baseUrl = process.env.VUE_APP_BASE_BACKEND_URL
  }

  async addNewUser (userData) {
    try {
      const response = await instance.post('/registration', userData, {
        headers: { accept: '*/*' }
      })
      return Promise.resolve(response.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async fetchDepartments () {
    try {
      const response = await instance.get('/contractors/departments', {
        headers: authHeader()
      })
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}
