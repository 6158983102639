import { UserDataContractor } from '@/models/userDataContractor'
import authHeader from '@/services/auth_header'
import authContentTypeHeader from '@/services/auth_content_type_header'
import acceptHeader from '@/services/aceppt_header'
import instance from '@/services/api'
import { InvoiceModel } from '@/models/invoices'
import { OrderModel } from '@/models/orders'
import { PaymentModel } from '@/models/paymentModel'
import Decimal from 'decimal.js'

export class UserService {
  async readUserData () {
    try {
      const response = await instance.get('/users', {
        headers: authHeader()
      })
      return Promise.resolve(new UserDataContractor(response.data))
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async addNewUser (userData) {
    try {
      await instance.post('/registration', userData, {
        headers: { accept: '*/*' }
      })
      return Promise.resolve(true)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async updateUserData (userData) {
    try {
      await instance.put('/users', userData, {
        headers: acceptHeader()
      })
      return Promise.resolve(true)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async fetchAvatar () {
    try {
      const response = await instance.get('/avatar', {
        responseType: 'blob',
        headers: acceptHeader()
      })
      if (response.status === 204) {
        return Promise.resolve(response.status)
      } else {
        return Promise.resolve(response.data)
      }
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async addAvatar (avatarData) {
    try {
      await instance.post('/avatar', avatarData, {
        headers: acceptHeader()
      })
      return Promise.resolve(true)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async deleteAvatar (avatarData) {
    try {
      await instance.delete('/avatar', avatarData, {
        headers: acceptHeader()
      })
      return Promise.resolve(true)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async fetchActiveSubscriptions () {
    try {
      const response = await instance.get('/users/newsletter/subscriptions/', {
        headers: authHeader()
      })
      return Promise.resolve(response.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async subscribeToNewsletter (subscribeMap) {
    try {
      const response = await instance.put('/users/newsletter/subscribe/', subscribeMap, {
        headers: authHeader()
      })
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async unsubscribeToNewsletter (unsubscribeMap) {
    try {
      const response = await instance.put('/users/newsletter/unsubscribe/', unsubscribeMap, {
        headers: authHeader()
      })
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async resetUserPassword () {
    try {
      await instance.get('/users/reset-password', {
        headers: authHeader()
      })
      return Promise.resolve(true)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async synchronizeUsers () {
    try {
      await instance.post('/registration/synchronize', {}, {
        headers: acceptHeader()
      })
      return Promise.resolve(true)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async merchantLimit () {
    try {
      const response = await instance.get('/contractors/limit', {
        headers: authContentTypeHeader()
      })
      return Promise.resolve(response.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async fetchContractorData (contractorId) {
    try {
      const response = await instance.get('/contractors/' + contractorId, {
        headers: authHeader()
      })
      return Promise.resolve(response.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async getInvoices (invoiceType) {
    try {
      const response = await instance.get('/invoice/' + invoiceType, {
        headers: authContentTypeHeader()
      })
      const externalResponse = await instance.get('/invoice/external/' + invoiceType)
      const invoicesList = []
      response.data.forEach(value => {
        invoicesList.push(new InvoiceModel(value))
      })
      if (externalResponse.data.length > 0) {
        externalResponse.data.forEach(value => {
          invoicesList.push(new InvoiceModel(value))
        })
      }
      return Promise.resolve(invoicesList)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async fetchPaidInvoicesData (invoiceId) {
    try {
      const response = await instance.get('/invoice/single/' + invoiceId, {
        headers: authHeader()
      })
      return Promise.resolve(response.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async getOrders (orderType) {
    try {
      const response = await instance.get('/order/' + orderType, { headers: authContentTypeHeader() })
      const orderList = []
      await response.data.forEach(value => {
        orderList.push(new OrderModel(JSON.parse(JSON.stringify(value))))
      })

      orderList.forEach(item => {
        if (item.unavailableOrderId !== null) {
          const index = orderList.findIndex(element => element.id === item.unavailableOrderId)
          if (index !== -1) {
            item.orders = [JSON.parse(JSON.stringify(item)), orderList[index]]
            // orderList.splice(index, 1)
          }
        } else {
          const index = orderList.findIndex(element => element.unavailableOrderId === item.id)

          if (index === -1) {
            item.orders = [JSON.parse(JSON.stringify(item))]
          }
        }
      })

      const orderWithoutEmpty = orderList.filter(element => element.orders.length > 0)

      await orderWithoutEmpty.forEach(order => {
        let ordersTotalAmount = 0
        let ordersShippingPrice = 0
        for (let i = 0; i < order.orders.length; i++) {
          ordersTotalAmount = new Decimal(ordersTotalAmount ?? 0).add(order.orders[i].totalAmount ?? 0).toNumber()
          ordersShippingPrice = new Decimal(ordersShippingPrice).add(order.orders[i].shippingPrice).toNumber()
        }
        order.shippingPrice = ordersShippingPrice.toFixed(2)
        order.totalAmount = new Decimal(ordersTotalAmount).add(ordersShippingPrice).toNumber().toFixed(2)
        if (order.creditPaymentAmount === null) {
          order.creditPaymentAmount = new Decimal(ordersTotalAmount).add(ordersShippingPrice).toNumber().toFixed(2)
        } else if (order.creditPaymentAmount !== 0) {
          const newCreditPaymentAmount = new Decimal(new Decimal(ordersTotalAmount).add(ordersShippingPrice)).sub(order.creditPaymentAmount).toNumber()
          if (newCreditPaymentAmount !== 0) {
            order.creditPaymentAmount = newCreditPaymentAmount.toFixed(2)
          }
        }
      })

      return Promise.resolve(orderWithoutEmpty)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async fetchInvoicePDF (invoiceId, basketName) {
    try {
      const response = await instance.get('/invoice/' + invoiceId + '/download', {
        responseType: 'blob',
        headers: acceptHeader()
      })
      const fileUrl = window.URL.createObjectURL(new Blob([response.data]))
      const fileLink = document.createElement('a')

      fileLink.href = fileUrl
      fileLink.setAttribute('download', basketName + '.pdf')
      document.body.appendChild(fileLink)

      fileLink.click()
      return Promise.resolve(URL.createObjectURL(response.data))
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async fetchExternalInvoicePDF (invoiceId, invoiceNumber) {
    try {
      const response = await instance.get('/invoice/external/' + invoiceId + '/download', {
        responseType: 'blob',
        headers: acceptHeader()
      })
      const fileUrl = window.URL.createObjectURL(new Blob([response.data]))
      const fileLink = document.createElement('a')

      fileLink.href = fileUrl
      fileLink.setAttribute('download', invoiceNumber + '.pdf')
      document.body.appendChild(fileLink)

      fileLink.click()
      return Promise.resolve(URL.createObjectURL(response.data))
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async payInvoices (paymentData) {
    try {
      const response = await instance.post('/payment/credit', paymentData, {
        headers: authContentTypeHeader()
      })
      return Promise.resolve(new PaymentModel(response.data))
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async payExternalInvoices (paymentData) {
    try {
      const response = await instance.post('/payment/credit/external', paymentData, {
        headers: authContentTypeHeader()
      })
      return Promise.resolve(new PaymentModel(response.data))
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async payOrders (paymentData) {
    try {
      const response = await instance.post('/payment/resume', paymentData, { headers: authContentTypeHeader() })
      return Promise.resolve(response.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async fetchNotifications () {
    try {
      const response = await instance.put('/invoice/new', { headers: acceptHeader() })
      return Promise.resolve(response.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async fetchNotificationData () {
    try {
      const response = await instance.get('/invoice/read', { headers: acceptHeader() })
      return Promise.resolve(response.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}
