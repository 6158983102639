import { translatePrizeOrderStatus } from '@/utils/utils'
import { PrizeCategory } from '@/models/prizeModel'

const state = {
  loyaltyProgramCategories: [],
  loyaltyProgramProducts: [],
  ordersList: [],
  ordersTotalRecord: null
}

export const getters = {
  getLoyaltyProgramCategories (state) {
    return state.loyaltyProgramCategories
  },
  getLoyaltyProgramProducts (state) {
    return state.loyaltyProgramProducts
  },
  getOrdersList (state) {
    return state.ordersList
  },
  getOrdersTotalRecord (state) {
    return state.ordersTotalRecord
  }
}

export const actions = {
  fetchAllLoyaltyProgramProducts: async ({ commit, dispatch }, payload) => {
    try {
      const response = await payload.loyaltyProgramService.fetchAllLoyaltyProgramProducts()
      commit('SET_ALL_LOYALTY_PROGRAMS', response)
    } catch (error) {
      await dispatch(
        'snackbarStore/showMessage',
        'Wystąpił błąd, proszę spróbować ponownie',
        { root: true }
      )
      return Promise.reject(error)
    }
  },
  fetchPrizeCategories: async ({ commit, dispatch }, payload) => {
    try {
      const response = await payload.loyaltyProgramService.fetchPrizeCategories()
      commit('SET_PRIZE_CATEGORIES', response)
    } catch (error) {
      await dispatch(
        'snackbarStore/showMessage',
        'Wystąpił błąd, proszę spróbować ponownie',
        { root: true }
      )
      return Promise.reject(error)
    }
  },
  fetchPrizesByCategory: async ({ commit, dispatch }, payload) => {
    try {
      const response = await payload.loyaltyProgramService.fetchPrizesByCategory(payload.categoryId)
      commit('SET_PRIZES_BY_CATEGORY', response)
    } catch (error) {
      await dispatch(
        'snackbarStore/showMessage',
        'Wystąpił błąd, proszę spróbować ponownie',
        { root: true }
      )
      return Promise.reject(error)
    }
  },
  fetchPrizeOrder: async ({ commit, dispatch }, payload) => {
    try {
      const response = await payload.loyaltyProgramService.fetchPrizeOrder(payload.prizeId)
      return response
    } catch (error) {
      await dispatch(
        'snackbarStore/showMessage',
        'Wystąpił błąd, proszę spróbować ponownie',
        { root: true }
      )
      return Promise.reject(error)
    }
  },
  fetchSinglePrize: async ({ commit, dispatch }, payload) => {
    try {
      const response = await payload.loyaltyProgramService.fetchSinglePrize(payload.prizeId)
      commit('SET_SINGLE_PRIZE', response)
      return response
    } catch (error) {
      await dispatch(
        'snackbarStore/showMessage',
        'Wystąpił błąd, proszę spróbować ponownie',
        { root: true }
      )
      return Promise.reject(error)
    }
  },
  fetchOrders: async ({ commit, dispatch }, payload) => {
    try {
      const response = await payload.loyaltyProgramService.fetchOrders()
      commit('SET_ORDERS', response)
    } catch (error) {
      await dispatch(
        'snackbarStore/showMessage',
        'Wystąpił błąd, proszę spróbować ponownie',
        { root: true }
      )
      return Promise.reject(error)
    }
  },
  orderPage: async ({ commit, dispatch }, payload) => {
    try {
      const response = await payload.loyaltyProgramService(payload.page !== 0 ? payload.page * 10 : 0, payload.page !== 0 ? (payload.page * 10) + 9 : payload.page + 9)
      commit('ON_ORDER_PAGE', response)
    } catch (error) {
      await dispatch(
        'snackbarStore/showMessage',
        'Wystąpił błąd, proszę spróbować ponownie',
        { root: true }
      )
      return Promise.reject(error)
    }
  },
  editPrizeOrder: async ({ commit, dispatch }, payload) => {
    try {
      await payload.loyaltyProgramService.editPrizeOrder(payload.editingPrize)
      commit('EDIT_PRIZE_ORDER', payload.editingPrize)
    } catch (error) {
      await dispatch(
        'snackbarStore/showMessage',
        'Wystąpił błąd, proszę spróbować ponownie',
        { root: true }
      )
      return Promise.reject(error)
    }
  },
  editPrizeCategory: async ({ commit, dispatch }, payload) => {
    try {
      commit('EDIT_PRIZE_CATEGORY', payload.categoryIndex)
    } catch (error) {
      await dispatch(
        'snackbarStore/showMessage',
        'Wystąpił błąd, proszę spróbować ponownie',
        { root: true }
      )
      return Promise.reject(error)
    }
  },
  postPrizeCategories: async ({ commit, dispatch }, payload) => {
    try {
      await payload.loyaltyProgramService.postPrizeCategories(payload.loyaltyNewCategory)
      if (payload.save) {
        commit('SAVE_PRIZE_CATEGORIES', payload)
      } else {
        commit('POST_PRIZE_CATEGORIES', payload)
      }
      await dispatch(
        'snackbarStore/showMessage',
        'Zapisano zmiany',
        { root: true }
      )
    } catch (error) {
      await dispatch(
        'snackbarStore/showMessage',
        'Wystąpił błąd, proszę spróbować ponownie',
        { root: true }
      )
      return Promise.reject(error)
    }
  },
  deletePrizeCategory: async ({ commit, dispatch }, payload) => {
    try {
      await payload.loyaltyProgramService.deletePrizeCategory(payload.categoryIdToRemove)
      commit('DELETE_PRIZE_CATEGORY', payload.categoryIdToRemove)
    } catch (error) {
      await dispatch(
        'snackbarStore/showMessage',
        'Wystąpił błąd, proszę spróbować ponownie',
        { root: true }
      )
      return Promise.reject(error)
    }
  },
  setDisabledPrizeCategories: async ({ commit, dispatch }) => {
    commit('SET_DISABLE_PRIZE_CATEGORY')
  },
  updateCategories: async ({ commit, dispatch }, payload) => {
    commit('UPDATE_CATEGORY_LIST', payload)
  },
  deletePrize: async ({ commit, dispatch }, payload) => {
    try {
      await payload.loyaltyProgramService.deletePrize(payload.prizeId)
      commit('DELETE_PRIZE', payload.prizeId)
    } catch (error) {
      await dispatch(
        'snackbarStore/showMessage',
        'Wystąpił błąd, proszę spróbować ponownie',
        { root: true }
      )
    }
  },
  postPrizeImage: async ({ commit, dispatch }, payload) => {
    try {
      await payload.loyaltyProgramService.postPrizeImage(payload.imageData)
    } catch (error) {
      await dispatch(
        'snackbarStore/showMessage',
        'Wystąpił błąd, proszę spróbować ponownie',
        { root: true }
      )
    }
  },
  addPrize: async ({ commit, dispatch }, payload) => {
    try {
      await payload.loyaltyProgramService.addPrize(payload.prizeMap)
    } catch (error) {
      await dispatch(
        'snackbarStore/showMessage',
        'Wystąpił błąd, proszę spróbować ponownie',
        { root: true }
      )
    }
  },
  updatePrize: async ({ commit, dispatch }, payload) => {
    try {
      await payload.loyaltyProgramService.updatePrize(payload.prizeMap)
    } catch (error) {
      await dispatch(
        'snackbarStore/showMessage',
        'Wystąpił błąd, proszę spróbować ponownie',
        { root: true }
      )
    }
  }
}

export const mutations = {
  SET_ALL_LOYALTY_PROGRAMS: (state, data) => {
    state.loyaltyProgramProducts = data
  },
  SET_PRIZE_CATEGORIES: (state, data) => {
    state.loyaltyProgramCategories = data
  },
  SET_PRIZES_BY_CATEGORY: (state, data) => {
    state.loyaltyProgramProducts = data
  },
  SET_SINGLE_PRIZE: (state, data) => {
    const prizeToChange = state.loyaltyProgramProducts.findIndex(prize => prize.id === data.id)
    state.loyaltyProgramProducts[prizeToChange].codedImage = data.codedImage
  },
  SET_ORDERS: (state, data) => {
    state.ordersList = data.purchasedPrizes
    state.ordersTotalRecord = data.count
  },
  ON_ORDER_PAGE: (state, data) => {
    state.ordersList = data.purchasedPrizes
  },
  EDIT_PRIZE_ORDER: (state, data) => {
    const ordersToChange = state.ordersList.findIndex(orders => orders.id === data.id)
    state.ordersList[ordersToChange].status = translatePrizeOrderStatus(data.status)
    state.ordersList[ordersToChange].pendingDate = data.pendingDate
    state.ordersList[ordersToChange].issueDate = data.issueDate
  },
  EDIT_PRIZE_CATEGORY: (state, data) => {
    state.loyaltyProgramCategories[data].disabled = !state.loyaltyProgramCategories[data].disabled
  },
  POST_PRIZE_CATEGORIES: (state, data) => {
    state.loyaltyProgramCategories.push(new PrizeCategory({ id: data.newCategoryId, categoryName: data.categoryName }))
  },
  DELETE_PRIZE_CATEGORY: (state, data) => {
    const categoryToDelete = state.loyaltyProgramCategories.findIndex(category => category.id === data.id)
    state.loyaltyProgramCategories.splice(categoryToDelete, 1)
  },
  SET_DISABLE_PRIZE_CATEGORY: (state) => {
    for (let i = 0; i < state.loyaltyProgramCategories.length; i++) {
      state.loyaltyProgramCategories[i].disabled = true
    }
  },
  UPDATE_CATEGORY_LIST: (state, data) => {
    state.loyaltyProgramCategories = data
  },
  SAVE_PRIZE_CATEGORIES: (state, data) => {
    state.loyaltyProgramCategories = data.loyaltyNewCategory
    for (let i = 0; i < state.loyaltyProgramCategories.length; i++) {
      state.loyaltyProgramCategories[i].disabled = true
    }
  },
  DELETE_PRIZE: (state, data) => {
    const prizeToDelete = state.loyaltyProgramProducts.findIndex(prize => prize.id === data.id)
    state.loyaltyProgramProducts.splice(prizeToDelete, 1)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
