import { requestStateEnum } from '@/enums/requestStateEnum'
const state = {
  requestStatus: requestStateEnum.NOT_SENT,
  selectedAddress: null,
  transactionAdress: null,
  multipleTransactionAddress: [],
  addressList: []
}

const getters = {
  getAddressList (state) {
    return state.addressList
  },
  selectedAddress (state) {
    return state.selectedAddress
  },
  getTransactionAddress (state) {
    return state.transactionAdress
  },
  getMultipleTransactionAddress (state) {
    return state.multipleTransactionAddress
  }
}

const actions = {
  fetchAddressList: async (context, payload) => {
    context.commit('SET_REQUEST_STATUS', requestStateEnum.IN_PROGRESS)
    try {
      const addressFromApi = await payload.addressService.getAddressList(payload.orderId)
      context.commit('SET_REQUEST_STATUS', requestStateEnum.SUCCESS)
      context.commit('SET_ADDRESS_LIST', addressFromApi)
    } catch (e) {
      context.commit('SET_REQUEST_STATUS', requestStateEnum.ERROR)
      Promise.reject(e)
    }
  },
  getChosenAddress: async (context, payload) => {
    try {
      const curAdress = await payload.addressService.getChosenAddress(payload.orderId)
      context.commit('SET_SELECTED_ADDRESS_ID', curAdress.data)
    } catch (e) {
      context.commit('SET_REQUEST_STATUS', requestStateEnum.ERROR)
      Promise.reject(e)
    }
  },
  setSelectedAddress: async ({ commit, dispatch }, payload) => {
    try {
      if (payload.item) {
        await payload.addressService.assignDeliveryAddress(payload.basketId, payload.addressMap)
        commit('SET_SELECTED_ADDRESS_ID', payload.item)
      } else {
        await payload.addressService.assignDeliveryAddress(payload.basketId, payload.addressMap)
        commit('SET_SELECTED_ADDRESS_ID', null)
        return
      }
      commit('SET_REQUEST_STATUS', requestStateEnum.SUCCESS)

      await dispatch('snackbarStore/showMessage', 'Wybrano adres dostawy', { root: true })
    } catch (e) {
      commit('SET_REQUEST_STATUS', requestStateEnum.ERROR)
      await dispatch('snackbarStore/showMessage', 'Wystąpił błąd, proszę spróbować ponownie', { root: true })
      return Promise.reject(e)
    }
  },
  setShoppingCarts: (context, addressList) => {
    context.commit('SET_ADDRESS_LIST', addressList)
  },
  addAddress: async ({ commit, dispatch }, payload) => {
    try {
      const response = await payload.addressService.addAddress(payload.newItem)
      const address = { ...payload.newItem, id: response.data }
      commit('SET_REQUEST_STATUS', requestStateEnum.SUCCESS)
      commit('ADD_ADDRESS', address)
      await dispatch('snackbarStore/showMessage', 'Pomyślnie dodano nowy adres!', { root: true })
    } catch (e) {
      commit('SET_REQUEST_STATUS', requestStateEnum.ERROR)
      await dispatch('snackbarStore/showMessage', 'Wystąpił błąd, proszę spróbować ponownie', { root: true })
      Promise.reject(e)
    }
  },
  updateAddress: async ({ commit, dispatch }, payload) => {
    try {
      await payload.addressService.updateAddress(payload.item)
      commit('SET_REQUEST_STATUS', requestStateEnum.SUCCESS)
      commit('UPDATE_ADDRESS', payload.item)
      await dispatch('snackbarStore/showMessage', 'Pomyślnie zmieniono adres!', { root: true })
    } catch (e) {
      commit('SET_REQUEST_STATUS', requestStateEnum.ERROR)
      await dispatch('snackbarStore/showMessage', 'Wystąpił błąd, proszę spróbować ponownie', { root: true })
      Promise.reject(e)
    }
  },
  removeAddress: async ({ commit, dispatch }, payload) => {
    try {
      await payload.addressService.deleteAddress(payload.id)
      commit('REMOVE_ADDRESS_ID', payload.id)
      await dispatch('snackbarStore/showMessage', 'Usunięto adres!', { root: true })
    } catch (e) {
      commit('SET_REQUEST_STATUS', requestStateEnum.ERROR)
      await dispatch('snackbarStore/showMessage', 'Wystąpił błąd, proszę spróbować ponownie', { root: true })
      Promise.reject(e)
    }
  },
  fetchTransactionAddress: async ({ commit, dispatch }, payload) => {
    if (payload.basketId.length > 36) {
      const basketIds = payload.basketId.split(',')
      try {
        for (let i = 0; i < basketIds.length; i++) {
          const response = await payload.addressService.getChosenAddress(basketIds[i])
          commit('SET_MULTIPLE_TRANSACTION_ADDRESS', response.data)
        }
      } catch (e) {
        commit('SET_REQUEST_STATUS', requestStateEnum.ERROR)
        await dispatch('snackbarStore/showMessage', 'Wystąpił błąd podczas pobierania adresu, proszę spróbować ponownie.', { root: true })
        Promise.reject(e)
      }
    } else {
      try {
        const response = await payload.addressService.getChosenAddress(payload.basketId)
        commit('SET_TRANSACTION_ADDRESS', response.data)
        commit('SET_SELECTED_ADDRESS_ID', response.data)
      } catch (e) {
        commit('SET_REQUEST_STATUS', requestStateEnum.ERROR)
        await dispatch('snackbarStore/showMessage', 'WWystąpił błąd podczas pobierania adresu, proszę spróbować ponownie.', { root: true })
        Promise.reject(e)
      }
    }
  }
}

const mutations = {
  SET_REQUEST_STATUS: (state, status) => {
    state.requestStatus = status
  },
  SET_ADDRESS_LIST: (state, addressList) => {
    state.addressList.length > 0 ? state.addressList.splice(0, state.addressList.length, ...addressList) : state.addressList = [...addressList]
    const chosenAddress = state.addressList.filter(function (address) {
      return address.chosen
    })
    if (chosenAddress.length > 0) {
      state.selectedAddress = chosenAddress[0]
    } else {
      state.selectedAddress = null
    }
  },
  UPDATE_ADDRESS: (state, addressList) => {
    const elementToDelete = state.addressList.findIndex(element => element.id === addressList.id)
    state.addressList.splice(elementToDelete, 1, addressList)
    state.selectedAddress = addressList
  },
  SET_SELECTED_ADDRESS_ID: (state, item) => {
    if (item) {
      // eslint-disable-next-line array-callback-return
      state.addressList.map(function (address) {
        if (address.id === item.id) {
          address.chosen = true
        } else {
          address.chosen = false
        }
      })
    } else {
      // eslint-disable-next-line array-callback-return
      state.addressList.map(function (address) {
        address.chosen = false
      })
      state.selectedAddress = item
    }
    state.selectedAddress = item
  },
  ADD_ADDRESS: (state, address) => {
    state.addressList.push(address)
    state.selectedAddress = address
  },
  REMOVE_ADDRESS_ID: (state, addressId) => {
    let indexOfElementToRemove
    for (let i = 0; i < state.addressList.length; i++) {
      if (state.addressList[i].id === addressId) {
        indexOfElementToRemove = i
        break
      }
    }
    state.addressList.splice(indexOfElementToRemove, 1)
  },
  SET_TRANSACTION_ADDRESS: (state, address) => {
    state.transactionAdress = address
  },
  SET_MULTIPLE_TRANSACTION_ADDRESS: (state, address) => {
    state.multipleTransactionAddress.push(address)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
