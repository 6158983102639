import authHeader from '@/services/auth_header'
import authContentTypeHeader from '@/services/auth_content_type_header'
import { NewsletterModel } from '@/models/newsletterModel'
import { NewsletterTemplatesModel } from '@/models/newsletterTemplatesModel'
import instance from '@/services/api'

export class NewsletterService {
  async addNewsletterTemplate (newsletterData) {
    try {
      await instance.post('/newsletter/templates', newsletterData, { headers: authContentTypeHeader() })
      return Promise.resolve(true)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async fetchNewsletterTemplate (id) {
    try {
      const response = await instance.get('/newsletter/templates/' + id, { headers: authHeader() })
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async fetchNewsletterTemplates () {
    try {
      const response = await instance.get('/newsletter/templates', { headers: authHeader() })
      const newsletterList = []
      response.data.forEach((value) => {
        newsletterList.push(new NewsletterTemplatesModel(value))
      })
      return Promise.resolve(newsletterList)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async fetchNewsletter () {
    try {
      const response = await instance.get('/newsletter', { headers: authHeader() })
      const newsletterList = []
      response.data.newsletters.forEach((value) => {
        newsletterList.push(new NewsletterModel(value))
      })
      return Promise.resolve(newsletterList)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async fetchSelectedNewsletter (id) {
    try {
      const response = await instance.get('/newsletter/' + id, { headers: authHeader() })
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async fetchNewsletterCategories () {
    try {
      const response = await instance.get('/newsletter/categories', { headers: authHeader() })
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async addNewsletter (newsletterData) {
    try {
      await instance.post('/newsletter', newsletterData, { headers: authContentTypeHeader() })
      return Promise.resolve(true)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async sendNewsletter (newsletterData, id) {
    try {
      await instance.post('/newsletter/send/' + id, newsletterData, { headers: authContentTypeHeader() })
      return Promise.resolve(true)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async updateNewsletter (newsletterData) {
    try {
      await instance.put('/newsletter', newsletterData, { headers: authContentTypeHeader() })
      return Promise.resolve(true)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async updateNewsletterTemplate (newsletterTemplate) {
    try {
      const response = await instance.put('/newsletter/templates', newsletterTemplate, { headers: authContentTypeHeader() })
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async sendNewsletterCategory (newsletterCategory) {
    try {
      await instance.post('/newsletter/categories', newsletterCategory, { headers: authContentTypeHeader() })
      return Promise.resolve(true)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async deleteNewsletterTemplate (id) {
    try {
      await instance.delete('/newsletter/templates/' + id, { headers: authHeader() })
      return Promise.resolve(true)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async deleteNewsletter (id) {
    try {
      await instance.delete('/newsletter/' + id, { headers: authHeader() })
      return Promise.resolve(true)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}
