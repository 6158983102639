import { requestStateEnum } from '@/enums/requestStateEnum'
import SecureLS from 'secure-ls'
import router from '@/router'
import { NotificationModel } from '@/models/notificationData'
import { ContractorCreditModel } from '@/models/contractorCredit'
import { ContractorPreview } from '@/models/contractorPreview'
import { invoicesStatusTypeEnum as InvoicesStatusTypeEnum } from '@/enums/InvoicesStatusTypeEnum'

const ls = new SecureLS({ isCompression: false })
const user = ls.get('vuex')
const state =
  user.userStore?.isAdmin || user.userStore?.isSuperAdmin
    ? {
        requestStatus: requestStateEnum.NOT_SENT,
        userData: null,
        avatarData: null,
        contractorData: null,
        creditLimit: null,
        userProfile: {},
        userDetails: '',
        userRoles: null,
        isAdmin: user.userStore.isAdmin,
        isSuperAdmin: user.userStore.isSuperAdmin,
        paidInvoices: [],
        unpaidInvoices: [],
        overdueInvoices: [],
        externalInvoices: [],
        paidOrders: [],
        unpaidOrders: [],
        statusValue: null,
        notifications: [],
        notificationData: []
      }
    : {
        requestStatus: requestStateEnum.NOT_SENT,
        userData: null,
        avatarData: null,
        contractorData: null,
        creditLimit: null,
        userProfile: {},
        userDetails: '',
        userRoles: null,
        isAdmin: false,
        isSuperAdmin: false,
        paidInvoices: [],
        unpaidInvoices: [],
        overdueInvoices: [],
        externalInvoices: [],
        paidOrders: [],
        unpaidOrders: [],
        statusValue: null,
        notifications: [],
        notificationData: []
      }

const getters = {
  getUserData (state) {
    return state.userData
  },
  getAvatarData (state) {
    return state.avatarData
  },
  getContractorData (state) {
    return state.contractorData
  },
  getCreditLimit (state) {
    return state.creditLimit
  },
  getUserProfile (state) {
    return state.userProfile
  },
  getUserDetails (state) {
    return state.userDetails
  },
  getUserRoles (state) {
    return state.userRoles?.includes('admin')
  },
  getIsSuperAdmin (state) {
    return state.isSuperAdmin
  },
  getIsAdmin (state) {
    return state.isAdmin
  },
  getPaidInvoices (state) {
    return state.paidInvoices
  },
  getUnpaidInvoices (state) {
    return state.unpaidInvoices
  },
  getOverDueInvoices (state) {
    return state.overdueInvoices
  },
  getExternalInvoices (state) {
    return state.externalInvoices
  },
  getTotalExternalPrice (state) {
    let totalPrice = 0
    state.externalInvoices.forEach(element => {
      totalPrice += element.leftToPay
    })
    return totalPrice
  },
  getPaidOrders (state) {
    return state.paidOrders
  },
  getUnpaidOrders (state) {
    return state.unpaidOrders
  },
  getStatusValue (state) {
    return state.statusValue
  },
  getNotificationValue (state) {
    return state.notifications
  },
  getNotificationData (state) {
    return state.notificationData
  }
}

const actions = {
  fetchUserData: async (context, payload) => {
    context.commit('SET_REQUEST_STATUS', requestStateEnum.IN_PROGRESS)
    try {
      const userDataServer = await payload.userService.readUserData()
      context.commit('SET_REQUEST_STATUS', requestStateEnum.SUCCESS)
      context.commit('SET_USER_DATA', userDataServer)
    } catch (e) {
      context.commit('SET_REQUEST_STATUS', requestStateEnum.ERROR)
      Promise.reject(e)
    }
  },
  fetchAvatar: async ({ commit, dispatch }, payload) => {
    try {
      const response = await payload.userService.fetchAvatar()
      commit('FETCH_AVATAR', response)
    } catch (error) {
      commit('FETCH_AVATAR', 204)
      await dispatch(
        'snackbarStore/showMessage',
        'Wystąpił błąd, proszę spróbować ponownie',
        { root: true }
      )
    }
  },
  addAvatar: async ({ commit, dispatch }, payload) => {
    try {
      await payload.userService.addAvatar(payload.avatarData)
      const response = await payload.userService.fetchAvatar()
      commit('FETCH_AVATAR', response)
    } catch (error) {
      await dispatch(
        'snackbarStore/showMessage',
        'Wystąpił błąd, proszę spróbować ponownie',
        { root: true }
      )
    }
  },
  deleteAvatar: async ({ commit, dispatch }, payload) => {
    try {
      await payload.userService.deleteAvatar(payload.avatarData)
      commit('DELETE_AVATAR')
    } catch (error) {
      await dispatch(
        'snackbarStore/showMessage',
        'Wystąpił błąd, proszę spróbować ponownie',
        { root: true }
      )
    }
  },
  fetchContractorData: async ({ commit, dispatch }, payload) => {
    try {
      const response = await payload.userService.fetchContractorData(payload.contractorId)
      commit('SET_CONTRACTOR', response)
    } catch (error) {
      await dispatch(
        'snackbarStore/showMessage',
        'Wystąpił błąd, proszę spróbować ponownie1',
        { root: true }
      )
    }
  },
  fetchPaidInvoices: async ({ commit, dispatch }, payload) => {
    try {
      const response = await payload.userService.getInvoices(InvoicesStatusTypeEnum.PAID)
      commit('SET_PAID_INVOICES', response)
    } catch (error) {
      await dispatch(
        'snackbarStore/showMessage',
        'Wystąpił błąd, proszę spróbować ponownie',
        { root: true }
      )
    }
  },
  fetchUnpaidInvoices: async ({ commit, dispatch }, payload) => {
    try {
      const response = await payload.userService.getInvoices(InvoicesStatusTypeEnum.UNPAID)
      commit('SET_UNPAID_INVOICES', response)
    } catch (error) {
      await dispatch(
        'snackbarStore/showMessage',
        'Wystąpił błąd, proszę spróbować ponownie',
        { root: true }
      )
    }
  },
  fetchOverdueInvoices: async ({ commit, dispatch }, payload) => {
    try {
      const response = await payload.userService.getInvoices(InvoicesStatusTypeEnum.OVERDUE)
      commit('SET_OVERDUE_INVOICES', response)
    } catch (error) {
      await dispatch(
        'snackbarStore/showMessage',
        'Wystąpił błąd, proszę spróbować ponownie',
        { root: true }
      )
    }
  },
  fetchPaidOrders: async ({ commit, dispatch }, payload) => {
    try {
      const response = await payload.userService.getOrders('paid')
      commit('SET_PAID_ORDERS', response)
    } catch (error) {
      await dispatch(
        'snackbarStore/showMessage',
        'Wystąpił błąd, proszę spróbować ponownie',
        { root: true }
      )
    }
  },
  fetchUnpaidOrders: async ({ commit, dispatch }, payload) => {
    try {
      const response = await payload.userService.getOrders('unpaid')
      commit('SET_UNPAID_ORDERS', response)
    } catch (error) {
      await dispatch(
        'snackbarStore/showMessage',
        'Wystąpił błąd, proszę spróbować ponownie',
        { root: true }
      )
    }
  },
  fetchMerchantLimit: async ({ commit, dispatch }, payload) => {
    try {
      const response = await payload.userService.merchantLimit()
      commit('SET_CREDIT_LIMIT', response)
      return response
    } catch (e) {
      await dispatch(
        'snackbarStore/showMessage',
        'Wystąpił błąd podczas pobierania kredytu, proszę spróbować ponownie',
        { root: true }
      )
      commit('SET_REQUEST_STATUS', requestStateEnum.ERROR)
      return Promise.reject(e)
    }
  },
  getUserInformation: async ({ commit, dispatch }, payload) => {
    try {
      commit('SET_USER_PROFILE', payload.userProfile)
      commit('SET_USER_ROLES', payload.userRole)
    } catch (error) {
      await dispatch(
        'snackbarStore/showMessage',
        'Wystąpił błąd podczas pobrania profilu, proszę spróbować ponownie',
        { root: true }
      )
      commit('SET_REQUEST_STATUS', requestStateEnum.ERROR)
    }
  },
  getUserDetails: async ({ commit, dispatch }, payload) => {
    try {
      const response = await payload.userService.readUserData()
      commit('SET_USER_DETAILS', response.contractor.name)
    } catch (error) {
      await dispatch(
        'snackbarStore/showMessage',
        'Wystąpił błąd podczas pobierania danych użytkownika, proszę spróbować ponownie',
        { root: true }
      )
    }
  },
  paySelectedInvoices: async ({ commit, dispatch }, payload) => {
    try {
      const response = await payload.userService.payInvoices(payload.paymentData)
      if (response.redirectUrl) {
        window.open(response.redirectUrl, '_self')
      }
      return response
    } catch (error) {
      await dispatch(
        'snackbarStore/showMessage',
        'Wystąpił błąd podczas opłacania faktury, proszę spróbować ponownie',
        { root: true }
      )
      commit('SET_REQUEST_STATUS', requestStateEnum.ERROR)
      return Promise.reject(error)
    }
  },
  payExternalSelectedInvoices: async ({ commit, dispatch }, payload) => {
    try {
      const response = await payload.userService.payExternalInvoices(payload.paymentData)
      if (response.redirectUrl) {
        window.open(response.redirectUrl, '_self')
      }
      return response
    } catch (error) {
      await dispatch(
        'snackbarStore/showMessage',
        'Wystąpił błąd podczas opłacania faktury, proszę spróbować ponownie',
        { root: true }
      )
      commit('SET_REQUEST_STATUS', requestStateEnum.ERROR)
      return Promise.reject(error)
    }
  },
  fetchExternalInvoicesData: async ({ commit, dispatch }, payload) => {
    commit('RESET_EXTERNAL_INVOICES')
    if (payload.invoiceId.length > 36) {
      const invoiceIds = payload.invoiceId.split(',')
      try {
        for (let i = 0; i < invoiceIds.length; i++) {
          const response = await payload.userService.fetchPaidInvoicesData(invoiceIds[i])
          commit('SET_MULTIPLE_EXTERNAL_INVOICES', response)
        }
      } catch (error) {
        await dispatch(
          'snackbarStore/showMessage',
          'Wystąpił błąd podczas opłacania faktury, proszę spróbować ponownie',
          { root: true }
        )
        commit('SET_REQUEST_STATUS', requestStateEnum.ERROR)
        return Promise.reject(error)
      }
    } else {
      try {
        const response = await payload.userService.fetchPaidInvoicesData(payload.invoiceId)
        commit('SET_EXTERNAL_INVOICES', response)
      } catch (error) {
        await dispatch(
          'snackbarStore/showMessage',
          'Wystąpił błąd podczas opłacania faktury, proszę spróbować ponownie',
          { root: true }
        )
        commit('SET_REQUEST_STATUS', requestStateEnum.ERROR)
        return Promise.reject(error)
      }
    }
  },
  paySelectedOrders: async ({ commit, dispatch }, payload) => {
    try {
      const response = await payload.userService.payOrders(payload.paymentDetails)
      if (response.redirectUrl) {
        window.open(response.redirectUrl, '_self')
      } else {
        await router.push({ name: 'Status of the resume payment', query: { orderId: payload.paymentDetails.orderIds } })
      }
      return Promise.resolve(response.data)
    } catch (error) {
      await dispatch(
        'snackbarStore/showMessage',
        'Wystąpił błąd podczas opłacania zamówienia, proszę spróbować ponownie',
        { root: true }
      )
      commit('SET_REQUEST_STATUS', requestStateEnum.ERROR)
      return Promise.reject(error)
    }
  },
  changeStatusValue: async ({ commit, dispatch }, payload) => {
    try {
      commit('CHANGE_APP_STATUS', payload.status)
    } catch (error) {
      await dispatch(
        'snackbarStore/showMessage',
        'Wystąpił błąd podczas opłacania faktury, proszę spróbować ponownie',
        { root: true }
      )
    }
  },
  synchronizeUsers: async ({ dispatch }, payload) => {
    try {
      await payload.userService.synchronizeUsers()
      await dispatch('snackbarStore/showMessage', 'Synchronizacja zakończona', { root: true })
    } catch (error) {
      await dispatch(
        'snackbarStore/showMessage',
        'Wystąpił błąd podczas synchronizacji użytkowników, proszę spróbować ponownie',
        { root: true }
      )
    }
  },
  fetchNotifications: async ({ commit, dispatch }, payload) => {
    try {
      const response = await payload.userService.fetchNotifications()
      commit('SET_NOTIFICATIONS_VALUE', response)
      return response
    } catch (error) {
      await dispatch(
        'snackbarStore/showMessage',
        'Wystąpił błąd podczas pobierania powiadomień, proszę spróbować ponownie',
        { root: true }
      )
    }
  },
  fetchNotificationData: async ({ commit, dispatch }, payload) => {
    try {
      const response = await payload.userService.fetchNotificationData()
      commit('SET_NOTIFICATION_DATA', response)
      return response
    } catch (error) {
      await dispatch(
        'snackbarStore/showMessage',
        'Wystąpił błąd podczas pobierania powiadomień, proszę spróbować ponownie',
        { root: true }
      )
    }
  }
}

const mutations = {
  SET_USER_DATA: (state, status) => {
    state.userData = status
  },
  FETCH_AVATAR: (state, avatar) => {
    if (avatar !== 204) {
      const avatarThumbnail = URL.createObjectURL(avatar)
      state.avatarData = avatarThumbnail
    } else {
      state.avatarData = avatar
    }
  },
  ADD_AVATAR: (state, avatar) => {
    state.avatarData = avatar
  },
  DELETE_AVATAR: (state) => {
    state.avatarData = 204
  },
  SET_CONTRACTOR: (state, contractor) => {
    state.contractorData = new ContractorPreview(contractor)
  },
  SET_REQUEST_STATUS: (state, status) => {
    state.requestStatus = status
  },
  SET_PAID_INVOICES: (state, paidInvoices) => {
    state.paidInvoices = paidInvoices
  },
  SET_UNPAID_INVOICES: (state, unpaidInvoices) => {
    state.unpaidInvoices = unpaidInvoices
  },
  SET_OVERDUE_INVOICES: (state, overdueInvoices) => {
    state.overdueInvoices = overdueInvoices
  },
  RESET_EXTERNAL_INVOICES: (state) => {
    state.externalInvoices = []
  },
  SET_EXTERNAL_INVOICES: (state, externalInvoices) => {
    state.externalInvoices.push(externalInvoices)
  },
  SET_MULTIPLE_EXTERNAL_INVOICES: (state, externalInvoices) => {
    state.externalInvoices.push(externalInvoices)
  },
  SET_PAID_ORDERS: (state, paidOrders) => {
    state.paidOrders = paidOrders
  },
  SET_UNPAID_ORDERS: (state, unpaidOrders) => {
    state.unpaidOrders = unpaidOrders
  },
  SET_CREDIT_LIMIT: (state, status) => {
    state.creditLimit = new ContractorCreditModel(status)
  },
  SET_USER_PROFILE: (state, profile) => {
    state.userProfile = profile
  },
  SET_USER_DETAILS: (state, details) => {
    state.userDetails = details
  },
  SET_USER_ROLES: (state, roles) => {
    state.userRoles = roles
    state.isSuperAdmin = state.userRoles?.includes('super_admin')
    state.isAdmin = state.userRoles?.includes('admin')
  },
  CHANGE_APP_STATUS: (state, status) => {
    state.statusValue = status
  },
  SET_NOTIFICATIONS_VALUE: (state, notifications) => {
    notifications.forEach(notification => state.notifications.push(new NotificationModel(notification)))
  },
  SET_NOTIFICATION_DATA: (state, notifications) => {
    state.notificationData = []
    notifications.forEach(notification => state.notificationData.push(new NotificationModel(notification)))
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
