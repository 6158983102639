<template>
  <div class="p-col-12 p-d-flex p-jc-center">
    <Breadcrumb :home="home" :model="breadcrumbs">
      <template #item="{item}">
        <router-link class="p-menuitem link-wrapper" :to="item.path" data-test="test">{{ item.label }}</router-link>
      </template>
    </Breadcrumb>
  </div>
</template>

<script>
import { ref, onMounted, watch, computed } from 'vue'
import { activedRoutes } from '@/utils/utils'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import Breadcrumb from 'primevue/breadcrumb'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Breadcrumbs',
  components: { Breadcrumb },
  setup () {
    const breadcrumbs = ref(null)
    const home = ref(null)
    const route = useRoute()
    const store = useStore()
    const productsService = store.getters['serviceStore/getProductsService']
    const routeObj = computed(() => route && JSON.parse(JSON.stringify(route.meta)))
    onMounted(() => {
      breadcrumbs.value = activedRoutes.slice(1, activedRoutes.length)
      home.value = activedRoutes[0]
    })
    watch(
      () => routeObj.value,
      async (currentValue, oldValue) => {
        breadcrumbs.value = activedRoutes.slice(1, activedRoutes.length)
        home.value = activedRoutes[0]
        if (route.params.id) {
          await store.dispatch('productStore/fetchProductDetails', {
            productsService,
            id: route.params.id
          })
          const productName = computed(() => store.getters['productStore/getName'])
          breadcrumbs.value[1].label = productName.value
        }
      }
    )

    return { breadcrumbs, activedRoutes, home }
  }
}
</script>

<style scoped lang="scss">
.p-menuitem {
  text-decoration: none;
  color: $navigation_black;
  width: 30%;
}
/* ::v-deep(.p-breadcrumb ul li.p-breadcrumb-chevron)::before {
    color: $navigation_black;
  } */
::v-deep(.router-link-active) {
  color: $primary_color;
}
::v-deep(.p-breadcrumb) {
  width: 80%;
  ul {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
}
</style>
