import { Address } from '@/models/address'
import authContentTypeHeader from '@/services/auth_content_type_header'
import authHeader from '@/services/auth_header'
import instance from '@/services/api'
export class AddressService {
  async deleteAddress (id) {
    try {
      const response = await instance.delete(`/contractors/delivery/${id}`, { headers: authContentTypeHeader() })
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async addAddress (data) {
    try {
      const response = await instance.post('/contractors/delivery', data, { headers: authContentTypeHeader() })
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async updateAddress (data) {
    const { id } = data

    try {
      const response = await instance.put(`/contractors/delivery/${id}`, data, { headers: authContentTypeHeader() })
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async assignDeliveryAddress (basketId, addressMap) {
    try {
      const response = await instance.put('/order/' + basketId + '/delivery', addressMap, { headers: authContentTypeHeader() })
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async getChosenAddress (basketId) {
    try {
      const response = await instance.get('/order/' + basketId + '/delivery', { headers: authContentTypeHeader() })
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async getAddressList (orderId) {
    try {
      const response = await instance.get('/contractors/delivery/' + orderId, { headers: authHeader() })
      const addresses = []
      for (const address of response.data) {
        addresses.push(new Address(address))
      }
      return Promise.resolve(addresses)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}
