import authHeader from '@/services/auth_header'
import { RegulationsCategoryModel } from '@/models/regulationsModel'
import acceptHeader from '@/services/aceppt_header'
import instance from '@/services/api'

export class RegulationsService {
  async fetchRegulationsByCategory (category) {
    try {
      const response = await instance.get('/regulations?category=' + category, {
        headers: authHeader()
      })
      const regulationsCategories = []
      response.data.forEach(value => {
        regulationsCategories.push(new RegulationsCategoryModel(value))
      })
      return Promise.resolve(regulationsCategories)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async fetchRegulation (id) {
    try {
      const response = await instance.get('/regulations/' + id, {
        headers: authHeader()
      })
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async updateRegulations (data) {
    try {
      const response = await instance.put('/regulations', data, {
        headers: authHeader()
      })
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async addRegulationWithFiles (data) {
    try {
      const response = await instance.post('/regulations', data, {
        headers: authHeader()
      })
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async addRegulationsFile (regulationData, regulationId) {
    try {
      const response = await instance.post(`/regulations/files/${regulationId}`, regulationData, {
        headers: authHeader()
      })
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async getRegulationsFile (fileId, fileName) {
    try {
      const response = await instance.get('/regulations/files/' + fileId, {
        responseType: 'blob',
        headers: acceptHeader()
      })
      const fileURL = window.URL.createObjectURL(new Blob([response.data]))
      const fileLink = document.createElement('a')

      fileLink.href = fileURL
      fileLink.setAttribute('download', fileName)
      document.body.appendChild(fileLink)

      fileLink.click()
      return Promise.resolve(URL.createObjectURL(response.data))
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async deleteRegulationsFile (fileId) {
    try {
      const response = await instance.delete('/regulations/files/' + fileId, {
        headers: authHeader()
      })
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async deleteRegulation (id) {
    try {
      const response = await instance.delete('/regulations/' + id, {
        headers: authHeader()
      })
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async fetchNewsletterRegulationsData (id) {
    try {
      const response = await instance.get('/regulations/' + id, {
        headers: authHeader()
      })
      return Promise.resolve(new RegulationsCategoryModel(response.data))
    } catch (error) {
      return Promise.reject(error)
    }
  }
}
