import authContentTypeHeader from '@/services/auth_content_type_header'
import { PaymentModel } from '@/models/paymentModel'
import instance from '@/services/api'

export class PaymentService {
  async registerPayment (paymentData) {
    try {
      const response = await instance.post('/payment', paymentData, { headers: authContentTypeHeader() })
      return Promise.resolve(new PaymentModel(response.data))
    } catch (error) {
      return Promise.reject(error)
    }
  }
}
