import router from '@/router'
import { ref } from 'vue'
import store from '@/store/index'

const synchronizeUsers = async () => {
  try {
    const userService = await store.getters['serviceStore/getUserService']
    await store.dispatch('userStore/synchronizeUsers', { userService: userService })
  } catch (e) {
    store.dispatch('snackbarStore/showMessage', 'Nastąpił błąd w synchronizacji')
  }
}

export const itemsAdmin = ref([
  {
    label: 'Synchronizuj konta',
    icon: 'pi pi-history',
    command: () => {
      synchronizeUsers()
    }
  },
  {
    key: 1,
    label: 'Katalog produktów',
    icon: 'pi product-icon',
    to: { name: 'Products' }
  },
  {
    label: 'Moje zamówienia',
    icon: 'pi pi-shopping-bag',
    to: { name: 'Orders' }
  },
  {
    label: 'Moje faktury',
    icon: 'pi pi-file',
    to: { name: 'InvoicesManagement' }
  },
  {
    label: 'Podgląd koszyków',
    icon: 'pi pi-shopping-cart',
    to: { name: 'CartPreview' }
  },
  {
    key: 2,
    label: 'Rozkrój płyt',
    name: 'cut-icon',
    icon: 'pi cut-icon',
    to: { name: 'Cutting boards' }
  },
  {
    label: 'Aktualności',
    name: 'cut-icon',
    icon: 'pi newsletter-icon',
    items: [
      {
        label: 'Edytuj aktualności',
        to: {
          name: 'Articles config view',
          query: {
            from: 0,
            to: 9
          }
        }
      },
      {
        label: 'Dodaj nową aktualność',
        to: {
          name: 'Article new',
          query: {
            articleId: null
          }
        }
      }
    ],
    command: () => {
      router.push({ name: 'Articles', query: { from: 0, to: 9 } })
    }
  },
  {
    label: 'Konfiguracja karuzeli',
    name: 'cut-icon',
    icon: 'pi cut-icon',
    to: { name: 'Carousel config' }
  },
  {
    label: 'Program lojalnościowy',
    icon: 'pi handshake-icon',
    command: () => {
      router.push({ name: 'Loyalty program' })
    },
    items: [
      {
        label: 'Edycja programu',
        to: { name: 'Loyalty program config' }
      },
      {
        label: 'Edycja kategorii',
        to: { name: 'Loyalty program categories config' }
      },
      {
        label: 'Zamówione nagrody',
        to: { name: 'Loyalty program orders' }
      }
    ]
  },
  {
    label: 'Do pobrania',
    icon: 'pi document-icon',
    command: () => {
      router.push({ name: 'Downloads' })
    },
    items: [
      {
        label: 'Konfiguracja plików',
        to: { name: 'Regulations config' }
      }
    ]
  },
  {
    label: 'FAQ',
    icon: 'pi help-icon',
    command: () => {
      router.push({ name: 'FAQ' })
    },
    items: [
      {
        label: 'Edycja FAQ',
        to: { name: 'FAQ config' }
      }
    ]
  },
  {
    label: 'Newsletter',
    icon: 'pi newsletter-icon',
    items: [
      {
        label: 'Zarządzanie newsletterami',
        to: { name: 'Newsletter config' }
      },
      {
        label: 'Szablon newslettera',
        to: { name: 'Newsletter template' }
      },
      {
        label: 'Zgody',
        to: { name: 'Newsletter regulations' }
      },
      {
        label: 'Zarządzanie kategoriami',
        to: { name: 'Newsletter categories config' }
      }
    ],
    command: () => {
      router.push({ name: 'Newsletter' })
    }
  },
  {
    label: 'Regulaminy',
    icon: 'pi pi-book',
    to: { name: 'RegulationsConfig' }
  },
  {
    label: 'Chat',
    icon: 'pi chat-icon',
    to: { name: 'Chat' }
  },
  {
    label: 'Kontakt',
    icon: 'pi pi-phone',
    command: () => {
      window.open('https://www.abler.pl/kontakt', '__blank', 'noopener noreferrer')
    }
  },
  {
    label: 'Logi',
    icon: 'pi pi-folder-open',
    to: { name: 'Logs' }
  }
])
