import { parseDateArray } from '@/utils/utils'

export class UserDataContractor {
  constructor (userDataContractor = {}) {
    this.appellative = userDataContractor.appellative
    this.b2bAuthorisation = userDataContractor.b2bAuthorisation
    this.birthDate = userDataContractor.birthDate
    this.businessProfile = userDataContractor.businessProfile
    this.company = userDataContractor.company
    this.contractor = new Contractor(userDataContractor.contractor)
    this.email = userDataContractor.email
    this.enovaId = userDataContractor.enovaId
    this.firstCustomerServiceConsultant = new FirstCustomerServiceConsultant(userDataContractor.firstCustomerServiceConsultant)
    this.firstName = userDataContractor.firstName
    this.fiscalCode = userDataContractor.fiscalCode
    this.guid = userDataContractor.guid
    this.lastName = userDataContractor.lastName
    this.phoneNumber = userDataContractor.phoneNumber
    this.position = userDataContractor.position
    this.roles = parseDateArray(userDataContractor.roles)
    this.secondCustomerServiceConsultant = new SecondCustomerServiceConsultant(userDataContractor.secondCustomerServiceConsultant)
    this.wwwAccess = userDataContractor.wwwAccess
    this.wwwAccessDenied = userDataContractor.wwwAccessDenied
    this.wwwAccountName = userDataContractor.wwwAccountName
    this.wwwAddress = userDataContractor.wwwAddress
    // this.invoiceAddress = new InvoiceAddress(userDataContractor.invoiceAddress)
  }
}
export class Contractor {
  constructor (contractor = {}) {
    this.address = contractor.address
    this.b2bAccess = contractor.b2bAccess
    this.b2bDepartment = contractor.b2bDepartment
    this.code = contractor.code
    this.creditLimit = contractor.creditLimit
    this.deadline = contractor.deadline
    this.definitionOfPayment = contractor.definitionOfPayment
    this.enovaId = contractor.enovaId
    this.guid = contractor.guid
    this.id = contractor.id
    this.name = contractor.name
    this.nip = contractor.nip
    this.type = contractor.type
  }
}
export class FirstCustomerServiceConsultant {
  constructor (firstCustomerServiceConsultant = {}) {
    this.email = firstCustomerServiceConsultant.email
    this.firstName = firstCustomerServiceConsultant.firstName
    this.id = firstCustomerServiceConsultant.id
    this.lastName = firstCustomerServiceConsultant.lastName
    this.phoneNumber = firstCustomerServiceConsultant.phoneNumber
  }
}
export class InvoiceAddress {
  constructor (invoiceAddress = {}) {
    this.apartmentNumber = invoiceAddress.apartmentNumber
    this.buildingNumber = invoiceAddress.buildingNumber
    this.city = invoiceAddress.city
    this.id = invoiceAddress.id
    this.street = invoiceAddress.street
    this.zipCode = invoiceAddress.zipCode
  }
}
export class SecondCustomerServiceConsultant {
  constructor (secondCustomerServiceConsultant = {}) {
    this.email = secondCustomerServiceConsultant.email
    this.firstName = secondCustomerServiceConsultant.firstName
    this.id = secondCustomerServiceConsultant.id
    this.lastName = secondCustomerServiceConsultant.lastName
    this.phoneNumber = secondCustomerServiceConsultant.string
  }
}
