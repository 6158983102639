import { createStore } from 'vuex'
import keycloakStore from '@/store/modules/keycloakStore'
import snackbarStore from '@/store/modules/snackbarStore'
import shoppingCartStore from '@/store/modules/shoppingCartStore'
import userStore from '@/store/modules/userStore'
import newsletterStore from '@/store/modules/newsletterStore'
import faqStore from '@/store/modules/faqStore'
import addressStore from '@/store/modules/addressStore'
import articleStore from '@/store/modules/articleStore'
import regulationsStore from '@/store/modules/regulationsStore'
import productStore from '@/store/modules/productStore'
import serviceStore from '@/store/modules/serviceStore'
import departmentStore from '@/store/modules/departmentStore'
import chatStore from '@/store/modules/chatStore'
import loyaltyProgramStore from '@/store/modules/loyaltyProgramStore'
import logStore from '@/store/modules/logStore'

import createPersistedState from 'vuex-persistedstate'
import SecureLS from 'secure-ls'
const ls = new SecureLS({ isCompression: false })

const debug = process.env.NODE_ENV !== 'production'

export default createStore({
  modules: {
    keycloakStore,
    serviceStore,
    userStore,
    departmentStore,
    snackbarStore,
    shoppingCartStore,
    newsletterStore,
    articleStore,
    faqStore,
    addressStore,
    productStore,
    regulationsStore,
    chatStore,
    loyaltyProgramStore,
    logStore
  },
  strict: debug,
  plugins: [
    createPersistedState({
      paths: ['userStore.isAdmin', 'userStore.isSuperAdmin', 'productStore.productName', 'articleStore.currentArticle'],
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key)
      }
    })
  ]
})
