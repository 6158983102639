import { RegulationsOutModel } from '@/models/regulationsOutModel'

const state = {
  regulation: {},
  GENERAL: [],
  FOOTER: [],
  NEWSLETTER: []
}

const getters = {
  getRegulationsByCategory: (state) => (category) => {
    return state[category]
  },
  getIsAccepted: (state) => (id) => {
    const index = state.NEWSLETTER.findIndex(element => element.id === id)
    return state.NEWSLETTER[index].isAccepted
  },
  getRegulation (state) {
    return state.regulation
  }
}

const actions = {
  async fetchNewsletterRegulationById ({ commit }, payload) {
    return payload.regulationsService.fetchNewsletterRegulationsData(payload.regulationId).then(
      response => {
        commit('fetchNewsletterRegulationByIdSuccess', response.data)
        return response
      },
      error => {
        commit('fetchNewsletterRegulationByIdFailure', error)
      }
    )
  },
  async fetchRegulationsCategory ({ commit, dispatch }, payload) {
    try {
      const response = await payload.regulationsService.fetchRegulationsByCategory(payload.category)
      commit('SET_REGULATIONS_BY_CATEGORY', { payload: response, category: payload.category })
      return response
    } catch (e) {
      await dispatch(
        'snackbarStore/showMessage',
        'Wystąpił błąd podczas pobierania regulaminów, proszę spróbować ponownie',
        { root: true }
      )
      return Promise.reject(e)
    }
  },
  async updateRegulationWithCategory ({ commit, dispatch }, payload) {
    try {
      await payload.regulationsService.updateRegulations(new RegulationsOutModel(payload.data))
      commit('SET_REGULATION_BY_CATEGORY', { data: payload.data, category: payload.category })
    } catch (e) {
      await dispatch(
        'snackbarStore/showMessage',
        'Wystąpił błąd, proszę spróbować ponownie',
        { root: true }
      )
      return Promise.reject(e)
    }
  },
  async addRegulationWithCategory ({ commit, dispatch }, payload) {
    try {
      await payload.regulationsService.addRegulationWithFiles(new RegulationsOutModel(payload.data))
      commit('ADD_NEW_REGULATION_BY_CATEGORY', { data: payload.data, category: payload.category })
    } catch (e) {
      await dispatch(
        'snackbarStore/showMessage',
        'Wystąpił błąd, proszę spróbować ponownie',
        { root: true }
      )
      return Promise.reject(e)
    }
  },
  setIsAccepted: ({ commit, dispatch }, payload) => {
    commit('CHANGE_IS_ACCEPTED', payload)
  },
  removeCategoryItem: async ({ commit, dispatch }, payload) => {
    try {
      const response = await payload.regulationsService.deleteRegulation(payload.categoryIdToRemove)
      commit('REMOVE_REGULATION_CATEGORY', { category: payload.category, categoryIdToRemove: payload.categoryIdToRemove })
      return Promise.resolve(response)
    } catch (e) {
      await dispatch('snackbarStore/showMessage', `Wystąpił problem podczas wysyłania pliku o nazwie ${payload.fileName}`, { root: true })
      return Promise.reject(e)
    }
  },
  addAttachment: async ({ commit, dispatch }, payload) => {
    try {
      const response = await payload.regulationsService.addRegulationsFile(payload.attachmentData, payload.regulationId)
      commit('ADD_REGULATION_FILE_BY_CATEGORY', { data: payload.attachmentData, category: payload.category, regulationId: payload.regulationId, fileStoreItem: payload.fileStoreItem })
      return Promise.resolve(response)
    } catch (e) {
      await dispatch('snackbarStore/showMessage', `Wystąpił problem podczas wysyłania pliku o nazwie ${payload.fileName}`, { root: true })
      return Promise.reject(e)
    }
  },
  removeAttachment: async ({ commit, dispatch }, payload) => {
    try {
      const response = await payload.regulationsService.deleteRegulationsFile(payload.regulationFileId)
      commit('REMOVE_REGULATION_FILE_BY_CATEGORY', { category: payload.category, regulationId: payload.regulationId, regulationFileId: payload.regulationFileId })
      await dispatch('snackbarStore/showMessage', 'Usunięto plik.', { root: true })
      return Promise.resolve(response)
    } catch (e) {
      await dispatch('snackbarStore/showMessage', `Wystąpił problem podczas wysyłania pliku o nazwie ${payload.fileName}`, { root: true })
      return Promise.reject(e)
    }
  },
  async fetchRegulation ({ commit, dispatch }, payload) {
    try {
      const response = await payload.regulationsService.fetchRegulation(payload.regulationId)
      commit('FETCH_REGULATION', response.data)
      return response
    } catch (e) {
      await dispatch(
        'snackbarStore/showMessage',
        'Wystąpił błąd podczas pobierania regulaminu, proszę spróbować ponownie',
        { root: true }
      )
      return Promise.reject(e)
    }
  }
}

const mutations = {
  fetchNewsletterRegulationByIdSuccess (state, data) {
    state.newsletterRegulation = data
  },
  SET_REGULATIONS_BY_CATEGORY (state, data) {
    const { category, payload } = data
    state[category] = payload
  },
  SET_REGULATION_BY_CATEGORY (state, payload) {
    const { category, data } = payload

    const index = state[category].findIndex(element => data.id === element.id)

    if (index !== -1) {
      state[category][index] = data
    }
  },
  ADD_NEW_REGULATION_BY_CATEGORY (state, payload) {
    const { category, data } = payload
    state[category].push(data)
  },
  CHANGE_IS_ACCEPTED (state, payload) {
    const index = state.NEWSLETTER.findIndex(element => element.id === payload.id)
    state.NEWSLETTER[index].isAccepted = payload.isAccepted
  },
  REMOVE_REGULATION_CATEGORY (state, payload) {
    const { category, categoryIdToRemove } = payload
    const index = state[category].findIndex(element => element.id === categoryIdToRemove)
    if (index !== -1) {
      state[category].splice(index, 1)
    }
  },
  ADD_REGULATION_FILE_BY_CATEGORY (state, payload) {
    const { category, regulationId, fileStoreItem } = payload
    const index = state[category].findIndex(element => element.id === regulationId)
    if (index !== -1) {
      state[category][index].files.push(fileStoreItem)
    }
  },
  REMOVE_REGULATION_FILE_BY_CATEGORY (state, payload) {
    const { category, regulationId, regulationFileId } = payload
    const regulationIndex = state[category].findIndex(element => element.id === regulationId)
    if (regulationIndex !== -1) {
      const regulationFileIndex = state[category][regulationIndex].files.findIndex(element => element.id === regulationFileId)
      if (regulationFileIndex !== -1) {
        state[category][regulationIndex].files.splice(regulationFileIndex, 1)
      }
    }
  },
  FETCH_REGULATION (state, currentRegulation) {
    state.regulation = currentRegulation
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
