<template>
  <div v-if="!isLoading" class="p-col-12 p-md-4 middleContent">
      <div class="detailsBox">
        <h3>Dodaj odpowiednią ilość do koszyka</h3>
      <div>
      <div v-if="converterArray?.length > 0">
        <div v-for="(singleUnitConverter, index) of converterArray" :key="index" class="amountBox p-d-flex p-flex-column">
          <div
            class="p-d-flex p-jc-between p-ai-end p-mt-3"
            style="width: 100%;"
          >
            <div
              class="p-d-flex p-flex-column p-jc-between"
              style="height: 100%; width: 45%;"
            >
              <h3>Cena/{{ singleUnitConverter.unitType }}:</h3>
              <h2 class="significant size">{{ ((priceStatus ? dataPriceCalculation.discountNet : dataPriceCalculation.net)  * (1 / singleUnitConverter.baseCalcUnit))?.toFixed(2) }} zł <span style='color: black; font-weight: 400;'>netto</span></h2>
            </div>
            <div class="p-mr-4" style="width: 40%">
              <p class="p-mb-2 p-text-bold small">
                ilość ({{ singleUnitConverter.unitType === 'kg' || singleUnitConverter.unitType === 'g' ? singleUnitConverter.unitType : singleUnitConverter.unitType + '.' }})
              </p>
              <p class="p-mb-2 p-text-bold small" v-if="singleUnitConverter.unitType === productDetails?.expectedIndivisibleUnitOfMeasure || singleUnitConverter.unitType === productDetails?.indivisibleUnitOfMeasure"> Jednostka niepodzielna</p>
              <Form @keyup.enter="handleChange">
                <InputNumber
                  class="amountInput"
                  id="min-max-unit-buttons"
                  v-model="singleUnitConverter.unitAmount"
                  mode="decimal"
                  :maxFractionDigits="4"
                  :min="1"
                  :max="9999"
                  :allowEmpty="false"
                  :loading="isLoadingPrice"
                  @input="calculateUnits($event, singleUnitConverter)"
                  :showButtons="singleUnitConverter.unitType === productDetails?.expectedIndivisibleUnitOfMeasure || singleUnitConverter.unitType === productDetails?.indivisibleUnitOfMeasure"
                  incrementButtonIcon="pi pi-plus"
                  decrementButtonIcon="pi pi-minus"
                />
              </Form>
            </div>
          </div>
        </div>
      </div>
      <div
        v-else
        class="p-d-flex p-jc-between p-ai-center p-mt-3"
        style="width: 100%; height: 60px"
      >
        <div
          class="p-d-flex p-flex-column p-jc-between"
          style="height: 100%; width: 45%;"
        >
          <h3>Cena/{{ dataPriceCalculation.baseUnit }}:</h3>
          <h2 class="significant size">{{ priceStatus ? dataPriceCalculation.discountNet.toFixed(2) : dataPriceCalculation.net.toFixed(2) }} zł <span style='color: black; font-weight: 400;'>netto</span></h2>
        </div>
        <div class="p-mr-4" style="width: 40%">
          <p class="p-mb-2 p-text-bold small">
            ilość ({{ dataPriceCalculation.baseUnit === 'kg' || dataPriceCalculation.baseUnit === 'g' ? dataPriceCalculation.baseUnit : dataPriceCalculation.baseUnit + '.' }})
          </p>
          <p class="p-mb-2 small" v-if="dataPriceCalculation.baseUnit === productDetails?.expectedIndivisibleUnitOfMeasure || dataPriceCalculation.baseUnit === productDetails?.indivisibleUnitOfMeasure"> Jednostka niepodzielna</p>
          <Form @keyup.enter="handleChange">
            <InputNumber
              class="amountInput"
              id="min-max-basic-unit-buttons"
              v-model="amount"
              mode="decimal"
              showButtons
              :maxFractionDigits="dataPriceCalculation.baseUnit === productDetails?.expectedIndivisibleUnitOfMeasure || dataPriceCalculation.baseUnit === productDetails?.indivisibleUnitOfMeasure ? 0 : 4"
              :allowEmpty="false"
              :min="1"
              :max="9999"
              :step="1"
              incrementButtonIcon="pi pi-plus"
              decrementButtonIcon="pi pi-minus"
            />
          </Form>
        </div>
      </div>
      <div class="p-d-flex p-dir-row p-jc-end p-ai-center p-mt-3">
        <h3 class="p-mr-2">Łączna kwota netto</h3>
        <h2 v-if="converterArray?.length > 0" class="significant size">{{ totalProductValue?.toFixed(2) }} zł</h2>
        <h2 v-else class="significant size">{{ ((priceStatus ? dataPriceCalculation.discountNet : dataPriceCalculation.net) * amount)?.toFixed(2) }} zł</h2>
      </div>
        <div class="p-d-flex p-jc-between p-mt-3 p-mb-5" style="width: 100%">
          <div v-if="!isLoading" class="p-d-flex p-dir-col">
            <p class="p-text-light p-mb-1">Dostępne: </p>
            <p class="p-text-bold">{{ productDetails?.storehouse }} {{ productDetails?.baseUnit === 'kg' || productDetails?.baseUnit === 'g' ? productDetails?.baseUnit : productDetails?.baseUnit + '.' }}</p>
          </div>
          <div>
            <Button @click="handleChange" :disabled="isLoading || isLoadingPrice || isBlockButton">
              {{ isChangeQuantity ? 'Zmień ilość' : 'Do koszyka'}}
            </Button>
          </div>
        </div>
        <div style="width: 100%">
          <p v-if="productDetails.splitPayment" class="significant p-mb-6">
            Uwaga: Produkt podlega płatności dzielonej!
          </p>
          <!-- <p class="significant p-mb-6">
            Uwaga: do koszyka będą dodawane pełne opakowania
          </p> -->
        </div>
      </div>
      </div>
    </div>
  <div v-else class="p-d-flex p-jc-center p-ai-center p-col-12">
    <ProgressSpinner></ProgressSpinner>
  </div>
</template>

<script>
import InputNumber from 'primevue/inputnumber'
import Button from 'primevue/button'
import ProgressSpinner from 'primevue/progressspinner'

import { useStore } from 'vuex'

import { ref, onMounted, computed } from 'vue'
import { ProductQuantity } from '@/models/productQuantity'
import { Form } from 'vee-validate'
import { ItemQuantity } from '@/models/itemQuantity'
import Decimal from 'decimal.js'

export default {
  name: 'PriceDetails',
  components: { Button, InputNumber, ProgressSpinner, Form },
  props: {
    id: {
      type: Number,
      required: true
    },
    isChangeQuantity: {
      type: Boolean,
      required: true
    },
    quantityItem: {
      type: Object,
      required: false
    }
  },
  setup (props, { emit }) {
    const store = useStore()
    const shoppingCartService = store.getters['serviceStore/getShoppingCartService']
    const productsService = store.getters['serviceStore/getProductsService']
    const priceStatus = computed(() => store.getters['productStore/getIsAfterDiscount'])
    const isLoadingPrice = ref(false)
    const isBlockButton = ref(false)
    const amount = ref(1)
    let timer
    const dataPriceCalculation = computed(
      () => store.getters['productStore/getDataPriceCalculation']
    )
    const productPriceConverter = computed(
      () => store.getters['productStore/getProductPriceConverter']
    )
    const converterArray = computed(() => JSON.parse(JSON.stringify(store.getters['productStore/getConverterArray'])))
    const totalProductValue = computed(() => store.getters['productStore/getTotalProductValue'])
    const currentShoppingCartId = computed(() => store.getters['shoppingCartStore/getCurrentShoppingCartId'])
    const productDetails = computed(() => store.getters['productStore/getProductDetails'])

    const calculateUnits = async (event, data) => {
      if (event.value !== 0) {
        clearTimeout(timer)
        isBlockButton.value = true
        timer = setTimeout(async () => {
          isLoadingPrice.value = true
          if (event.value < 9999) {
            await store.dispatch('productStore/calculateUnits', { eventValue: event.value, converterData: data })
          } else {
            await store.dispatch('productStore/calculateUnits', { eventValue: 9999, converterData: data })
          }
          isLoadingPrice.value = false
          isBlockButton.value = false
        }, 1000)
      }
    }
    const isLoading = ref(true)
    onMounted(async () => {
      isLoading.value = true
      try {
        await store.dispatch('productStore/fetchProductDetails', { productsService, id: props.id })
        if (props.isChangeQuantity === true) {
          if (converterArray.value.length > 0) {
            await store.dispatch('productStore/calculateUnits', { eventValue: props.quantityItem.quantity, converterData: converterArray.value[0] })
          } else {
            amount.value = props.quantityItem.quantity
          }
        } else {
          await store.dispatch('productStore/calculateUnits', { eventValue: 1, converterData: converterArray.value[0] })
        }
      } catch (error) {
        if (error.status === 404) {
          await store.dispatch('snackbarStore/showMessage', 'Wybrany produkt został usunięty z oferty.')
        } else {
          await store.dispatch('snackbarStore/showMessage', 'Wystąpił problem podczas łączenia z bazą danych.')
        }
        emit('closeModal')
        isLoading.value = false
      }
      isLoading.value = false
    })

    const handleChange = async () => {
      if (isBlockButton.value === true) {
        return
      }

      if (props.isChangeQuantity === true) {
        await handleChangeQuantity()
      } else if (props.isChangeQuantity === false) {
        await handleAddToShoppingCart()
      }
    }

    const handleChangeQuantity = async () => {
      const indivisibleUnit = converterArray.value.find(el => el.unitType === productDetails.value.indivisibleUnitOfMeasure)
      const quantityMap = new ItemQuantity({ itemId: props.quantityItem.id, orderId: currentShoppingCartId.value, quantity: converterArray.value[0]?.unitAmount ? converterArray.value[0].unitAmount : amount.value })

      await store.dispatch('shoppingCartStore/changeQuantityInBasket', {
        shoppingCartService: shoppingCartService,
        orderId: currentShoppingCartId.value,
        quantityId: props.quantityItem.id,
        quantityMap: quantityMap,
        indivisibleQuantity: indivisibleUnit ? indivisibleUnit.unitAmount : amount.value,
        baseUnitPerIndivisible: indivisibleUnit ? new Decimal(1).dividedBy(indivisibleUnit.baseCalcUnit).toNumber() : 1
      })
      // TODO Delete if backend handles it
      await store.dispatch('shoppingCartStore/setCurrentShoppingCartId', {
        shoppingCartService: shoppingCartService,
        shoppingCartId: currentShoppingCartId.value
      })
      await store.dispatch('productStore/calculateUnits', { eventValue: 1, converterData: converterArray.value[0] })
      if (converterArray.value?.length === 0) {
        amount.value = 1
      }
      emit('closeModal')
    }

    const handleAddToShoppingCart = async () => {
      const quantityMap = new ProductQuantity({ commodityId: productDetails.value.id, orderId: currentShoppingCartId.value, quantity: converterArray.value[0]?.unitAmount ? converterArray.value[0].unitAmount : amount.value })
      if (currentShoppingCartId.value === 'domyslny_koszyk') {
        try {
          await store.dispatch('shoppingCartStore/addShoppingCart', {
            shoppingCartService: shoppingCartService,
            basketName: 'Domyślny koszyk'
          })
          const indivisibleUnit = converterArray.value.find(el => el.unitType === productDetails.value.indivisibleUnitOfMeasure)
          const quantityAfterBasket = new ProductQuantity({ commodityId: productDetails.value.id, orderId: currentShoppingCartId.value, quantity: converterArray.value[0]?.unitAmount ? converterArray.value[0].unitAmount : amount.value })
          await store.dispatch('shoppingCartStore/addProductToShoppingCart', {
            shoppingCartService: shoppingCartService,
            product: productDetails.value,
            quantityMap: quantityAfterBasket,
            indivisibleQuantity: indivisibleUnit ? indivisibleUnit.unitAmount : amount.value,
            baseUnitPerIndivisible: indivisibleUnit ? new Decimal(1).dividedBy(indivisibleUnit.baseCalcUnit).toNumber() : 1
          })
          await store.dispatch('productStore/calculateUnits', { eventValue: 1, converterData: converterArray.value[0] })
          if (converterArray.value?.length === 0) {
            amount.value = 1
          }
          emit('closeModal')
        } catch (e) {
        }
      } else {
        const indivisibleUnit = converterArray.value.find(el => el.unitType === productDetails.value.indivisibleUnitOfMeasure)
        await store.dispatch('shoppingCartStore/addProductToShoppingCart', {
          shoppingCartService: shoppingCartService,
          product: productDetails.value,
          quantityMap: quantityMap,
          indivisibleQuantity: indivisibleUnit ? indivisibleUnit.unitAmount : amount.value,
          baseUnitPerIndivisible: indivisibleUnit ? new Decimal(1).dividedBy(indivisibleUnit.baseCalcUnit).toNumber() : 1
        })
        await store.dispatch('productStore/calculateUnits', { eventValue: 1, converterData: converterArray.value[0] })
        if (converterArray.value?.length === 0) {
          amount.value = 1
        }
        emit('closeModal')
      }
    }
    return { amount, isLoading, priceStatus, isLoadingPrice, isBlockButton, productDetails, handleChange, totalProductValue, dataPriceCalculation, productPriceConverter, converterArray, calculateUnits }
  }
}
</script>

<style lang="scss" scoped>
.middleContent {
  width: 100%;
  height: 100%;
  .detailsBox {
    border: 1px solid $grey;
    background-color: white;
    padding: 15px;
    //height: 50vh;
    @media (min-width: 992px) {
      height: 95%;
    }
    h2 {
      font-weight: 500;
    }

    .amountBox {
      //height: 400px;
      margin: 5px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .significant {
        color: $primary_color;
        font-weight: 800;
      }
      .size {
        //font-size: 1.7rem;
      }
      div div h2.bold {
        font-weight: 900;
      }
      .small {
        font-size: 0.7rem;
      }
    }

    .priceBox {
      height: 35px;
      margin: 15px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;

      h2 {
        color: black;
        font-weight: 500;
      }
    }
    .significant {
      color: $primary_color;
      font-weight: 800;
    }
    .size {
      //font-size: 1.7rem;
    }
  }
}
::v-deep(.p-inputnumber-buttons-stacked .p-inputnumber-input) {
  width: 30%;
  border-radius: 0;
}

::v-deep(.p-inputnumber-input) {
  width: 30%;
  border-radius: 0;
}
::v-deep(.p-inputnumber-buttons-stacked
    .p-inputnumber-button-group
    .p-button.p-inputnumber-button) {
  border-radius: 0;
  width: 23px;
}
.p-button.p-component {
  width: 100%;
  height: 40px;
  background-color: $navigation_black;
  border: 1px solid $navigation_black;
  border-radius: 0;
  color: white;
  padding: 12px 30px;
  &:hover {
    background-color: white;
    border: 1px solid $navigation_black;
    color: $navigation_black;
  }
}
</style>
