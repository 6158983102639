import { DepartmentModel } from '@/models/departmentModel'

export class DepartmentsModel {
  constructor (element) {
    this.departments = this.parseDepartments(element.departments)
  }

  parseDepartments (departments) {
    const departmentsList = []
    for (const department of departments) {
      departmentsList.push(new DepartmentModel(department))
    }
    return departmentsList
  }
}
