import { SingleOrder } from '@/models/singleOrder'

export class OrdersList {
  constructor (element) {
    this.count = element.count
    this.purchasedPrizes = this.parseOrders(element.purchasedPrizes)
  }

  parseOrders (orders) {
    const ordersList = []
    for (const order of orders) {
      ordersList.push(new SingleOrder(order))
    }
    return ordersList
  }
}
