import authHeader from '@/services/auth_header'
import { CarouselLinks } from '@/models/carouselLinks'
import authMultipartHeader from '@/services/auth_multipart_header'
import acceptHeader from '@/services/aceppt_header'
import instance from '@/services/api'

export class CarouselService {
  async fetchCarouselImages () {
    try {
      const response = await instance.get('/carousel', { headers: authHeader() })
      return Promise.resolve(new CarouselLinks(response.data))
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async putCarouselImages (data) {
    try {
      const response = await instance.put('/carousel', data, { headers: authHeader() })
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async postCarouselImage (carouselData) {
    try {
      const response = await instance.post('/carousel-image', carouselData, { headers: authMultipartHeader() })
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async fetchCarouselThumbnail (id) {
    try {
      const response = await instance.get('/carousel-image/thumbnail/' + id, { responseType: 'blob', headers: acceptHeader() })
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async fetchCarouselImage (id) {
    try {
      const response = await instance.get('/carousel-image/' + id, { responseType: 'blob', headers: acceptHeader() })
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async deleteCarouselImage (id) {
    try {
      await instance.delete('/carousel-image/' + id, { headers: authHeader() })
      return Promise.resolve(true)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}
