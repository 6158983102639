export class InvoiceModel {
  constructor (invoiceData = {}) {
    this.invoiceGrossAmount = invoiceData.invoiceGrossAmount
    this.id = invoiceData.id
    this.leftToPay = invoiceData.leftToPay
    this.invoiceDate = this.parseDate(invoiceData.invoiceDate)
    this.invoiceNumber = invoiceData.invoiceNumber
    this.external = invoiceData.external
    this.splitPayment = invoiceData.splitPayment
    this.invoiceNetAmount = invoiceData.invoiceNetAmount
    this.orderId = invoiceData.orderId
    this.paid = invoiceData.paid
    this.paymentDeadline = this.parseDate(invoiceData.paymentDeadline)
    this.shopOrderName = invoiceData.shopOrderName
    this.invoiceState = invoiceData.invoiceState
  }

  parseDate (date) {
    if (date) {
      const newDate = date[1] + '/' + date[2] + '/' + date[0]
      return new Date(newDate)
    }
    return ''
  }
}
