export const userRoleEnum = {
  SUPER_ADMIN: 'super_admin',
  ADMIN: 'admin',
  USER: 'user'
}
export const userRoleInterfaceEnum = {
  SUPER_ADMIN: 'Super Administrator',
  ADMIN: 'Administrator',
  USER: 'Klient'
}
