import { SingleProduct } from '@/models/singleProduct'

export class ProductsList {
  constructor (product) {
    this.count = product.count
    this.commodityDtoList = this.parseCommodities(product.commodityDtoList)
  }

  parseCommodities (commodities) {
    const commoditiesList = []
    for (const commodity of commodities) {
      commoditiesList.push(new SingleProduct(commodity))
    }
    return commoditiesList
  }
}
