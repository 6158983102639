import { requestStateEnum } from '@/enums/requestStateEnum'
import { NewsletterModel } from '@/models/newsletterModel'
import { newsletterStateEnum } from '@/enums/newsletterStateEnum'

const state = {
  newsletters: null,
  categories: null,
  newsletterTemplatesList: [],
  newsletterTemplateDetails: null,
  activeNewsletter: null,
  selectedNewsletterTemplate: null,
  requestStatus: requestStateEnum.NOT_SENT
}

const getters = {
  getActiveNewsletter (state) {
    return state.activeNewsletter
  },
  getNewsletterTemplateDetails (state) {
    return state.newsletterTemplateDetails
  },
  getNewsletterTemplatesList (state) {
    return state.newsletterTemplatesList
  },
  getNewsletterData (state) {
    return state.newsletter
  },
  getNewsletterCategoriesData (state) {
    return state.categories
  },
  getSelectedNewsletterTemplate (state) {
    return state.selectedNewsletterTemplate
  }
}

const actions = {
  async fetchNewsletterData ({ commit }, payload) {
    return payload.newsletterService.fetchNewsletter().then(
      (response) => {
        commit('fetchDataSuccess', response)
        return response
      },
      (error) => {
        commit('fetchDataFailure', error)
      }
    )
  },
  setSelectedNewsletterTemplate: (context, item) => {
    context.commit('SET_SELECTED_NEWSLETTER_TEMPLATE', { id: item?.id, item: item?.item })
  },
  async addNewsletterTemplate ({ commit }, payload) {
    try {
      const response = await payload.newsletterService.addNewsletterTemplate(
        payload.dataToSend
      )
      // commit('fetchDataSuccess', response)
      commit('ADD_NEWSLETTER_TEMPLATE', response)
    } catch (e) {
      commit('fetchDataFailure', e)

      Promise.reject(e)
    }
  },
  async updateNewsletterTemplate ({ commit }, payload) {
    try {
      const response = await payload.newsletterService.updateNewsletterTemplate(
        payload.dataToUpdate
      )
      // commit('fetchDataSuccess', response)
      commit('UPDATE_NEWSLETTER_TEMPLATE', response)
    } catch (e) {
      commit('fetchDataFailure', e)

      Promise.reject(e)
    }
  },
  fetchNewsletterTemplateDetails: async (context, payload) => {
    if (payload.id) {
      try {
        const addressFromApi = await payload.newsletterService.fetchNewsletterTemplate(
          payload.id
        )
        context.commit('GET_NEWSLETTER_DETAILS', addressFromApi)
      } catch (e) {
        Promise.reject(e)
      }
    }
  },
  fetchNewsletterTemplates: async (context, payload) => {
    try {
      const newsletterTemplatesFromApi = await payload.newsletterService.fetchNewsletterTemplates()
      context.commit('GET_NEWSLETTER_TEMPLATES', newsletterTemplatesFromApi)
    } catch (e) {
      Promise.reject(e)
    }
  },
  async fetchSelectedNewsletterData ({ commit }, payload) {
    return payload.newsletterService.fetchSelectedNewsletter(payload.id).then(
      (response) => {
        commit('fetchSelectedDataSuccess', response.data)
        return response
      },
      (error) => {
        commit('fetchSelectedDataFailure', error)
      }
    )
  },
  async fetchNewsletterCategoriesData ({ commit }, payload) {
    return payload.newsletterService.fetchNewsletterCategories().then(
      (response) => {
        commit('fetchCategoriesSuccess', response.data)
      },
      (error) => {
        commit('fetchCategoriesFailure', error)
      }
    )
  },
  async saveNewsletterData ({ commit }, payload) {
    const newsletterData = new NewsletterModel(
      JSON.parse(JSON.stringify(payload.newsletterData))
    )
    const newsletterDataToServer = new NewsletterModel({
      id: payload.newsletterData.id,
      name: payload.newsletterData.name,
      categories: payload.newsletterData.categories,
      header: payload.newsletterData.header,
      content: payload.newsletterData.content,
      footer: payload.newsletterData.footer,
      templateId: payload.newsletterData.templateId
    })

    return payload.newsletterService.addNewsletter(newsletterDataToServer).then(
      (response) => {
        commit('saveNewsletterDataSuccess', newsletterData)
      },
      (error) => {
        commit('saveNewsletterDataFailure', error)
      }
    )
  },
  async sendNewsletterData ({ commit }, payload) {
    const newsletterData = JSON.parse(JSON.stringify(payload.newsletterData))

    return payload.newsletterService
      .sendNewsletter(newsletterData, newsletterData.newsletterId)
      .then(
        (response) => {
          commit('sendNewsletterDataSuccess', newsletterData)
        },
        (error) => {
          console.log(error)
          commit('sendNewsletterDataFailure', error)
        }
      )
  },
  async sendNewsletterCategories ({ commit }, payload) {
    return payload.newsletterService
      .sendNewsletterCategory(payload.newsletterMap)
      .then(
        (response) => {
          commit('sendNewsletterCategoriesSuccessful', payload.newsletterMap)
        },
        (error) => {
          console.log(error)
        }
      )
  },
  async updateNewsletterData ({ commit }, payload) {
    const newsletterData = new NewsletterModel(
      JSON.parse(JSON.stringify(payload.newsletterData))
    )
    const newsletterDataToServer = new NewsletterModel({
      id: payload.newsletterData.id,
      name: payload.newsletterData.name,
      categories: payload.newsletterData.categories,
      header: payload.newsletterData.header,
      content: payload.newsletterData.content,
      footer: payload.newsletterData.footer,
      templateId: payload.newsletterData.templateId
    })

    return payload.newsletterService
      .updateNewsletter(newsletterDataToServer)
      .then(
        (response) => {
          commit('updateNewsletterDataSuccess', newsletterData)
        },
        (error) => {
          console.log(error)
          commit('updateNewsletterDataFailure', error)
        }
      )
  },
  async removeNewsletterData ({ commit }, payload) {
    return payload.newsletterService.deleteNewsletter(payload.id).then(
      (response) => {
        commit('removeNewsletterDataSuccess', payload.id)
      },
      (error) => {
        console.log(error)
        commit('removeNewsletterDataFailure', error)
      }
    )
  },
  async deleteNewsletterTemplate ({ commit }, payload) {
    try {
      await payload.newsletterService.deleteNewsletterTemplate(payload.id)
      commit('REMOVE_NEWSLETTER_TEMPLATE', payload.id)
    } catch (e) {
      Promise.reject(e)
    }
  }
}

const mutations = {
  GET_NEWSLETTER_TEMPLATES: (state, newsletterTemplates) => {
    state.newsletterTemplatesList = newsletterTemplates
    // state.idActiveNewsletter = newsletterTemplates[0].id
  },
  SET_SELECTED_NEWSLETTER_TEMPLATE: (state, item) => {
    state.selectedNewsletterTemplate = item?.id
    state.activeNewsletter = item?.item?.title
  },
  REMOVE_NEWSLETTER_TEMPLATE: (state, id) => {
    state.newsletterTemplatesList = state.newsletterTemplatesList.filter(
      (element) => element.id !== id
    )
  },
  UPDATE_NEWSLETTER_TEMPLATE: (state, newsletterTemplates) => {
    console.log('newsletterTemplates', newsletterTemplates)
  },
  ADD_NEWSLETTER_TEMPLATE: (state, newsletterTemplates) => {
    state.newsletterTemplatesList = [
      ...state.newsletterTemplatesList,
      newsletterTemplates
    ]
  },
  GET_NEWSLETTER_DETAILS: (state, newsletterTemplate) => {
    const xmlString = JSON.parse(newsletterTemplate.data.content)
    state.newsletterTemplateDetails = xmlString
  },
  fetchDataSuccess (state, data) {
    state.newsletters = data
    state.requestStatus = requestStateEnum.SUCCESS
  },
  fetchDataFailure (state, error) {
    state.newsletters = null
    state.requestStatus = requestStateEnum.ERROR
  },
  fetchSelectedDataSuccess (state, data) {
    for (let i = 0; i < state.newsletters.length; i++) {
      if (state.newsletters[i].id === data.id) {
        state.newsletters[i] = new NewsletterModel(data)
      }
    }
  },
  fetchSelectedDataFailure (state) {
    state.requestStatus = requestStateEnum.ERROR
  },
  fetchCategoriesSuccess (state, data) {
    state.categories = data
    state.requestStatus = requestStateEnum.SUCCESS
  },
  fetchCategoriesFailure (state, data) {
    state.categories = null
    state.requestStatus = requestStateEnum.ERROR
  },
  saveNewsletterDataSuccess (state, data) {
    state.newsletters.push(data)
    state.requestStatus = requestStateEnum.SUCCESS
  },
  saveNewsletterDataFailure (state, error) {
    state.newsletter = null
    state.requestStatus = requestStateEnum.ERROR
  },
  updateNewsletterDataSuccess (state, newsletter) {
    for (let i = 0; i < state.newsletters.length; i++) {
      if (state.newsletters[i].id === newsletter.id) {
        state.newsletters[i] = new NewsletterModel(newsletter)
      }
    }
  },
  sendNewsletterDataSuccess (state, data) {
    const index = state.newsletters.findIndex(
      (newsletter) => newsletter.id === data.newsletterId
    )
    state.newsletters[index].status = newsletterStateEnum.SENT
  },
  sendNewsletterCategoriesSuccessful (state, data) {
    // state.categories = data
  },
  removeNewsletterDataSuccess (state, id) {
    let indexToRemove
    for (let i = 0; i < state.newsletters.length; i++) {
      if (state.newsletters[i].id === id) {
        indexToRemove = i
        break
      }
    }
    state.newsletters.splice(indexToRemove, 1)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
