import { Article } from '@/models/articleModel'

export class ArticleList {
  constructor (element = {}) {
    this.count = element.count
    this.articles = this.parseArticles(element.articles)
  }

  parseArticles (articles) {
    const articlesList = []
    for (const article of articles) {
      articlesList.push(new Article(article))
    }
    return articlesList
  }
}
