import { defineRule } from 'vee-validate'

defineRule('required', value => {
  if (typeof value === 'number') {
    if (!value) {
      return '* Pole jest wymagane.'
    }
  } else {
    if (!value || !value.length) {
      return '* Pole jest wymagane.'
    }
  }

  return true
})

defineRule('acceptRequired', value => {
  if (!value) {
    return '* Wymagana akceptacja.'
  }

  return true
})

defineRule('email', value => {
  if (!value || !value.length) {
    return true
  }

  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    return '* Podaj prawidłowy adres e-mail.'
  }

  return true
})

defineRule('password', value => {
  const lowerCaseLetters = /[a-z]/g
  const upperCaseLetters = /[A-Z]/g
  const numbers = /[0-9]/g
  const special = "<>@!#$%^&*()_+[]{}?:;|'\"\\,./~`-="
  const specialChars = (value) => {
    for (let i = 0; i < special.length; i++) {
      if (value.indexOf(special[i]) > -1) {
        return true
      }
    }
  }

  if (!value || !value.length) {
    return true
  }
  if (value.length < 8) {
    return 'Za krótkie'
  } else if (!value.match(lowerCaseLetters)) {
    return 'Brak małej litery'
  } else if (!value.match(upperCaseLetters)) {
    return 'Brak wielkiej litery'
  } else if (!value.match(numbers)) {
    return 'Brak liczby'
  } else if (!specialChars(value)) {
    return 'Brak znaku specjalnego'
  } else {
    return true
  }
})

defineRule('max', value => {
  if (!value || value.length > 250) {
    return 'Przekroczono maksymalną liczbę znaków 250.'
  } else {
    return true
  }
})
defineRule('max35', value => {
  if (!value || value.length > 35) {
    return 'Przekroczono maksymalną liczbę znaków 35.'
  } else {
    return true
  }
})
defineRule('maxNotRequired', (value) => {
  if (value?.length > 250) {
    return 'Przekroczono maksymalną liczbę znaków 250.'
  } else {
    return true
  }
})
defineRule('maxLength', value => {
  if (!value || value.length > 20) {
    return 'Przekroczono maksymalną liczbę znaków 20.'
  } else {
    return true
  }
})
defineRule('maxLengthNotRequired', (value) => {
  if (value.length > 25) {
    return 'Przekroczono maksymalną liczbę znaków 25.'
  } else {
    return true
  }
})
defineRule('maxValue', value => {
  if (value.length > 8) {
    return 'Przekroczono maksymalną liczbę znaków 8.'
  } else {
    return true
  }
})
defineRule('text', value => {
  if (!value || !value.length) {
    return true
  }

  if (!/^[A-ZŚĆŻŹŁĄÓĘŃa-zśćżźłąóęń,\s]*$/.test(value)) {
    return '* Pole powinno zawierać same litery.'
  }

  return true
})

defineRule('textSpace', value => {
  if (!value || !value.length) {
    return true
  }

  if (!/^[A-ZŚĆŻŹŁĄÓĘŃa-zśćżźłąóęń_ ]*$/.test(value)) {
    return '* Pole powinno zawierać same litery.'
  }

  return true
})

defineRule('number', value => {
  if (!value || !value.length) {
    return true
  }

  if (!/^[0-9]*$/.test(value)) {
    return '* Pole powinno zawierać same cyfry.'
  }

  return true
})

defineRule('postCode', value => {
  if (!value || !value.length) {
    return true
  }

  if (!/^\d{2}-\d{3}$/.test(value)) {
    return '* Pole powinno zawierać kod pocztowy.'
  }

  return true
})

defineRule('textNumber', value => {
  if (!value.length) {
    return true
  }

  if (!/^[0-9A-ZŚĆŻŹŁĄÓĘŃa-zśćżźłąóęń,\s]*$/.test(value)) {
    return '* Pole powinno zawierać cyfry lub litery.'
  }

  return true
})

defineRule('filterNumber', value => {
  if (!value || !value.length) {
    return true
  }

  if (!/^[0-9]*$/.test(value)) {
    return '* Pole powinno zawierać cyfry.'
  } else if (value > 99999) {
    return '* Pole nie może przekraczać 99999'
  }

  return true
})

defineRule('phoneNumber', value => {
  if (!value || !value.length) {
    return true
  }

  if (!/^\d{3}\d{3}\d{3}$/.test(value)) {
    return '* Podaj prawidłowy numer telefonu.'
  }

  return true
})

defineRule('fiscalCode', value => {
  if (!value || !value.length) {
    return true
  }

  if (!/^\d{3}\d{3}\d{2}\d{2}$/.test(value)) {
    return '* Podaj prawidłowy NIP.'
  }

  return true
})

defineRule('noSpecial', value => {
  if (!value.length) {
    return true
  }

  if (!/^[a-zśćżźłąóęńA-ZŚĆŻŹŁĄÓĘŃ0-9.,/ ]{1,30}$/.test(value)) {
    return '* Pole nie może zawierać znaków specjalnych.'
  }

  return true
})
