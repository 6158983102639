<template>
  <div v-if="!isLoading" class="navbar">
    <div class="card p-d-flex p-jc-between p-ai-center" v-if="!isMobile">
      <div class="logo-button-section p-d-flex p-jc-center p-ai-center" :style="{ 'min-width': drawerWidth !== '0' ? drawerWidth : '50' }">
        <router-link to="/">
          <img
            alt="logo"
            src="../assets/abler_logo.png"
            class="navbarLogo"
            :class="{ 'resize-image-collapsed': collapsed }"
          />
        </router-link>
      </div>
      <div class="p-jc-start p-pl-3 navigation-bar-text-wrapper">
        <NavigatorText />
      </div>
      <SearchProductInput class="navigation-bar-search-large"/>
      <div class="shoppingCartAndProfileWrapper p-d-flex p-jc-center p-ai-center">
        <div class="p-d-flex p-jc-center p-ai-center">
          <div class='notification-bell p-mr-3' @click="toggleNotificationOverlayPanel">
            <div class="p-overlay-badge">
              <i class="bell-icon p-text-secondary pi pi-bell" style="font-size: 1.5rem"></i>
            </div>
          </div>
          <div class="new-messages-button p-mr-3" @click="toggleMessagesOverlayPanel">
            <div class="p-overlay-badge">
              <div class="messages-icon p-text-secondary" style="font-size: 1.5rem"></div>
              <span v-if="unreadAmount >= 1" class="p-badge p-badge-sm">
                {{ unreadAmount }}
              </span>
            </div>
          </div>
          <div class="shoppingCartBasket p-mr-3" @click="toggleShoppingBasketOverlayPanel">
            <div class="p-overlay-badge">
              <div class="shopping-cart-icon p-text-secondary" style="font-size: 1.5rem"></div>
              <span v-if="currentShoppingCart?.orderCommodities.length > 0" class="p-badge p-badge-sm">
                {{ currentShoppingCart?.orderCommodities.length }}
              </span>
            </div>
          </div>
          <ToggleButton class="p-d-flex p-ai-center p-jc-center p-mr-3" v-model="priceStatus" onLabel="" offLabel="" onIcon="pi pi-percentage" offIcon="pi pi-money-bill"></ToggleButton>
        </div>
        <div class="user-row p-d-flex p-ai-center p-jc-center">
          <!-- <img class="p-mr-2" alt="avatar" style='width: 30px; height: 30px;' src="@/assets/avatar.png"> -->
          <!--          <p>{{ userProfile.firstName + ' ' + userProfile.lastName }}</p>-->
          <!--          <Button icon="pi pi-angle-down" class="p-button-rounded p-button-help p-button-text" />-->
          <Menubar :model="items"></Menubar>
          <!--          <SplitButton :label="userProfile.firstName + ' ' + userProfile.lastName" icon="pi user-avatar" :model="userMenu">-->
          <!--          </SplitButton>-->
        </div>
      </div>
    </div>
    <div :class="userRole === 'Administrator' ? 'mobileAdminMenu' : userRole === 'Super Administrator' ? 'mobileSuperAdminMenu' : 'mobileUserMenu'" v-else>
      <div class='p-d-flex p-jc-center p-menubar' style='padding: 0.5rem !important'>
        <p>Dostępny kredyt: <b>{{ creditLimit.actualCreditLimit }} zł</b></p>
      </div>
      <Menubar :model="listViews">
        <template #start>
          <router-link to="/">
            <img alt="logo" src="../assets/abler_logo.png" class="navbarLogo p-mr-2" />
          </router-link>
        </template>
        <template #end>
          <div class="shoppingCartAndProfileWrapper p-d-flex p-jc-center p-ai-center">
            <div class="p-d-flex p-jc-center p-ai-center p-mr-3">
              <div class="new-messages-button p-mr-3">
                <div class="p-overlay-badge">
                  <div class="messages-icon p-text-secondary" style="font-size: 1.5rem" v-badge="unreadAmount" @click="toggleMessagesOverlayPanel"></div>
                </div>
              </div>
              <div class="shoppingCartBasket" @click="toggleShoppingBasketOverlayPanel">
                <div class="p-overlay-badge">
                  <div class="shopping-cart-icon p-text-secondary" style="font-size: 1.5rem"></div>
                  <span v-if="currentShoppingCart?.orderCommodities.length > 0" class="p-badge p-badge-sm">
                    {{ currentShoppingCart?.orderCommodities.length }}
                  </span>
                </div>
              </div>
            </div>
            <ToggleButton class="p-d-flex p-ai-center p-jc-center p-mr-3" v-model="priceStatus" onLabel="" offLabel="" onIcon="pi pi-percentage" offIcon="pi pi-money-bill"></ToggleButton>
          </div>
        </template>
      </Menubar>
      <SearchProductInput/>
    </div>
  </div>
  <OverlayPanel class='notificationBefore' ref="overlayNotificationPanelRef">
    <div class="messagesOverlayContainer notificationContent p-pb-1">
      <div class="messagesOverlayHeader">
        <h3 class="p-mt-4">POWIADOMIENIA</h3>
      </div>
      <div class="messagesOverlayContent">
        <div v-if="notificationData.length > 0" >
          <div v-for="(notification, index) of notificationData" :key="index">
            <div class="message-wrapper">
                <div class="p-d-flex p-dir-row p-jc-between p-mb-2">
                  <p class="p-ml-4">{{ notification.notifyDate }}</p>
                </div>
                  <p style='color: black;' class="p-mb-2 p-ml-4 p-mr-2" @click='goToTransactionView(notification.link)'>Twoje zamówienie o nazwie "<b>{{ notification.name }}</b>" zostało skompletowane. Kliknij i przejdź do podsumowania</p>
              </div>
          </div>
        </div>
        <div class="noProductsText p-my-5" v-if="notificationData.length === 0">
          Brak powiadomień!
        </div>
      </div>
    </div>
  </OverlayPanel>
  <OverlayPanel ref="overlayChatPanelRef">
    <div class="messagesOverlayContainer">
      <div class="messagesOverlayHeader">
        <h3 class="p-mt-4">WIADOMOŚCI</h3>
      </div>
      <div class="messagesOverlayContent">
        <div v-if="lastMessages.length > 0">
          <div v-for="(message, index) of lastMessages" :key="index">
            <a class="p-button-link" @click="handleNavigationToMessage(message.id, message.topic, message.status)">
              <div class="message-wrapper">
                <div class="p-d-flex p-dir-row p-jc-between p-mb-2">
                  <p class="p-ml-4">{{ message.startDate }}</p>
                  <p class="p-mr-4" v-if="message.lastUpdateDate">{{ message.lastUpdateDate }}</p>
                </div>
                <p :class="message.read ? 'p-mb-2 p-ml-4 topic-text' : 'p-mb-2 p-ml-4 bold-text topic-text'">{{ message.topic }}</p>
              </div>
            </a>
          </div>
        </div>
        <div class="noProductsText p-my-5" v-if="lastMessages.length === 0">
          Brak wiadomości!
        </div>
      </div>
      <div class="messagesOverlayFooter">
        <Button @click="routeToChat" label="Zobacz wszystkie" />
      </div>
    </div>
  </OverlayPanel>
  <OverlayPanel :showCloseIcon="true" :dismissable="false" ref="overlayPanelRef" appendTo="self" v-if="!isSavingNewBasket">
    <div class="shoppingBasketContainer">
      <div class="shoppingBasketHeader">
        <div class="addNewShoppingBasket">
          <i
            @click="toggleAddingNewShoppingCart"
            class="pi p-ml-2 p-mr-2"
            :class="addingNewShoppingCart === false ? 'pi-plus' : 'pi-minus'"
          ></i>
        </div>
        <Form class="p-d-flex p-jc-center p-ai-center" style="width: 100%" @submit="saveNewShoppingCart">
          <div v-if="addingNewShoppingCart === false" class="changeShoppingBasket">
            <i
              @click="previousShoppingCart"
              class="pi pi-chevron-left p-ml-2 p-mr-2"
              :class="
                shoppingCarts.indexOf(currentShoppingCart) === 0 || currentShoppingCart.id === 'domyslny_koszyk'
                  ? 'disabled'
                  : ''
              "
            ></i>
            <h3>{{ currentShoppingCart.name }}</h3>
            <i
              @click="nextShoppingCart"
              class="pi pi-chevron-right p-ml-2 p-mr-2"
              :class="shoppingCarts.indexOf(currentShoppingCart) === shoppingCarts.length - 1 ? 'disabled' : ''"
            ></i>
          </div>
          <div v-else class="shopping-basket-input">
              <ShoppingCartInput class="labeled-input-wrapper" name="newShoppingCart" placeholder="Podaj nazwę koszyka (max. 35)" v-model:modelValue="newShoppingCartName" rules="required|textNumber|max35"/>
          </div>
          <div class="lastDivShoppingBasket">
            <button type="submit" v-if="addingNewShoppingCart === true">
              <i class="pi pi-check p-ml-2 p-mr-2"></i>
            </button>
          </div>
        </Form>
      </div>
      <div class="shoppingBasketContent">
        <div v-if="currentShoppingCart?.orderCommodities.length > 0">
          <div v-for="product in currentShoppingCart?.orderCommodities" :key="product.id">
            <ShoppingCartProductItem :product="product" :toggleShoppingBasketOverlayPanel="toggleShoppingBasketOverlayPanel"/>
          </div>
        </div>
        <div class="noProductsText" v-if="currentShoppingCart?.orderCommodities.length === 0 && unavailableProducts.orderCommodities.length === 0">
          Wybrany koszyk jest pusty!
        </div>
        <div class="noProductsText" v-if="currentShoppingCart?.orderCommodities.length === 0 && unavailableProducts.orderCommodities.length > 0">
          Wybrany koszyk zawiera tylko <b><u>niedostępne</u> produkty!</b>
        </div>
      </div>
      <div class="shoppingBasketFooter">
        <Button @click="routeToShoppingCart" label="Koszyk" />
        <div class="summary">
          <p>Łączna kwota netto</p>
          <h3>{{ nettoPrice }}zł</h3>
        </div>
      </div>
    </div>
  </OverlayPanel>
  <Dialog :visible="isNewNotification" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}" :modal="true" :closeOnEscape='false' :closable='false'>
    <template #header>
      <div class='p-text-center' style='width:100%'>
        <h2>Drogi kliencie!</h2>
      </div>
    </template>
    <div class='p-text-center'>
      <p style='font-size: 22px;' class='p-my-5'>Skompletowano Twoje zamówienie o nazwie:</p>
      <div>
        <li style='font-size: 20px;' class='p-mb-2' v-for='notification in lastNotification' :key='notification.orderId'>
          <router-link style='text-decoration: none' :to='notification.link' @click='closeDialog'>
            {{ notification.name }}
          </router-link>
        </li>
      </div>
      <p style='font-size: 22px;' class='p-my-5'>Kliknij i przejdź do jego szczegółów</p>
    </div>
    <template #footer>
      <div class='p-text-right'>
        <Button label="zamknij" icon="pi pi-times" @click='closeDialog' class="p-button-text"/>
      </div>
    </template>
  </Dialog>
</template>

<script>
import OverlayPanel from 'primevue/overlaypanel'
import Button from 'primevue/button'
import Menubar from 'primevue/menubar'
import Dialog from 'primevue/dialog'
import BadgeDirective from 'primevue/badgedirective'
import ShoppingCartProductItem from '@/components/shopping_cart/step_one/ShoppingCartProductItem'
import { drawerWidth, collapsed, toggleDrawer } from '@/components/NavigationDrawer/drawerState'
import { ref, computed, inject, onMounted, onUnmounted } from 'vue'
import { navigationItems } from '@/utils/tempData'
import { useStore } from 'vuex'
import router from '@/router'
import { v4 as uuidv4 } from 'uuid'
import NavigatorText from '@/components/NavigatorText'
import { setRole, start, end } from '@/utils/utils'
import ToggleButton from 'primevue/togglebutton'
import { userRoleInterfaceEnum } from '@/enums/userRoleEnum'
import { Form } from 'vee-validate'
import ShoppingCartInput from '@/components/ShoppingCartInput'
import SearchProductInput from '@/components/products/SearchProductInput'

export default {
  name: 'NavigationBar',
  components: {
    SearchProductInput,
    ShoppingCartInput,
    OverlayPanel,
    Button,
    Menubar,
    Form,
    ToggleButton,
    ShoppingCartProductItem,
    NavigatorText,
    Dialog
  },
  directives: {
    badge: BadgeDirective
  },
  emits: ['synchronizeUsers', 'changePriceStatus'],
  setup (props, { emit }) {
    const store = useStore()
    const keycloak = inject(process.env.VUE_APP_KEYCLOAK_PROVIDE_VARIABLE)
    const chatService = store.getters['serviceStore/getChatService']
    const shoppingCartService = store.getters['serviceStore/getShoppingCartService']
    const userService = store.getters['serviceStore/getUserService']

    const shoppingCarts = computed(() => store.getters['shoppingCartStore/getShoppingCarts'])
    const currentShoppingCart = computed(() => store.getters['shoppingCartStore/getCurrentShoppingCart'])
    const nettoPrice = computed(() => currentShoppingCart.value.getTotalNettoPrice(true))
    const unavailableProducts = computed(() => store.getters['shoppingCartStore/getUnavailableItems'])
    const lastNotification = computed(() => store.getters['userStore/getNotificationValue'])
    const notificationData = computed(() => JSON.parse(JSON.stringify(store.getters['userStore/getNotificationData'])))
    const creditLimit = computed(() => store.getters['userStore/getCreditLimit'])

    const isLoading = ref(true)
    const isSavingNewBasket = ref(false)
    const overlayPanelRef = ref(null)
    const overlayChatPanelRef = ref(null)
    const overlayNotificationPanelRef = ref(null)
    const isNewNotification = ref(false)

    const addingNewShoppingCart = ref(false)
    const newShoppingCartName = ref(null)
    const userProfile = ref('')
    const lastMessages = computed(() => JSON.parse(JSON.stringify(store.getters['chatStore/getLastChats'])))
    const unreadAmount = computed(() => JSON.parse(JSON.stringify(store.getters['chatStore/getUnreadAmount'])))
    const isMobile = computed(() => {
      return screen.width < 768
    })
    const listViews = ref(null)
    const userRole = ref('')
    let currentRoomIntervalId

    const priceStatus = computed({
      get () {
        return store.getters['productStore/getIsAfterDiscount']
      },
      async set () {
        try {
          if (priceStatus.value) {
            await store.dispatch('productStore/changeStateOfDiscountStatus', false)
            await store.dispatch('snackbarStore/showMessage', 'Ustawiono ceny na status: Przed rabatem')
          } else {
            await store.dispatch('productStore/changeStateOfDiscountStatus', true)
            await store.dispatch('snackbarStore/showMessage', 'Ustawiono ceny na status: Po rabacie')
          }
        } catch (error) {
          await store.dispatch('snackbarStore/showMessage', 'Nastąpił błąd w zmianie cen')
        }
      }
    })

    const items = ref([])
    const itemsSuperAdmin = ref([
      {
        label: 'Synchronizuj konta',
        icon: 'pi pi-history',
        command: () => {
          emit('synchronizeUsers')
        }
      },
      {
        label: 'Zarządzanie oddziałami',
        icon: 'pi pi-building',
        command: () => {
          if (collapsed.value) {
            toggleDrawer()
          }
          router.push({ name: 'Departments Management' })
        }
      },
      {
        label: 'Konfiguracja karuzeli',
        name: 'cut-icon',
        icon: 'pi pi-image',
        command: () => {
          if (collapsed.value) {
            toggleDrawer()
          }
          router.push({ name: 'Carousel config' })
        }
      },
      {
        key: 1,
        label: 'Katalog produktów',
        icon: 'pi pi-box',
        command: () => {
          if (collapsed.value) {
            toggleDrawer()
          }
          router.push({ name: 'Products' })
        }
      },
      {
        label: 'Zamówienia',
        icon: 'pi pi-credit-card',
        command: () => {
          if (collapsed.value) {
            toggleDrawer()
          }
          router.push({ name: 'Orders' })
        }
      },
      {
        label: 'Moje faktury',
        icon: 'pi pi-folder-open',
        command: () => {
          if (collapsed.value) {
            toggleDrawer()
          }
          router.push({ name: 'InvoicesManagement' })
        }
      },
      {
        label: 'Podgląd koszyków',
        icon: 'pi pi-shopping-cart',
        command: () => {
          if (collapsed.value) {
            toggleDrawer()
          }
          router.push({ name: 'CartPreview' })
        }
      },
      {
        key: 2,
        label: 'Rozkrój płyt',
        name: 'cut-icon',
        icon: 'pi pi-th-large',
        command: () => {
          if (collapsed.value) {
            toggleDrawer()
          }
          router.push({ name: 'Cutting boards' })
        }
      },
      {
        label: 'Aktualności',
        name: 'cut-icon',
        icon: 'pi pi-tablet',
        items: [
          {
            label: 'Edytuj aktualności',
            command: () => {
              if (collapsed.value) {
                toggleDrawer()
              }
              router.push('/articles-config-view?from=0&to=9')
            }
          },
          {
            label: 'Dodaj aktualności',
            command: () => {
              if (collapsed.value) {
                toggleDrawer()
              }
              router.push({ name: 'Article new', query: { articleId: '' } })
            }
          }
        ],
        command: () => {
          if (collapsed.value) {
            toggleDrawer()
          }
          router.push({ name: 'Articles', query: { from: start, to: end } })
        }
      },
      {
        label: 'Program lojalnościowy',
        icon: 'pi pi-users',
        command: () => {
          if (collapsed.value) {
            toggleDrawer()
          }
          router.push({ name: 'Loyalty program' })
        },
        items: [
          {
            label: 'Edycja programu',
            command: () => {
              if (collapsed.value) {
                toggleDrawer()
              }
              router.push({ name: 'Loyalty program config' })
            }
          },
          {
            label: 'Edycja kategorii',
            command: () => {
              if (collapsed.value) {
                toggleDrawer()
              }
              router.push({ name: 'Loyalty program categories config' })
            }
          },
          {
            label: 'Zamówione nagrody',
            command: () => {
              if (collapsed.value) {
                toggleDrawer()
              }
              router.push({ name: 'Loyalty program orders' })
            }
          }
        ]
      },
      {
        label: 'Do pobrania',
        icon: 'pi pi-download',
        command: () => {
          if (collapsed.value) {
            toggleDrawer()
          }
          router.push({ name: 'Downloads' })
        },
        items: [
          {
            label: 'Konfiguracja plików',
            command: () => {
              if (collapsed.value) {
                toggleDrawer()
              }
              router.push({ name: 'Regulations config' })
            }
          }
        ]
      },
      {
        label: 'FAQ',
        icon: 'pi pi-question-circle',
        command: () => {
          if (collapsed.value) {
            toggleDrawer()
          }
          router.push({ name: 'FAQ' })
        },
        items: [
          {
            label: 'Edycja FAQ',
            command: () => {
              if (collapsed.value) {
                toggleDrawer()
              }
              router.push({ name: 'FAQ config' })
            }
          }
        ]
      },
      {
        label: 'Newsletter',
        icon: 'pi pi-at',
        items: [
          {
            label: 'Tworzenie newslettera',
            command: () => {
              if (collapsed.value) {
                toggleDrawer()
              }
              router.push({ name: 'Newsletter config' })
            }
          },
          {
            label: 'Szablon newslettera',
            command: () => {
              if (collapsed.value) {
                toggleDrawer()
              }
              router.push({ name: 'Newsletter template' })
            }
          },
          {
            label: 'Zgody',
            command: () => {
              if (collapsed.value) {
                toggleDrawer()
              }
              router.push({ name: 'Newsletter regulations' })
            }
          },
          {
            label: 'Zarządzanie kategoriami',
            command: () => {
              if (collapsed.value) {
                toggleDrawer()
              }
              router.push({ name: 'Newsletter categories config' })
            }
          }
        ],
        command: () => {
          if (collapsed.value) {
            toggleDrawer()
          }
          router.push({ name: 'Newsletter' })
        }
      },
      {
        label: 'Regulaminy',
        icon: 'pi pi-book',
        command: () => {
          if (collapsed.value) {
            toggleDrawer()
          }
          router.push({ name: 'RegulationsConfig' })
        }
      },
      // {
      //   label: 'Ustawienia konta',
      //   icon: 'pi pi-user-edit',
      //   command: () => {
      //     if (collapsed.value) {
      //       toggleDrawer()
      //     }
      //     router.push({ name: 'Account' })
      //   }
      // },
      {
        label: 'Zarządzanie fakturami',
        icon: 'pi pi-folder-open',
        command: () => {
          if (collapsed.value) {
            toggleDrawer()
          }
          router.push({ name: 'InvoicesManagement' })
        }
      },
      {
        label: 'Chat',
        icon: 'pi pi-envelope',
        command: () => {
          if (collapsed.value) {
            toggleDrawer()
          }
          router.push({ name: 'Chat' })
        }
      },
      {
        label: 'Kontakt',
        icon: 'pi pi-phone',
        command: () => {
          if (collapsed.value) {
            toggleDrawer()
          }
          window.open('https://www.abler.pl/kontakt', '__blank', 'noopener noreferrer')
        }
      },
      {
        label: 'Logi',
        icon: 'pi pi-folder-open',
        command: () => {
          router.push({ name: 'Logs' })
        }
      },
      {
        label: 'Wyloguj się',
        icon: ' pi pi-sign-out',
        command: async () => {
          window.localStorage.clear()
          await keycloak.logout({ redirectUri: window.location.origin })
        }
      }
    ])
    const itemsAdmin = ref([
      {
        label: 'Synchronizuj konta',
        icon: 'pi pi-history',
        command: () => {
          emit('synchronizeUsers')
        }
      },
      {
        key: 1,
        label: 'Katalog produktów',
        icon: 'pi pi-box',
        command: () => {
          if (collapsed.value) {
            toggleDrawer()
          }
          router.push({ name: 'Products' })
        }
      },
      {
        label: 'Moje zamówienia',
        icon: 'pi pi-credit-card',
        command: () => {
          if (collapsed.value) {
            toggleDrawer()
          }
          router.push({ name: 'Orders' })
        }
      },
      {
        label: 'Moje faktury',
        icon: 'pi pi-folder-open',
        command: () => {
          if (collapsed.value) {
            toggleDrawer()
          }
          router.push({ name: 'InvoicesManagement' })
        }
      },
      {
        label: 'Podgląd koszyków',
        icon: 'pi pi-shopping-cart',
        command: () => {
          if (collapsed.value) {
            toggleDrawer()
          }
          router.push({ name: 'CartPreview' })
        }
      },
      {
        key: 2,
        label: 'Rozkrój płyt',
        name: 'cut-icon',
        icon: 'pi pi-th-large',
        command: () => {
          if (collapsed.value) {
            toggleDrawer()
          }
          router.push({ name: 'Cutting boards' })
        }
      },
      {
        label: 'Aktualności',
        name: 'cut-icon',
        icon: 'pi pi-tablet',
        items: [
          {
            label: 'Edytuj aktualności',
            command: () => {
              if (collapsed.value) {
                toggleDrawer()
              }
              router.push('/articles-config-view?from=0&to=9')
            }
          },
          {
            label: 'Dodaj aktualność',
            command: () => {
              if (collapsed.value) {
                toggleDrawer()
              }
              router.push({ name: 'Article new', query: { articleId: '' } })
            }
          }
        ],
        command: () => {
          if (collapsed.value) {
            toggleDrawer()
          }
          router.push({ name: 'Articles', query: { from: start, to: end } })
        }
      },
      {
        label: 'Konfiguracja karuzeli',
        name: 'cut-icon',
        icon: 'pi pi-image',
        command: () => {
          if (collapsed.value) {
            toggleDrawer()
          }
          router.push({ name: 'Carousel config' })
        }
      },
      {
        label: 'Program lojalnościowy',
        icon: 'pi pi-users',
        command: () => {
          if (collapsed.value) {
            toggleDrawer()
          }
          router.push({ name: 'Loyalty program' })
        },
        items: [
          {
            label: 'Edycja programu',
            command: () => {
              if (collapsed.value) {
                toggleDrawer()
              }
              router.push({ name: 'Loyalty program config' })
            }
          },
          {
            label: 'Edycja kategorii',
            command: () => {
              if (collapsed.value) {
                toggleDrawer()
              }
              router.push({ name: 'Loyalty program categories config' })
            }
          },
          {
            label: 'Zamówione nagrody',
            command: () => {
              if (collapsed.value) {
                toggleDrawer()
              }
              router.push({ name: 'Loyalty program orders' })
            }
          }
        ]
      },
      {
        label: 'Do pobrania',
        icon: 'pi pi-download',
        command: () => {
          if (collapsed.value) {
            toggleDrawer()
          }
          router.push({ name: 'Downloads' })
        },
        items: [
          {
            label: 'Konfiguracja plików',
            command: () => {
              if (collapsed.value) {
                toggleDrawer()
              }
              router.push({ name: 'Regulations config' })
            }
          }
        ]
      },
      {
        label: 'FAQ',
        icon: 'pi pi-question-circle',
        command: () => {
          if (collapsed.value) {
            toggleDrawer()
          }
          router.push({ name: 'FAQ' })
        },
        items: [
          {
            label: 'Edycja FAQ',
            command: () => {
              if (collapsed.value) {
                toggleDrawer()
              }
              router.push({ name: 'FAQ config' })
            }
          }
        ]
      },
      {
        label: 'Newsletter',
        icon: 'pi pi-at',
        items: [
          {
            label: 'Tworzenie newslettera',
            command: () => {
              if (collapsed.value) {
                toggleDrawer()
              }
              router.push({ name: 'Newsletter config' })
            }
          },
          {
            label: 'Szablon newslettera',
            command: () => {
              if (collapsed.value) {
                toggleDrawer()
              }
              router.push({ name: 'Newsletter template' })
            }
          },
          {
            label: 'Zgody',
            command: () => {
              if (collapsed.value) {
                toggleDrawer()
              }
              router.push({ name: 'Newsletter regulations' })
            }
          },
          {
            label: 'Zarządzanie kategoriami',
            command: () => {
              if (collapsed.value) {
                toggleDrawer()
              }
              router.push({ name: 'Newsletter categories config' })
            }
          }
        ],
        command: () => {
          if (collapsed.value) {
            toggleDrawer()
          }
          router.push({ name: 'Newsletter' })
        }
      },
      {
        label: 'Regulaminy',
        icon: 'pi pi-book',
        command: () => {
          if (collapsed.value) {
            toggleDrawer()
          }
          router.push({ name: 'RegulationsConfig' })
        }
      },
      // {
      //   label: 'Ustawienia konta',
      //   icon: 'pi pi-user-edit',
      //   command: () => {
      //     if (collapsed.value) {
      //       toggleDrawer()
      //     }
      //     router.push({ name: 'Account' })
      //   }
      // },
      {
        label: 'Chat',
        icon: 'pi pi-envelope',
        command: () => {
          if (collapsed.value) {
            toggleDrawer()
          }
          router.push({ name: 'Chat' })
        }
      },
      {
        label: 'Kontakt',
        icon: 'pi pi-phone',
        command: () => {
          if (collapsed.value) {
            toggleDrawer()
          }
          window.open('https://www.abler.pl/kontakt', '__blank', 'noopener noreferrer')
        }
      },
      {
        label: 'Logi',
        icon: 'pi pi-folder-open',
        command: () => {
          router.push({ name: 'Logs' })
        }
      },
      {
        label: 'Wyloguj się',
        icon: ' pi pi-sign-out',
        command: async () => {
          window.localStorage.clear()
          await keycloak.logout({ redirectUri: window.location.origin })
        }
      }
    ])

    const itemsUser = ref([
      {
        key: 1,
        label: 'Katalog produktów',
        icon: 'pi pi-box',
        command: () => {
          if (collapsed.value) {
            toggleDrawer()
          }
          router.push({ name: 'Products' })
        }
      },
      {
        label: 'Moje zamówienia',
        icon: 'pi pi-credit-card',
        command: () => {
          if (collapsed.value) {
            toggleDrawer()
          }
          router.push({ name: 'Orders' })
        }
      },
      {
        label: 'Moje faktury',
        icon: 'pi pi-folder-open',
        command: () => {
          if (collapsed.value) {
            toggleDrawer()
          }
          router.push({ name: 'InvoicesManagement' })
        }
      },
      {
        key: 2,
        label: 'Rozkrój płyt',
        name: 'cut-icon',
        icon: 'pi pi-th-large',
        command: () => {
          if (collapsed.value) {
            toggleDrawer()
          }
          router.push({ name: 'Cutting boards' })
        }
      },
      {
        label: 'Aktualności',
        name: 'cut-icon',
        icon: 'pi pi-tablet',
        command: () => {
          if (collapsed.value) {
            toggleDrawer()
          }
          router.push({ name: 'Articles' })
        }
      },
      {
        label: 'Program lojalnościowy',
        icon: 'pi pi-users',
        command: () => {
          if (collapsed.value) {
            toggleDrawer()
          }
          router.push({ name: 'Loyalty program' })
        },
        items: [
          {
            label: 'Zamówione nagrody',
            command: () => {
              if (collapsed.value) {
                toggleDrawer()
              }
              router.push({ name: 'Loyalty program orders' })
            }
          }
        ]
      },
      {
        label: 'Do pobrania',
        icon: 'pi pi-download',
        command: () => {
          if (collapsed.value) {
            toggleDrawer()
          }
          router.push({ name: 'Downloads' })
        }
      },
      {
        label: 'FAQ',
        icon: 'pi pi-question-circle',
        command: () => {
          if (collapsed.value) {
            toggleDrawer()
          }
          router.push({ name: 'FAQ' })
        }
      },
      {
        label: 'Newsletter',
        icon: 'pi pi-at',
        command: () => {
          if (collapsed.value) {
            toggleDrawer()
          }
          router.push({ name: 'Newsletter' })
        }
      },
      {
        label: 'Chat',
        icon: 'pi pi-envelope',
        command: () => {
          if (collapsed.value) {
            toggleDrawer()
          }
          router.push({ name: 'Chat' })
        }
      },
      {
        label: 'Kontakt',
        icon: 'pi pi-phone',
        command: () => {
          if (collapsed.value) {
            toggleDrawer()
          }
          window.open('https://www.abler.pl/kontakt', '__blank', 'noopener noreferrer')
        }
      },
      {
        label: 'Wyloguj się',
        icon: ' pi pi-sign-out',
        command: async () => {
          window.localStorage.clear()
          await keycloak.logout({ redirectUri: window.location.origin })
        }
      }
    ])

    const loadData = async () => {
      try {
        await store.dispatch('chatStore/fetchUnreadAmount', { chatService: chatService })
        await store.dispatch('chatStore/fetchLastMessages', { chatService: chatService })
        await store.dispatch('userStore/fetchNotificationData', { userService: userService })
      } catch (error) {
      }
    }

    const loadShoppingCarts = async () => {
      await store.dispatch('shoppingCartStore/fetchShoppingCarts', { shoppingCartService: shoppingCartService })

      if (shoppingCarts.value.length > 0) {
        try {
          await store.dispatch('shoppingCartStore/setCurrentShoppingCartId', {
            shoppingCartService: shoppingCartService,
            shoppingCartId: shoppingCarts.value[0].id
          })
        } catch (e) {
          isLoading.value = false
        }
      }
      userProfile.value = await keycloak.loadUserProfile()
    }

    const closeDialog = () => {
      isNewNotification.value = false
    }

    const handleScroll = (event) => {
      if (overlayPanelRef.value !== null) {
        overlayPanelRef.value.hide()
      }
      if (overlayChatPanelRef.value !== null) {
        overlayChatPanelRef.value.hide()
      }
      if (overlayNotificationPanelRef.value !== null) {
        overlayNotificationPanelRef.value.hide()
      }
    }

    onMounted(async () => {
      isLoading.value = true
      await loadShoppingCarts()

      window.addEventListener('scroll', handleScroll)

      await loadData()
      userRole.value = setRole(keycloak.realmAccess.roles)
      switch (userRole.value) {
        case userRoleInterfaceEnum.SUPER_ADMIN: {
          listViews.value = itemsSuperAdmin.value
          break
        }
        case userRoleInterfaceEnum.ADMIN: {
          listViews.value = itemsAdmin.value
          break
        }
        case userRoleInterfaceEnum.USER: {
          listViews.value = itemsUser.value
          break
        }
        default: {
          listViews.value = itemsUser.value
        }
      }
      try {
        await store.dispatch('userStore/fetchNotifications', { userService: userService }).then(response => {
          if (response.length > 0) {
            isNewNotification.value = true
          }
        })
      } catch (error) {
        await store.dispatch('snackbarStore/showMessage', 'Wystąpił błąd przy pobieraniu powiadomień')
      }

      try {
        await store.dispatch('userStore/fetchNotificationData', { userService: userService })
      } catch (error) {
        await store.dispatch('snackbarStore/showMessage', 'Wystąpił błąd przy pobieraniu bazy powiadomień')
      }
      if (isMobile.value) {
        try {
          await store.dispatch('userStore/fetchMerchantLimit', {
            userService: userService
          })
        } catch (error) {
          await store.dispatch('snackbarStore/showMessage', 'Nastąpił błąd w pobieraniu kontraktora')
        }
      }
      currentRoomIntervalId = setInterval(function () {
        loadData()
      }, 30000)

      items.value = [
        {
          key: 1,
          label: userProfile.value.firstName + ' ' + userProfile.value.lastName,
          icon: 'pi user-avatar',
          items: [
            // {
            //   label: 'Ustawienia konta',
            //   command: () => {
            //     router.push({ name: 'Account' })
            //   }
            // },
            // {
            //   label: 'Dane adresowe',
            //   command: () => {
            //     router.push({ name: 'Account' })
            //   }
            // },
            // {
            //   label: 'Dane do faktury',
            //   command: () => {
            //     router.push({ name: 'Account' })
            //   }
            // },
            // {
            //   label: 'Zarządzanie fakturami',
            //   command: () => {
            //     router.push({ name: 'InvoicesManagement' })
            //   }
            // },
            // {
            //   label: 'Zamówienia',
            //   command: () => {
            //     router.push({ name: 'Orders' })
            //   }
            // },
            {
              label: 'Zarządzaj avatarem',
              command: async () => {
                router.push({ name: 'Avatar Management' })
              }
            },
            {
              label: 'Wyloguj się',
              command: async () => {
                window.localStorage.clear()
                await keycloak.logout({ redirectUri: window.location.origin })
              }
            }
          ]
        }
      ]
      isLoading.value = false
    })

    const goToTransactionView = (link) => {
      const currentPath = router.currentRoute.value.path + `${link}`
      if (currentPath === '/transaction-status' + link) {
        store.dispatch('snackbarStore/showMessage', 'Znajdujesz się w tym podsumowaniu!')
      } else {
        router.push(link)
      }
      overlayNotificationPanelRef.value.toggle()
    }

    const toggleShoppingBasketOverlayPanel = (event) => {
      if (!router.currentRoute.value.path.includes('shopping-cart')) {
        if (screen.width >= 768) {
          overlayPanelRef.value.toggle(event)
        } else {
          router.push('/shopping-cart')
        }
      } else {
        store.dispatch('snackbarStore/showMessage', 'Aktualnie jesteś już w koszyku!')
      }
    }

    const toggleMessagesOverlayPanel = (event) => {
      if (screen.width >= 768) {
        overlayChatPanelRef.value.toggle(event)
      } else {
        router.push('/chat')
      }
    }

    const toggleNotificationOverlayPanel = (event) => {
      overlayNotificationPanelRef.value.toggle(event)
    }

    const toggleAddingNewShoppingCart = () => {
      addingNewShoppingCart.value = !addingNewShoppingCart.value
    }

    const saveNewShoppingCart = async () => {
      if (newShoppingCartName.value !== '') {
        // const newShoppingCart = new ShoppingCart(
        //   {
        //     id: uuidv4(),
        //     name: newShoppingCartName.value,
        //     initDate: parseArticleDate(Date.now()),
        //     orderCommodities: []
        //   }
        // )
        // newShoppingCartName.value = ''
        // currentShoppingCart.value = new ShoppingCart(JSON.parse(JSON.stringify(newShoppingCart)))
        if (keycloak.token !== undefined) {
          isSavingNewBasket.value = true
          try {
            const tempShoppingCartId = uuidv4()
            await store.dispatch('shoppingCartStore/addShoppingCart', {
              shoppingCartService: shoppingCartService,
              orderId: tempShoppingCartId,
              basketName: newShoppingCartName.value
            })
            toggleAddingNewShoppingCart()
            newShoppingCartName.value = ''
            isSavingNewBasket.value = false
          } catch (e) {
            isSavingNewBasket.value = false
          }
        }
      } else {
        await store.dispatch('snackbarStore/showMessage', 'Podaj nazwę koszyka przed zapisem!')
      }
    }

    const previousShoppingCart = () => {
      const currentIndex = shoppingCarts.value.indexOf(currentShoppingCart.value)

      if (currentIndex !== 0) {
        store.dispatch('shoppingCartStore/setCurrentShoppingCartId', {
          shoppingCartService: shoppingCartService,
          shoppingCartId: shoppingCarts.value[currentIndex - 1].id
        })
      }
    }

    const nextShoppingCart = () => {
      const currentIndex = shoppingCarts.value.indexOf(currentShoppingCart.value)

      if (currentIndex !== shoppingCarts.value.length - 1) {
        store.dispatch('shoppingCartStore/setCurrentShoppingCartId', {
          shoppingCartService: shoppingCartService,
          shoppingCartId: shoppingCarts.value[currentIndex + 1].id
        })
      }
    }
    const routeToShoppingCart = async () => {
      toggleShoppingBasketOverlayPanel()

      if (currentShoppingCart.value.id === 'domyslny_koszyk') {
        try {
          const tempShoppingCartId = uuidv4()
          await store.dispatch('shoppingCartStore/addShoppingCart', {
            shoppingCartService: shoppingCartService,
            orderId: tempShoppingCartId,
            basketName: 'Domyślny koszyk'
          })
          await router.push('/shopping-cart')
        } catch (e) {}
      } else {
        const currentIndex = shoppingCarts.value.indexOf(currentShoppingCart.value)
        await store.dispatch('shoppingCartStore/setCurrentShoppingCartId', {
          shoppingCartService: shoppingCartService,
          shoppingCartId: shoppingCarts.value[currentIndex].id
        })
        await router.push('/shopping-cart')
      }
    }

    const routeToChat = () => {
      toggleMessagesOverlayPanel()

      router.push('/chat')
    }

    const onUserClick = async () => {
      if (keycloak.token === undefined || keycloak.isTokenExpired()) {
        await keycloak.login()
      } else {
        await router.push('/account')
      }
    }
    onUnmounted(() => {
      clearInterval(currentRoomIntervalId)
    })

    const handleNavigationToMessage = (id, topic, currentStatus) => {
      router.push({ name: 'Chat room', query: { chatRoomId: id, topic: topic, currentStatus: currentStatus } })
      toggleMessagesOverlayPanel()
    }
    return {
      isLoading,
      navigationItems,
      isMobile,
      priceStatus,
      items,
      userProfile,
      unreadAmount,
      toggleShoppingBasketOverlayPanel,
      saveNewShoppingCart,
      toggleMessagesOverlayPanel,
      overlayPanelRef,
      overlayChatPanelRef,
      toggleAddingNewShoppingCart,
      addingNewShoppingCart,
      currentShoppingCart,
      lastMessages,
      handleNavigationToMessage,
      previousShoppingCart,
      nextShoppingCart,
      shoppingCarts,
      newShoppingCartName,
      routeToShoppingCart,
      routeToChat,
      onUserClick,
      isSavingNewBasket,
      drawerWidth,
      collapsed,
      nettoPrice,
      unavailableProducts,
      listViews,
      userRole,
      toggleNotificationOverlayPanel,
      lastNotification,
      overlayNotificationPanelRef,
      closeDialog,
      isNewNotification,
      notificationData,
      goToTransactionView,
      creditLimit
    }
  }
}
</script>
<style lang="scss" scoped>
.navigation-bar-text-wrapper{
  display: flex;
  width: auto;

  @media screen and (max-width: 1300px) {
    display: none;
  }
}
.navigation-bar-search-large{
  display: flex;
  @media screen and (max-width: 767px) {
    display: none;
  }
}
.p-togglebutton.p-button{
  background: white;
  border: 0;
  border-radius: 0;

  ::v-deep(.p-button-icon){
    color: black;
  }

  &:hover{
    color: white;
    ::v-deep(.p-button-icon){
      color: black;
    }
  }
}
.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover{
  background: white;
}
.p-togglebutton.p-button.p-highlight {
  background: white;
  color: white;

  ::v-deep(.p-button-icon){
    color: black;
  }
  &:hover{
    background: white;

    ::v-deep(.p-button-icon){
      color: black;
    }
  }
}
.message-wrapper{
  .bold-text {
    font-weight: 600;
  }

  .topic-text{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1.1rem;
  }
}
.message-wrapper:hover {
  box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 1px 8px 0 rgba(0, 0, 0, 0.12);
}
.navbar {
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: #ffffff;

  .p-menubar{
    background: white;
    border-top: 1px solid #888;
    border-bottom: 0;
    padding: 1rem 1rem 0 1rem;
  }
  .search-input-wrapper{
    background: white;

    @media screen and (max-width: 767px) {
      border-bottom: 1px solid #888;
    }
  }
  ::v-deep(.pi) {
    font-size: 1.5rem;
  }
  ::v-deep(.pi-chevron-down){
    font-size: 1rem;
  }

  .card {
    height: 100px;

    .logo-button-section {
      border-right-width: 2px;
      border-right-color: #dfdfdf;
      border-right-style: solid;
      height: 100px;
      transition: 0.3s ease;

      @media screen and (max-width: 768px) {
        width: 75px !important;
      }
    }

    .text-section {
      @media screen and (max-width: 768px) {
        display: none !important;
      }
    }
    .text-section-collapsed {
      padding-left: 2rem !important;

      @media screen and (max-width: 768px) {
        width: calc(100% - 250px);
        padding-left: 0.1rem !important;
      }
    }
  }
  img {
    width: 133.94px;
    height: 66.33px;
    transition: 0.3s ease;

    @media screen and (max-width: 768px) {
      width: calc(133.94px / 2);
      height: calc(66.33px / 2);
    }
  }

  .resize-image-collapsed {
    width: calc(133.94px / 2);
    height: calc(66.33px / 2);
  }
}

.disabled {
  opacity: 30%;
  cursor: default !important;
}

.noProductsText {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  height: 100%;
}
  @media screen and (max-width: 1110px) {
    .mobileAdminMenu {
      ::v-deep(.p-menubar.p-menubar-mobile-active .p-menubar-root-list) {
        overflow: scroll;
        height: 430px;
        justify-content: start;
      }
    }

    .mobileUserMenu {
      ::v-deep(.p-menubar.p-menubar-mobile-active .p-menubar-root-list) {
        overflow: scroll;
        height: 430px;
        justify-content: start;
      }
    }

    .mobileSuperAdminMenu {
      ::v-deep(.p-menubar.p-menubar-mobile-active .p-menubar-root-list) {
        overflow: scroll;
        height: 430px;
        justify-content: start;
      }
    }

    .user-row {
      ::v-deep(.p-menubar.p-menubar-mobile-active .p-menubar-root-list) {
        width: 16rem;
        left: -140px;
        justify-content: start;
      }
    }
  }
.shoppingCartAndProfileWrapper {
  padding-right: 30px;

  @media screen and (max-width: 768px) {
    margin-right: 5px;
    padding-right: 0;
  }

  .shoppingCartBasket {
    cursor: pointer;
    padding: 5px 8px;
    font-size: 9px;
    font-weight: 500;
    border-radius: 10px;
    color: black;
    display: flex;

    .shopping-cart-icon {
      width: 31px;
      height: 31px;
      background-image: url('../assets/icons/shopping_basket_icon.svg');
    }

    .shoppingCartNameSpan {
      width: 100%;
      margin-left: 15px;
      align-self: center;
      text-transform: uppercase;
    }
  }
  .new-messages-button {
    cursor: pointer;
    padding: 5px 8px;
    font-size: 9px;
    font-weight: 500;
    border-radius: 10px;
    color: black;
    display: flex;

    .messages-icon {
      width: 24px;
      height: 18px;
      background-image: url('../assets/icons/messages_icon.svg');
    }
  }

  .notification-bell {
    cursor: pointer;
    padding: 5px 8px;
    font-size: 9px;
    font-weight: 500;
    border-radius: 10px;
    color: black;
    display: flex;
    .bell-icon {
      width: 24px;
      height: 18px;
      color: black;
    }
  }

  .shoppingCartProfile {
    color: black;
    cursor: pointer;
  }

  .user-row {
    ::v-deep(.p-menubar) {
      background: #ffffff;
      border: none;
      padding: 0;

      .p-submenu-list {
        width: 100%;
      }
    }

    //::v-deep(.user-avatar) {
    //  width: 50px;
    //  height: 30px;
    //  background-image: url('../assets/avatar.png');
    //  background-size: contain;
    //  background-repeat: no-repeat;
    //}
  }
}

.shoppingBasketContainer {
  width: 350px;
  background-color: white;

  .shoppingBasketHeader {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    padding: 1rem 1rem 0 1rem;

    .labeled-input-wrapper{
      ::v-deep(input){
        margin-top: 0;
      }
    }

    .addNewShoppingBasket {
      i {
        cursor: pointer;
      }
    }

    .shopping-basket-input{
      flex: 5;

      input{
        width: 100%;
      }
    }
    .changeShoppingBasket {
      display: flex;
      flex: auto;
      align-items: center;
      justify-content: space-between;

      i {
        font-size: 12px;
        cursor: pointer;
      }
    }

    .lastDivShoppingBasket {
      text-align: end;

      button{
        background-color: white;
        border: none;
      }

      i {
        cursor: pointer;
      }
    }

    .addingNewShoppingCartInput {
      height: 24px;
      border: none;
      border-bottom: 1px solid black;
      padding: 0 4px;
    }
  }

  .shoppingBasketContent {
    padding: 0 0.2rem 0.3rem 0.7rem;
    height: 320px;
    overflow-y: auto;

    p {
      font-size: 12px;
      text-align: center;
      margin-top: 16px;
    }

    .message-wrapper {
      cursor: pointer;
      padding-top: 20px;
      padding-bottom: 20px;
      border-bottom-color: #a4a4a4;
      border-style: hidden hidden solid hidden;
      border-width: 0.5px;

      .bold-text {
        font-weight: 600;
      }
    }
  }

  .shoppingBasketFooter {
    border-top: 1px solid #c8c8c8;
    display: flex;
    padding: 0.6rem 1rem;

    button {
      background-color: $primary_color;
      border: 1px solid $primary_color;
      color: white;
      border-radius: 0;
      padding: 12px 30px;

      &:hover {
        background-color: white;
        border: 1px solid #b72937;
        color: #b72937;
      }
    }

    .summary {
      flex: 2;
      justify-content: center;
      align-items: center;
      align-self: center;
      text-align: right;
    }
  }
}

.messagesOverlayContainer {
  width: 350px;
  background-color: white;

  .messagesOverlayHeader {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;

    .addNewShoppingBasket {
      i {
        cursor: pointer;
      }
    }

    .changeShoppingBasket {
      display: flex;
      flex: auto;
      align-items: center;
      justify-content: space-between;

      i {
        font-size: 12px;
        cursor: pointer;
      }
    }

    .lastDivShoppingBasket {
      flex: 1;
      text-align: end;

      button{
        background-color: white;
        border: none;
      }

      i {
        cursor: pointer;
      }
    }

    .addingNewShoppingCartInput {
      height: 24px;
      border: none;
      border-bottom: 1px solid black;
      padding: 0 4px;
    }
  }

  .messagesOverlayContent {
    p {
      margin-top: 16px;
    }

    .message-wrapper {
      cursor: pointer;
      padding-top: 5px;
      padding-bottom: 5px;
      border-bottom-color: #a4a4a4;
      border-style: hidden hidden solid hidden;
      border-width: 0.5px;

      .bold-text {
        font-weight: 600;
      }
    }
  }

  .messagesOverlayFooter {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.6rem 1rem;

    button {
      background-color: $primary_color;
      border: 1px solid $primary_color;
      border-radius: 0;
      color: white;
      padding: 12px 30px;

      &:hover {
        background-color: white;
        border: 1px solid #b72937;
        color: #b72937;
      }
    }
  }
}
.notificationContent {
  max-height: 399px;
  overflow:scroll;
}
</style>
