import { ChatMessage } from '@/models/chatMessage'

const state = {
  usersToAssign: [],
  inProgressChats: [],
  lastMessages: [],
  closedChats: [],
  unreadAmount: 0,
  uploadProgress: 0,
  currentChatMessages: []
}

const getters = {
  getProgress (state) {
    return state.uploadProgress
  },
  getInProgressChats (state) {
    return state.inProgressChats
  },
  getLastChats (state) {
    return state.lastMessages.chats
  },
  getClosedChats (state) {
    return state.closedChats
  },
  getUnreadAmount (state) {
    return state.unreadAmount
  },
  getUsersToAssign (state) {
    return state.usersToAssign
  },
  getCurrentChatMessages (state) {
    return state.currentChatMessages
  }
}

const actions = {
  fetchUsersToAssign: async ({ commit, dispatch }, payload) => {
    try {
      const response = await payload.chatService.fetchUsersToAssign()
      commit('SET_USERS_TO_ASSIGN', response)
    } catch (e) {
      await dispatch(
        'snackbarStore/showMessage',
        'Wystąpił błąd, proszę spróbować ponownie',
        { root: true }
      )
      return Promise.reject(e)
    }
  },
  fetchLastMessages: async ({ commit, dispatch }, payload) => {
    try {
      const response = await payload.chatService.fetchInProgressChats(0, 2)
      commit('SET_LAST_MESSAGES', response)
    } catch (e) {
      await dispatch(
        'snackbarStore/showMessage',
        'Wystąpił błąd, proszę spróbować ponownie',
        { root: true }
      )
      return Promise.reject(e)
    }
  },
  fetchInProgressChats: async ({ commit, dispatch }, payload) => {
    const { start, end } = payload
    try {
      const response = await payload.chatService.fetchInProgressChats(start, end)
      commit('SET_IN_PROGRESS_CHATS', response)
    } catch (e) {
      await dispatch(
        'snackbarStore/showMessage',
        'Wystąpił błąd, proszę spróbować ponownie',
        { root: true }
      )
      return Promise.reject(e)
    }
  },
  fetchClosedChats: async ({ commit, dispatch }, payload) => {
    try {
      const response = await payload.chatService.fetchClosedChats(payload.start, payload.end)
      commit('SET_CLOSED_CHATS', response)

      return response
    } catch (e) {
      await dispatch(
        'snackbarStore/showMessage',
        'Wystąpił błąd, proszę spróbować ponownie',
        { root: true }
      )
      return Promise.reject(e)
    }
  },
  fetchUnreadAmount: async ({ commit, dispatch }, payload) => {
    try {
      const response = await payload.chatService.getUnreadChatsAmount()
      commit('SET_UNREAD_AMOUNT', response)

      return response
    } catch (e) {
      await dispatch(
        'snackbarStore/showMessage',
        'Wystąpił błąd, proszę spróbować ponownie',
        { root: true }
      )
      return Promise.reject(e)
    }
  },
  fetchCurrentChat: async ({ commit, dispatch }, payload) => {
    try {
      const response = await payload.chatService.fetchChat(payload.id)
      commit('SET_CURRENT_CHAT', response)

      return response
    } catch (e) {
      await dispatch(
        'snackbarStore/showMessage',
        'Wystąpił błąd, proszę spróbować ponownie',
        { root: true }
      )
      return Promise.reject(e)
    }
  },
  updateCurrentChat: async ({ commit, dispatch }, payload) => {
    try {
      const response = await payload.chatService.fetchChat(payload.id)
      commit('UPDATE_CURRENT_CHAT', response)

      return response
    } catch (e) {
      await dispatch(
        'snackbarStore/showMessage',
        'Wystąpił błąd, proszę spróbować ponownie',
        { root: true }
      )
      return Promise.reject(e)
    }
  },
  addNewChatByClient: async ({ commit, dispatch }, payload) => {
    try {
      await payload.chatService.postChatByClient(payload.chatData)
      commit('ADD_NEW_CHAT', payload.chatData)
    } catch (e) {
      await dispatch(
        'snackbarStore/showMessage',
        'Wystąpił błąd, proszę spróbować ponownie',
        { root: true }
      )
      return Promise.reject(e)
    }
  },
  addNewChatByAdmin: async ({ commit, dispatch }, payload) => {
    try {
      await payload.chatService.postChatByAdmin(payload.chatData, payload.userId)
      dispatch('snackbarStore/showMessage', 'Pomyślnie utworzono nowy chat!', { root: true })
      commit('ADD_NEW_CHAT', payload.chatData)
    } catch (e) {
      await dispatch(
        'snackbarStore/showMessage',
        'Wystąpił błąd, proszę spróbować ponownie',
        { root: true }
      )
      return Promise.reject(e)
    }
  },
  closeCurrentChat: async ({ commit, dispatch }, payload) => {
    try {
      await payload.chatService.closeChat(payload.id)
    } catch (e) {
      await dispatch(
        'snackbarStore/showMessage',
        'Wystąpił błąd, proszę spróbować ponownie',
        { root: true }
      )
      return Promise.reject(e)
    }
  },
  sendNewMessage: async ({ commit, dispatch }, payload) => {
    try {
      await payload.chatService.sendMessage(payload.formData, dispatch)
    } catch (e) {
      await dispatch(
        'snackbarStore/showMessage',
        'Wystąpił błąd, proszę spróbować ponownie',
        { root: true }
      )
      return Promise.reject(e)
    }
  },
  fetchChatVideo: async ({ commit, dispatch }, payload) => {
    try {
      commit('SET_LOADING_VIDEO', { fileId: payload.fileId })
      const response = await payload.chatService.downloadVideo(payload.chatId, payload.fileId, payload.fileName)
      commit('ASSIGN_VIDEO_TO_CHAT', { response: response, chatId: payload.chatId, fileId: payload.fileId, fileName: payload.fileName })

      return response
    } catch (e) {
      await dispatch(
        'snackbarStore/showMessage',
        'Wystąpił błąd, proszę spróbować ponownie',
        { root: true }
      )
      return Promise.reject(e)
    }
  },
  updateSendProgressBar: ({ commit, dispatch }, payload) => {
    commit('UPDATE_SEND_PROGRESS', { progress: payload.progress })
  }
}

const mutations = {
  SET_USERS_TO_ASSIGN: (state, data) => {
    state.usersToAssign = data
  },
  SET_IN_PROGRESS_CHATS: (state, data) => {
    state.inProgressChats = data
  },
  SET_LAST_MESSAGES: (state, data) => {
    state.lastMessages = data
  },
  SET_CLOSED_CHATS: (state, data) => {
    state.closedChats = data
  },
  SET_UNREAD_AMOUNT: (state, data) => {
    state.unreadAmount = data
  },
  SET_CURRENT_CHAT: (state, data) => {
    state.currentChatMessages = data
  },
  UPDATE_CURRENT_CHAT: (state, data) => {
    const stateLength = state.currentChatMessages.length
    if (stateLength !== data.length) {
      data.splice(0, stateLength)
      state.currentChatMessages = state.currentChatMessages.concat(data)
    }
  },
  ADD_NEW_CHAT: (state, newChatData) => {
    state.inProgressChats.chats.unshift(new ChatMessage({
      id: newChatData.id,
      status: 'IN_PROGRESS',
      read: false,
      topic: newChatData.topic,
      startDate: [
        new Date().getFullYear(),
        new Date().getMonth() + 1,
        new Date().getDay(),
        new Date().getHours(),
        new Date().getMinutes(),
        null
      ]
    }))
    state.inProgressChats.count += 1
  },
  SET_LOADING_VIDEO: (state, data) => {
    const chatIndex = state.currentChatMessages.findIndex(element => element?.attachment?.id === data?.fileId)
    state.currentChatMessages[chatIndex].attachment.isVideoLoading = true
  },
  ASSIGN_VIDEO_TO_CHAT: (state, data) => {
    const chatIndex = state.currentChatMessages.findIndex(element => element?.attachment?.id === data?.fileId)
    state.currentChatMessages[chatIndex].attachment.videoSrc = data.response
    state.currentChatMessages[chatIndex].attachment.isVideoLoading = false
  },
  UPDATE_SEND_PROGRESS: (state, data) => {
    state.uploadProgress = data.progress
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
