<template>
  <h3 data-test='nav'>{{ navigatorText }}</h3>
</template>

<script>
import { ref, onMounted, inject, computed, watch } from 'vue'
import { useRoute } from 'vue-router'

export default {
  setup () {
    const route = useRoute()
    const keycloak = inject(process.env.VUE_APP_KEYCLOAK_PROVIDE_VARIABLE)
    const routeObj = computed(() => route)

    const navigatorText = ref('')
    const userProfile = ref('')

    onMounted(async () => {
      userProfile.value = await keycloak.loadUserProfile()
      if (routeObj.value.name === 'Home') {
        navigatorText.value = 'Witaj ' + userProfile.value.firstName + ' ' + userProfile.value.lastName + '!'
      } else {
        navigatorText.value = routeObj.value.meta.routeName
      }
    })

    watch(routeObj.value, () => {
      if (routeObj.value.name === 'Home') {
        navigatorText.value = 'Witaj ' + userProfile.value.firstName + ' ' + userProfile.value.lastName + '!'
      } else {
        navigatorText.value = routeObj.value.meta.routeName
      }
    })

    return {
      navigatorText,
      route
    }
  }
}
</script>

<style>

</style>
