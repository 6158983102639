import { ShoppingCart } from '@/models/shoppingCart'

export class DividedShoppingCart {
  constructor (shoppingCart = {}) {
    this.quantityUpdated = shoppingCart.quantityUpdated
    this.priceUpdated = shoppingCart.priceUpdated
    this.orders = this.parseShoppingCarts(shoppingCart.orders)
  }

  parseShoppingCarts (shoppingCarts) {
    const shoppingCartsList = []
    if (shoppingCarts !== undefined) {
      for (const shoppingCart of shoppingCarts) {
        shoppingCartsList.push(new ShoppingCart({
          id: shoppingCart.id,
          name: shoppingCart.name,
          isChanged: true,
          master: shoppingCart.master,
          initDate: shoppingCart.initDate,
          lastModificationDate: shoppingCart.lastModificationDate,
          shopUserName: shoppingCart.shopUserName,
          shopUserDto: shoppingCart.shopUserDto,
          orderCommodities: shoppingCart.orderCommodities,
          totalAmount: shoppingCart.totalAmount,
          totalAmountWithoutDiscount: shoppingCart.totalAmountWithoutDiscount
        }))
      }
    } else {
      return shoppingCartsList
    }

    return shoppingCartsList
  }
}
