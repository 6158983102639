import { UnknownException } from '@/utils/exceptions'
import { ShoppingCart } from '@/models/shoppingCart'
import authContentTypeHeader from '@/services/auth_content_type_header'
import authHeader from '@/services/auth_header'
import { DividedShoppingCart } from '@/models/dividedShoppingCart'
import acceptHeader from '@/services/aceppt_header'
import instance from '@/services/api'
import { SplitPaymentModel } from '@/models/splitPaymentModel'

export class ShoppingCartService {
  async fetchBaskets () {
    try {
      const response = await instance.get('/order', {
        headers: authContentTypeHeader()
      })
      const basketList = []
      response.data.forEach(value => {
        basketList.push(new ShoppingCart(value))
      })
      return Promise.resolve(basketList)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async fetchBasketsForPreview () {
    try {
      const response = await instance.get('/order/all', {
        headers: authContentTypeHeader()
      })
      const basketList = []
      response.data.forEach(value => {
        basketList.push(new ShoppingCart(value))
      })
      return Promise.resolve(basketList)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async fetchBasket (orderId) {
    try {
      const response = await instance.get('/order/' + orderId, {
        headers: authContentTypeHeader()
      })
      return Promise.resolve(response.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async fetchContractorBaskets () {
    try {
      const response = await instance.get('/order/contractor', {
        headers: authContentTypeHeader()
      })
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async takeOverBasket (orderId) {
    try {
      const response = await instance.put('/order/basket/' + orderId + '/takeover', {}, {
        headers: authContentTypeHeader()
      })
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async changeQuantity (orderId, itemId, quantityData) {
    try {
      const response = await instance.put('/order/basket/quantity/' + orderId + '/item/' + itemId, quantityData, {
        headers: authContentTypeHeader()
      })
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async deleteBasket (data) {
    try {
      const response = await instance.delete('/order/basket/' + data.id, {
        headers: authContentTypeHeader()
      })
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async addBasket (basketName) {
    try {
      const response = await instance.post('/order/basket/' + basketName, {}, {
        headers: authContentTypeHeader()
      })
      return Promise.resolve(new ShoppingCart(response.data))
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async renameBasket (data) {
    const { id, name } = data.shoppingCartInEditNameMode

    try {
      const response = await instance.put(`/order/basket/${id}/name/${name}`, data?.shoppingCartInEditNameMode, {
        headers: authContentTypeHeader()
      })
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async cloneBasket (data) {
    const { id } = data

    try {
      const response = await instance.put(`/order/basket/${id}/clone`, {}, {
        headers: acceptHeader()
      })
      return Promise.resolve(new ShoppingCart(response.data))
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async divideBasket (basketId, orderType) {
    try {
      const response = await instance.post('/order/basket/' + basketId + '/divide', orderType, {
        headers: authContentTypeHeader()
      })
      return Promise.resolve(new DividedShoppingCart(response.data))
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async splitPayment (basketId, orderType) {
    try {
      const response = await instance.post('/order/basket/' + basketId + '/splitpayment', orderType, {
        headers: authContentTypeHeader()
      })
      return Promise.resolve(new SplitPaymentModel(response.data))
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async getBaskets () {
    try {
      const response = await instance.get('/basket', {
        headers: authHeader()
      })
      const basketList = []
      for (const rawBasket of response.data) {
        basketList.push(new ShoppingCart(rawBasket))
      }

      return basketList
    } catch (e) {
      throw new UnknownException()
    }
  }

  async updateBaskets (token) {
    try {
      const response = await instance.post('/basket', {
        headers: {
          'Content-Type': 'application/json',
          accept: 'application/json',
          Authorization: 'Bearer ' + token
        }
      })

      return response
    } catch (e) {
      console.log(e)
    }
  }

  async addProductToBasket (orderId, commodityId, quantityMap) {
    try {
      const response = await instance.post('/order/' + orderId + '/commodity/' + commodityId, quantityMap, {
        headers: authContentTypeHeader()
      })
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async deleteProductFromBasket (productDetails, currentShoppingCartId) {
    try {
      const response = await instance.delete(`/order/${currentShoppingCartId}/item/${productDetails.id}`, {
        headers: authHeader()
      })
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async moveCommodityFromBasketTo (data) {
    try {
      const response = await instance.put('/order', data, {
        headers: authContentTypeHeader()
      })
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async fetchContractor () {
    try {
      const response = await instance.get('/contractors', {
        headers: authHeader()
      })
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}
