<template>
  <div v-if="!isLoading" class="search-input-wrapper">
    <Form @submit="downloadProductsFromApi()">
      <div class="p-sm-12 name-section p-d-flex p-jc-center">
        <div class="p-d-flex p-jc-center input-form-wrapper">
          <LabeledInput
            class="p-p-0"
            name="title_name_input"
            type="text"
            rules=""
            v-model:modelValue="textNew"
            @enter="downloadProductsFromApi()"
            placeholder="Wyszukaj produkt"
          />
          <Button v-if="selectedCategory.id !== currentCategories[0].id || textNew !== ''" icon="pi pi-times" @click="selectedCategory = currentCategories[0]; textNew = ''; downloadProductsFromApi()"></Button>
        </div>
        <Dropdown
          v-model="selectedCategory"
          :options="currentCategories"
          placeholder="Wybierz kategorię"
          optionLabel="label"
          filterPlaceholder="Wyszukaj"
          aria-labelledby="single"
          class="input-dropdown-wrapper"
        />
        <div class="search-icon-button">
          <Button type="submit" icon="pi pi-search" data-test="searchProduct" :loading="isLoadingButton"/>
        </div>
      </div>
    </Form>
  </div>
</template>

<script>
import Button from 'primevue/button'
import LabeledInput from '@/components/LabeledInput'
import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { Form } from 'vee-validate'
import { setParams } from '@/utils/utils'
import Dropdown from 'primevue/dropdown'

export default {
  name: 'SearchProductInput',
  components: {
    LabeledInput,
    Dropdown,
    Button,
    Form
  },
  setup (props) {
    const store = useStore()
    const productsService = store.getters['serviceStore/getProductsService']
    const currentCategories = computed(() => store.getters['productStore/getCurrentCategories'])
    const selectedCategory = ref()
    const textNew = computed({
      get () {
        return store.getters['productStore/getCurrentSearchText']
      },
      async set (data) {
        await store.dispatch('productStore/setCurrentSearchText', { text: data })
      }
    })
    const paginationValue = computed({
      get () {
        return store.getters['productStore/getCurrentPagination']
      },
      async set (value) {
        await store.dispatch('productStore/setPagination', value)
      }
    })
    const currentPath = computed({
      get () {
        return store.getters['productStore/getCurrentPathParam']
      },
      async set (data) {
        await store.dispatch('productStore/setPathParam', { pathParam: data.pathParam })
      }
    })
    const currentPathId = computed({
      get () {
        return store.getters['productStore/getCurrentPathId']
      },
      async set (data) {
        await store.dispatch('productStore/setPathId', { pathId: data.pathId })
      }
    })
    const selectionKeys = computed({
      get () {
        return store.getters['productStore/getSelectedTreeKeys']
      },
      async set (data) {
        await store.dispatch('productStore/setSelectedTreeKeys', data)
      }
    })
    const isLoadingProducts = computed({
      get () { return store.getters['productStore/getIsLoadingProducts'] },
      async set (value) {
        await store.dispatch('productStore/setIsLoadingProducts', { isLoadingProducts: value })
      }
    })

    const isLoadingButton = ref(false)
    const isLoading = ref(true)

    const downloadProductsFromApi = async () => {
      try {
        isLoadingButton.value = true
        isLoadingProducts.value = true
        await store.dispatch('productStore/resetFilters', { productsService })
        currentPath.value = { pathParam: selectedCategory.value.path }
        currentPathId.value = { pathId: selectedCategory.value.id }
        paginationValue.value = { start: 0, end: paginationValue.value.rows, rows: paginationValue.value.rows }
        setParams(0, paginationValue.value.rows, paginationValue.value.rows, selectedCategory.value.path, selectedCategory.value.id, textNew.value)
        await store.dispatch('productStore/fetchProducts', { productsService: productsService })
        await store.dispatch('productStore/fetchCategoryCharacteristics', { productsService: productsService, pathPhrase: encodeURI(selectedCategory.value.path) })
        selectionKeys.value = { [selectedCategory.value.id]: true }
        isLoadingButton.value = false
        isLoadingProducts.value = false
      } catch (e) {
        isLoadingButton.value = false
        isLoadingProducts.value = false
      }
    }

    onMounted(async () => {
      isLoading.value = true
      await store.dispatch('productStore/fetchCategories', { productsService: productsService })
      selectedCategory.value = currentCategories.value[0]
      isLoading.value = false
    })

    return { textNew, isLoadingButton, downloadProductsFromApi, isLoading, currentCategories, selectedCategory }
  }
}
</script>

<style scoped lang="scss">
::v-deep(.p-dropdown){
  border-radius: 0;
  border: 1px solid #B72937;

  @media(max-width: 768px) {
    min-width: 100px;
  }
}
.search-input-wrapper{
  .search-icon-button{
    width: 100px;

    @media(max-width: 768px) {
      width: auto;
    }
  }

  .input-form-wrapper {
    width: intrinsic;
    width: -webkit-fill-available;
    width: -moz-available;

    .p-button.p-component {
      background-color: white;
      border: 1px solid #B72937;
      border-left: none;
      border-right: none;
      color: black;

      &:hover {
        color: #B72937;
      }
    }
  }

  ::v-deep(input){
    border-right: none;
  }
}
.productsWrapper {
  flex-direction: row;

  #searchSection{
    scroll-margin: 170px;
  }

  h1 {
    font-weight: 500;
    margin-bottom: 45px;
  }
  .category-tree{
    min-width: 275px;
    max-width: 350px;
    padding: 193px 15px 0 15px;
  }
}
::v-deep(.p-button.p-button-icon-only ) {
  width: 50px;
  border-radius: 0;
  height: 100%;

  .p-button-icon{
    font-size: 1rem;
  }
}
::v-deep(.field input) {
  margin: 0;
  width: 100%;
  padding: 0 4px;
  height: 100%;
}
</style>
