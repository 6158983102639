import { Article } from '@/models/articleModel'
import { ArticleList } from '@/models/articleList'
import instance from '@/services/api'
import authHeader from '@/services/auth_header'
import acceptHeader from '@/services/aceppt_header'

export class ArticleService {
  async addImage (articleData, articleId) {
    try {
      const response = await instance.put(`/article/image/${articleId}`, articleData, { headers: authHeader() })
      return Promise.resolve(response.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async addAttachment (articleData, articleId) {
    try {
      const response = await instance.put(`/article/attachment/${articleId}`, articleData, { headers: authHeader() })
      return Promise.resolve(response.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async deleteAttachment (articleId, attachmentId) {
    try {
      const response = await instance.delete(`/article/${articleId}/attachment?file_id=${attachmentId}`, { headers: acceptHeader() })
      return Promise.resolve(response.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async fetchArticles (start, end) {
    try {
      const response = await instance.get('/article/?from=' + start + '&to=' + end)
      return Promise.resolve(new ArticleList(response.data))
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async fetchAdminArticles (start, end) {
    try {
      const response = await instance.get('/article/admin?from=' + start + '&to=' + end)
      return Promise.resolve(new ArticleList(response.data))
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async updateArticle (articleData) {
    try {
      const response = await instance.put('/article', articleData, { headers: authHeader() })
      return Promise.resolve(response.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async postArticle (articleData) {
    try {
      const response = await instance.post('/article', articleData, { headers: authHeader() })
      return Promise.resolve(response.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async fetchArticle (id) {
    try {
      const response = await instance.get('/article/' + id, { headers: authHeader() })
      return Promise.resolve(new Article(response.data))
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async fetchArticleImage (id) {
    try {
      const response = await instance.get('/article/' + id + '/image', { headers: acceptHeader() })
      return Promise.resolve(response.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async fetchArticleAttachment (articleId, fileId, fileName) {
    try {
      const response = await instance.get('/article/' + articleId + '/attachment?file_id=' + fileId, {
        responseType: 'blob',
        headers: acceptHeader()
      })
      const fileURL = window.URL.createObjectURL(new Blob([response.data]))
      const fileLink = document.createElement('a')

      fileLink.href = fileURL
      fileLink.setAttribute('download', fileName)
      document.body.appendChild(fileLink)

      fileLink.click()
      return URL.createObjectURL(response.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async deleteArticle (id) {
    try {
      const response = await instance.delete('/article/' + id, { headers: authHeader() })
      return Promise.resolve(response.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}
