import { SingleProduct } from '@/models/singleProduct'

export class SingleOrderProduct {
  constructor (product) {
    this.id = product.id
    this.quantity = product.quantity
    this.unitPrice = product.unitPrice
    this.baseUnitPerIndivisible = product.baseUnitPerIndivisible
    this.indivisibleQuantity = product.indivisibleQuantity
    this.commodity = new SingleProduct(product.commodity)
  }
}
