import authHeader from '@/services/auth_header'
import { MessagesList } from '@/models/messagesList'
import { SingleChatMessage } from '@/models/singleChatMessage'
import { ChatUser } from '@/models/chatUser'
import authContentTypeHeader from '@/services/auth_content_type_header'
import acceptHeader from '@/services/aceppt_header'
import instance from '@/services/api'

export class ChatService {
  async fetchUsersToAssign () {
    try {
      const response = await instance.get('/users/customers', { headers: authHeader() })
      const chatUsers = []
      for (const user of response.data) {
        chatUsers.push(new ChatUser(user))
      }
      return Promise.resolve(chatUsers)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async fetchInProgressChats (start, end) {
    try {
      const response = await instance.get('/chat/show/in-progress?from=' + start + '&to=' + end, { headers: authHeader() })
      return Promise.resolve(new MessagesList(response.data))
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async getUnreadChatsAmount () {
    try {
      const response = await instance.get('/chat/unread', { headers: authHeader() })
      return Promise.resolve(response.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async fetchClosedChats (start, end) {
    try {
      const response = await instance.get('/chat/show/closed?from=' + start + '&to=' + end, { headers: authHeader() })
      return Promise.resolve(new MessagesList(response.data))
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async fetchChat (id) {
    try {
      const response = await instance.get('/chat/' + id, { headers: authHeader() })
      const chatMessages = []
      for (const message of response.data) {
        chatMessages.push(new SingleChatMessage(message))
      }
      return Promise.resolve(chatMessages)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async postChatByUser (chatData) {
    try {
      await instance.post('/chat', chatData, { headers: authHeader() })
      return Promise.resolve(true)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async postChatByAdmin (chatData, userId) {
    try {
      await instance.post('/chat/new-chat/admin?user_id=' + userId, chatData, { headers: authContentTypeHeader() })
      return Promise.resolve(true)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async postChatByClient (chatData) {
    try {
      await instance.post('/chat/new-chat/user', chatData, { headers: authContentTypeHeader() })
      return Promise.resolve(true)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async closeChat (id) {
    try {
      const response = await instance.put('/chat/' + id, {}, { headers: acceptHeader() })
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async sendMessage (message, dispatch) {
    try {
      const response = instance.post('/chat/new-message', message, {
        headers: authHeader(),
        onUploadProgress: progressEvent => {
          dispatch('chatStore/updateSendProgressBar', { progress: Math.round((progressEvent.loaded / progressEvent.total) * 100) }, { root: true })
        }
      })
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async downloadChatFile (chatId, fileId, fileName) {
    try {
      const response = await instance.get('/chat/' + chatId + '/attachment?file_id=' + fileId, { responseType: 'blob', headers: acceptHeader() })
      const fileURL = window.URL.createObjectURL(new Blob([response.data]))
      const fileLink = document.createElement('a')

      fileLink.href = fileURL
      fileLink.setAttribute('download', fileName)
      document.body.appendChild(fileLink)

      fileLink.click()
      return Promise.resolve(URL.createObjectURL(response.data))
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async downloadVideo (chatId, fileId, fileName) {
    try {
      const response = await instance.get('/chat/' + chatId + '/attachment?file_id=' + fileId, { responseType: 'blob', headers: acceptHeader() })
      const fileURL = URL.createObjectURL(new Blob([response.data]))

      return Promise.resolve(fileURL)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async downloadChatImage (chatId, fileId, fileName) {
    try {
      const response = await instance.get('/chat/' + chatId + '/attachment?file_id=' + fileId, { responseType: 'blob', headers: acceptHeader() })
      const fileURL = window.URL.createObjectURL(new Blob([response.data]))
      const fileLink = document.createElement('a')

      fileLink.href = fileURL
      fileLink.setAttribute('download', fileName)
      document.body.appendChild(fileLink)

      fileLink.click()
      return Promise.resolve(URL.createObjectURL(response.data))
    } catch (error) {
      return Promise.reject(error)
    }
  }
}
