export class PrizeModel {
  constructor (element) {
    this.id = element.id
    this.description = element.description
    this.codedImage = element.codedImage
    this.displayCounter = element.displayCounter
    this.status = element.status
    this.imageDto = new ImageModel(element.imageDto)
    this.name = element.name
    this.prizeCategoryDto = new PrizeCategory(element.prizeCategoryDto)
    this.requiredPoints = element.requiredPoints
  }
}

export class ImageModel {
  constructor (image = {}) {
    this.id = image.id
    this.originalPath = image.originalPath
    this.smallPath = image.smallPath
  }
}

export class PrizeCategory {
  constructor (category = {}) {
    this.id = category.id
    this.categoryName = category.categoryName
  }
}
