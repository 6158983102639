import { ref } from 'vue'
import router from '@/router'

export const itemsUser = ref([
  {
    key: 1,
    label: 'Katalog produktów',
    icon: 'pi product-icon',
    to: { name: 'Products' }
  },
  {
    label: 'Moje zamówienia',
    icon: 'pi pi-shopping-bag',
    to: { name: 'Orders' }
  },
  {
    label: 'Moje faktury',
    icon: 'pi pi-file',
    to: { name: 'InvoicesManagement' }
  },
  {
    key: 2,
    label: 'Rozkrój płyt',
    name: 'cut-icon',
    icon: 'pi cut-icon',
    to: { name: 'Cutting boards' }
  },
  {
    label: 'Aktualności',
    name: 'cut-icon',
    icon: 'pi newsletter-icon',
    to: { name: 'Articles' }
  },
  {
    label: 'Program lojalnościowy',
    icon: 'pi handshake-icon',
    command: () => {
      router.push({ name: 'Loyalty program' })
    },
    items: [
      {
        label: 'Zamówione nagrody',
        to: { name: 'Loyalty program orders' }
      }
    ]
  },
  {
    label: 'Do pobrania',
    icon: 'pi document-icon',
    to: { name: 'Downloads' }
  },
  {
    label: 'FAQ',
    icon: 'pi help-icon',
    to: { name: 'FAQ' }
  },
  {
    label: 'Newsletter',
    icon: 'pi newsletter-icon',
    to: { name: 'Newsletter' }
  },
  {
    label: 'Chat',
    icon: 'pi chat-icon',
    to: { name: 'Chat' }
  },
  {
    label: 'Kontakt',
    icon: 'pi pi-phone',
    command: () => {
      window.open('https://www.abler.pl/kontakt', '__blank', 'noopener noreferrer')
    }
  }
])
