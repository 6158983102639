import SecureLS from 'secure-ls'
import { UnitModel } from '@/models/unitModel'
import { filterCharacteristicsTypeEnum } from '@/enums/filterCharacteristicsTypeEnum'
import { FetchProductsQueryModel } from '@/models/fetchProductsQueryModel'
import router from '@/router'
import Decimal from 'decimal.js'
const ls = new SecureLS({ isCompression: false })
const product = ls.get('vuex')
const state = product.productStore?.productName
  ? {
      currentProducts: [],
      similarProducts: [],
      lastWatchedProducts: [],
      featuredProducts: [],
      isLoadingProducts: true,
      productDetails: null,
      productPriceConverter: null,
      converterArray: null,
      totalProductValue: null,
      totalProductBaseValue: null,
      mainImage: [],
      productThumbnailsListId: null,
      productDescription: '',
      commodityCategories: [],
      selectedTreeKeys: {},
      filterCharacteristics: [],
      mainCategories: [],
      searchText: '',
      pathParam: '',
      sortingParam: {
        label: 'Sortowanie domyślne',
        value: { name: 'default', order: 'desc' }
      },
      pathId: null,
      expandedIds: [],
      pagination: {
        start: 0,
        end: 13,
        rows: 14
      },
      arrayDownloadedMainImages: [],
      productName: product.productStore.productName,
      isAfterDiscount: false
    }
  : {
      currentProducts: [],
      similarProducts: [],
      lastWatchedProducts: [],
      featuredProducts: [],
      isLoadingProducts: true,
      productDetails: null,
      productPriceConverter: null,
      converterArray: null,
      totalProductValue: null,
      totalProductBaseValue: null,
      mainImage: [],
      productThumbnailsListId: null,
      productDescription: '',
      commodityCategories: [],
      selectedTreeKeys: {},
      filterCharacteristics: [],
      mainCategories: [],
      searchText: '',
      pathParam: '',
      sortingParam: {
        label: 'Sortowanie domyślne',
        value: { name: 'default', order: 'desc' }
      },
      pathId: null,
      expandedIds: [],
      pagination: {
        start: 0,
        end: 13,
        rows: 14
      },
      arrayDownloadedMainImages: [],
      productName: '',
      isAfterDiscount: false
    }

const getters = {
  getIsLoadingProducts (state) {
    return state.isLoadingProducts
  },
  getCurrentPathParam (state) {
    return state.pathParam
  },
  getCurrentSorting (state) {
    return state.sortingParam
  },
  getSelectedTreeKeys (state) {
    return state.selectedTreeKeys
  },
  getCurrentSearchText (state) {
    return state.searchText
  },
  getCurrentPathId (state) {
    return state.pathId
  },
  getCurrentCategories (state) {
    return state.commodityCategories
  },
  getMainCategories (state) {
    return state.mainCategories
  },
  getCurrentPagination (state) {
    return state.pagination
  },
  getProductDescription (state) {
    return state.productDescription
  },
  getCurrentProducts (state) {
    return state.currentProducts
  },
  getFilterCharacteristics (state) {
    return state.filterCharacteristics
  },
  getSelectedFilterCharacteristics (state) {
    const filteredCharacteristics = state.filterCharacteristics.filter(characteristic => {
      if (characteristic.type === filterCharacteristicsTypeEnum.DOUBLE_SET || characteristic.type === filterCharacteristicsTypeEnum.BIGDECIMAL_SET) {
        if (characteristic.selected !== undefined) {
          if (characteristic.min !== characteristic.selected[0] || characteristic.max !== characteristic.selected[1]) {
            return characteristic.selected
          }
        }
        return null
      }
      return characteristic.selected
    })

    const selectedObj = {}
    filteredCharacteristics.map((obj) => {
      if (obj.selected.name) {
        return Object.assign(selectedObj, { [obj.name]: obj.selected.value })
      } else {
        return Object.assign(selectedObj, { [obj.name]: obj.selected })
      }
    })
    return selectedObj
  },
  getQueryItems (state) {
    const filteredCharacteristics = state.filterCharacteristics.filter(characteristic => {
      if (characteristic.type === filterCharacteristicsTypeEnum.DOUBLE_SET || characteristic.type === filterCharacteristicsTypeEnum.BIGDECIMAL_SET) {
        if (characteristic.selected !== undefined) {
          if (characteristic.min !== characteristic.selected[0] || characteristic.max !== characteristic.selected[1]) {
            return characteristic.selected
          }
        }
        return null
      }
      return characteristic.selected
    })
    const selectedObj = {}
    filteredCharacteristics.map((obj) => {
      if (obj.selected.name) {
        return Object.assign(selectedObj, { [obj.name]: obj.selected.value })
      } else {
        return Object.assign(selectedObj, { [obj.name]: obj.selected })
      }
    })
    const queryArray = new FetchProductsQueryModel(selectedObj)
    queryArray.from = state.pagination.start
    queryArray.to = state.pagination.end
    queryArray.phrase = state.searchText
    queryArray.pathPhrase = state.pathParam
    queryArray.commoditySort = state.sortingParam.value

    return queryArray
  },
  getFilterCharacteristicsShort (state) {
    return state.filterCharacteristics.slice(0, 5)
  },
  getFilterCharacteristicByIndex: (state) => (index) => {
    return state.filterCharacteristics[index].selected
  },
  getFilterCharacteristicMinByIndex: (state) => (index) => {
    return state.filterCharacteristics[index].selected[0]
  },
  getFilterCharacteristicMaxByIndex: (state) => (index) => {
    return state.filterCharacteristics[index].selected[1]
  },
  getIsFilterChanged: (state) => (index) => {
    if (state.filterCharacteristics[index].selected[0] === state.filterCharacteristics[index].min && state.filterCharacteristics[index].selected[1] === state.filterCharacteristics[index].max) {
      return false
    } else {
      return true
    }
  },
  getSimilarProducts (state) {
    return state.similarProducts
  },
  getLastWatchedProducts (state) {
    return state.lastWatchedProducts
  },
  getFeaturedProducts (state) {
    return state.featuredProducts
  },
  getCommodityCharacteristics (state) {
    return state.productDetails?.commodityCharacteristicDto
  },
  getName (state) {
    return state?.productName
  },
  getCode (state) {
    return state.productDetails?.code
  },
  getBaseUnit (state) {
    return state?.productDetails?.baseUnit
  },
  getProductDetails (state) {
    return state.productDetails
  },
  getDataPriceCalculation (state) {
    const dataPriceCalculation = {
      baseUnit: state?.productDetails?.baseUnit,
      vat: state?.productDetails?.vat,
      net: state?.productDetails?.net,
      discountNet: state?.productDetails?.discountNet
    }
    return dataPriceCalculation
  },
  getTotalProductValue (state) {
    return state.totalProductValue
  },
  getTotalProductBaseValue (state) {
    return state.totalProductBaseValue
  },
  getConverterMap (state) {
    return state.converterMap
  },
  getConverterArray (state) {
    return state.converterArray
  },
  getProductPriceConverter (state) {
    return state.productPriceConverter
  },
  getThumbnailsListId (state) {
    return state?.productThumbnailsListId
  },
  getIsAfterDiscount (state) {
    return state.isAfterDiscount
  }
}

const actions = {
  fetchCategoryCharacteristics: async ({ commit, dispatch }, payload) => {
    try {
      const characteristicsFromApi = await payload.productsService.getCategoryCharacteristics(payload.pathPhrase)
      await commit('SET_FILTER_CHARACTERISTICS', characteristicsFromApi)
    } catch (error) {
      await dispatch('snackbarStore/showMessage', 'Wystąpił błąd, proszę spróbować ponownie', { root: true })
      return Promise.reject(error)
    }
  },
  fetchProducts: async ({ getters, commit, dispatch }, payload) => {
    try {
      const query = getters.getQueryItems
      const currentProductsFromApi = await payload.productsService.getCommodities(query)
      const thumbnails = await payload.productsService.getThumbnails(currentProductsFromApi)
      for (let i = 0; i < thumbnails.length; i++) {
        if (thumbnails[i].status === 'fulfilled') {
          currentProductsFromApi.commodityDtoList[i].thumbnail = URL.createObjectURL(thumbnails[i].value)
        }
      }
      await commit('GET_CURRENT_PRODUCTS', currentProductsFromApi)
    } catch (error) {
      await dispatch('snackbarStore/showMessage', 'Wystąpił błąd, proszę spróbować ponownie', { root: true })
      return Promise.reject(error)
    }
  },
  setIsLoadingProducts: ({ commit }, payload) => {
    commit('SET_IS_LOADING_PRODUCTS', payload)
  },
  setPathParam: ({ commit }, payload) => {
    commit('SET_PATH_PARAM', payload)
  },
  setCurrentSearchText: ({ commit }, payload) => {
    commit('SET_SEARCH_TEXT', payload)
  },
  setPathId: ({ commit }, payload) => {
    commit('SET_PATH_ID', payload)
  },
  setPagination: async ({ commit, dispatch }, payload) => {
    commit('SET_PAGINATION', payload)
  },
  setSelectedFilterValue: ({ commit, dispatch }, payload) => {
    commit('SET_SELECTED_FILTER', payload)
  },
  setSelectedTreeKeys: ({ commit, dispatch }, payload) => {
    commit('SET_SELECTED_TREE_KEYS', payload)
  },
  setFilterParams: ({ commit, dispatch }, payload) => {
    commit('SET_FILTER_PARAMS', payload)
  },
  setSorting: ({ commit, dispatch }, payload) => {
    commit('SET_SORTING_PARAM', payload)
  },
  resetFilters: async ({ actions, commit, dispatch }, payload) => {
    dispatch('setSorting', {
      label: 'Dostępność - od najwyższej',
      value: { name: 'availability', order: 'desc' }
    })
    commit('RESET_FILTERS')
  },
  fetchProductDetails: async ({ commit, dispatch }, payload) => {
    try {
      const productDetails = await payload.productsService.getProductById(parseInt(payload.id))
      commit('GET_PRODUCT_DETAILS', productDetails)
      const productConverterData = await payload.productsService.getProductConverter(payload.id)
      commit('SET_PRODUCT_CONVERTER_DATA', { productDetails: productDetails, productConverterData: productConverterData })
      commit('CALCULATE_PRODUCT_UNITS', { converterData: { unitType: productDetails.baseUnit, baseCalcUnit: 1, unitAmount: 1 }, eventValue: 1 })
      const parser = new DOMParser()
      const doc = parser.parseFromString(productDetails.description, 'text/html')
      commit('GET_PRODUCT_DESCRIPTION', { doc: doc.body.textContent, name: productDetails.name })
    } catch (error) {
      await dispatch('snackbarStore/showMessage', 'Wystąpił błąd, proszę spróbować ponownie', { root: true })
      return Promise.reject(error)
    }
  },
  calculateUnits: async ({ commit, dispatch }, payload) => {
    await commit('CALCULATE_PRODUCT_UNITS', payload)
    await commit('CALCULATE_PRODUCT_PRICE')
  },
  fetchCategories: async ({ commit, dispatch }, payload) => {
    try {
      const currentProductsFromApi = await payload.productsService.getCommodityCategory()
      commit('GET_CATEGORIES', currentProductsFromApi)
    } catch (error) {
      await dispatch('snackbarStore/showMessage', 'Wystąpił błąd, proszę spróbować ponownie', { root: true })
      return Promise.reject(error)
    }
  },
  fetchThumbnailsListId: async ({ commit, dispatch }, payload) => {
    try {
      const productThumbnailsListId = await payload.productsService.getThumbnailsListId(payload.path)
      commit('GET_THUMBNAILS_LIST_ID', productThumbnailsListId)
    } catch (error) {
      await dispatch('snackbarStore/showMessage', 'Wystąpił błąd, proszę spróbować ponownie', { root: true })
      return Promise.reject(error)
    }
  },
  resetState: async ({ commit }) => {
    commit('RESET_STATE')
  },
  fetchAllThumbnails: async ({ commit, dispatch }, payload) => {
    try {
      const responseThumbnailSingle = await payload.productsService.getProductThumbnails(
        state.productThumbnailsListId || payload.dataThumbnailsListId.value
      )
      commit('GET_THUMBNAIL_SINGLE', responseThumbnailSingle)
    } catch (error) {
      await dispatch('snackbarStore/showMessage', 'Wystąpił błąd, proszę spróbować ponownie', { root: true })
      return Promise.reject(error)
    }
  },
  fetchMainImage: async ({ commit, dispatch }, payload) => {
    const element = state.arrayDownloadedMainImages.find((element) => element.id === payload.id)
    if (element === undefined) {
      try {
        const mainImage = await payload.productsService.getMainImage(payload.id)
        commit('SET_MAIN_IMAGE', { mainImage, id: payload.id })
      } catch (error) {
        await dispatch('snackbarStore/showMessage', 'Wystąpił błąd, proszę spróbować ponownie', { root: true })
        return Promise.reject(error)
      }
    } else {
      commit('SET_MAIN_IMAGE', { mainImage: element.mainImage, id: element.id })
    }
  },
  fetchSimilarProducts: async ({ commit, dispatch }, payload) => {
    try {
      const similarItemsFromApi = await payload.productsService.getSimilarProducts()
      const thumbnails = await payload.productsService.getFeaturedThumbnails(similarItemsFromApi)

      for (let i = 0; i < thumbnails.length; i++) {
        if (thumbnails[i].status === 'fulfilled') {
          similarItemsFromApi[i].thumbnail = URL.createObjectURL(thumbnails[i].value)
        }
      }
      commit('GET_SIMILAR_PRODUCTS', similarItemsFromApi)
    } catch (error) {
      await dispatch('snackbarStore/showMessage', 'Wystąpił błąd, proszę spróbować ponownie', { root: true })
      return Promise.reject(error)
    }
  },
  fetchLastWatchedProducts: async ({ commit, dispatch }, payload) => {
    try {
      const itemsFromApi = await payload.productsService.getLastWatchedProducts()
      const thumbnails = await payload.productsService.getFeaturedThumbnails(itemsFromApi)

      for (let i = 0; i < thumbnails.length; i++) {
        if (thumbnails[i].status === 'fulfilled') {
          itemsFromApi[i].thumbnail = URL.createObjectURL(thumbnails[i].value)
        }
      }
      commit('SET_LAST_WATCHED_PRODUCTS', itemsFromApi)
    } catch (error) {
      await dispatch('snackbarStore/showMessage', 'Wystąpił błąd, proszę spróbować ponownie', { root: true })
      return Promise.reject(error)
    }
  },
  fetchFeaturedProducts: async ({ commit, dispatch }, payload) => {
    try {
      const featuredItemsFromApi = await payload.productsService.getFeaturedProducts()
      const thumbnails = await payload.productsService.getFeaturedThumbnails(featuredItemsFromApi)

      for (let i = 0; i < thumbnails.length; i++) {
        if (thumbnails[i].status === 'fulfilled') {
          featuredItemsFromApi[i].thumbnail = URL.createObjectURL(thumbnails[i].value)
        }
      }
      commit('SET_FEATURED_PRODUCTS', featuredItemsFromApi)
    } catch (error) {
      await dispatch('snackbarStore/showMessage', 'Wystąpił błąd, proszę spróbować ponownie', { root: true })
      return Promise.reject(error)
    }
  },
  changeStateOfDiscountStatus: async ({ commit }, payload) => {
    commit('CHANGE_DISCOUNT_STATUS', payload)
  }
}

const mutations = {
  SET_IS_LOADING_PRODUCTS: (state, data) => {
    state.isLoadingProducts = data.isLoadingProducts
  },
  SET_PATH_PARAM: (state, data) => {
    state.pathParam = data.pathParam
    state.expandedIds = data.expandedIds
  },
  SET_SEARCH_TEXT: (state, data) => {
    state.searchText = data.text
  },
  SET_PATH_ID: (state, data) => {
    state.pathId = data.pathId
  },
  SET_PAGINATION: (state, data) => {
    state.pagination = data
  },
  SET_SELECTED_FILTER: (state, data) => {
    state.filterCharacteristics[data.index].selected = data.value
  },
  SET_SELECTED_TREE_KEYS: (state, data) => {
    state.selectedTreeKeys = data
  },
  RESET_FILTERS: (state) => {
    for (let i = 0; i < state.filterCharacteristics.length; i++) {
      if (state.filterCharacteristics[i]?.selected?.length > 1 && state.filterCharacteristics[i].selected !== undefined) {
        state.filterCharacteristics[i].selected = [state.filterCharacteristics[i].min,
          state.filterCharacteristics[i].max]
      } else {
        state.filterCharacteristics[i].selected = undefined
      }
    }
    const sortValue = 'AVAILABILITY_DESC'
    router.replace({ query: { from: state.pagination.from, phrase: state.searchText, pathPhrase: state.pathQuery, pathId: state.pathId, to: state.pagination.to, rows: state.pagination.rows, expanded: state.expandedIds, sort: sortValue } })
  },
  GET_CATEGORIES: (state, categories) => {
    state.commodityCategories = categories
    const mainCategories = []
    categories.forEach(node => mainCategories.push(node.id))
    state.mainCategories = mainCategories
  },
  SET_FILTER_CHARACTERISTICS: (state, characteristics) => {
    const availableFilter = characteristics.filter(e => e.name === 'isAvailability')
    if (availableFilter.length > 0) {
      characteristics.unshift(availableFilter[0])
      characteristics.splice(characteristics.length - 1, 1)
    }
    state.filterCharacteristics = characteristics
  },
  SET_FILTER_PARAMS: (state, data) => {
    for (const [key, value] of Object.entries(data)) {
      if (value !== undefined) {
        const index = state.filterCharacteristics.findIndex(element => {
          return element.name === key
        })
        if (index !== -1) {
          state.filterCharacteristics[index].selected = value
        }
      }
    }
  },
  SET_SORTING_PARAM: (state, data) => {
    state.sortingParam = data
  },
  GET_CURRENT_PRODUCTS: (state, productsFromApi) => {
    state.currentProducts = productsFromApi
  },
  RESET_STATE: () => {
    state.productThumbnailsListId = null
    state.productDescription = ''
  },
  GET_PRODUCT_DETAILS: (state, productDetails) => {
    state.productDetails = productDetails
    state.productDetails.consignmentStorehouse = productDetails.consignmentStorehouse
  },
  SET_PRODUCT_CONVERTER_DATA: (state, payload) => {
    state.productPriceConverter = payload.productConverterData
    const baseUnit = state.productDetails.baseUnit
    const unitArray = []
    payload.productConverterData.forEach(convertObject => {
      if (convertObject.baseUnit === baseUnit) {
        if (!unitArray.some(e => e.unitType === convertObject.baseUnit)) {
          unitArray.unshift(new UnitModel({ unitType: baseUnit, baseCalcUnit: 1, unitAmount: 1 }))
        }
        if (!unitArray.some(e => e.unitType === convertObject.computedUnit)) {
          unitArray.push(new UnitModel({ unitType: convertObject.computedUnit, baseCalcUnit: new Decimal(1).dividedBy(convertObject.countedFactor).toNumber(), unitAmount: new Decimal(1).dividedBy(convertObject.countedFactor).toNumber() }))
        }
      } else if (convertObject.computedUnit === baseUnit) {
        if (!unitArray.some(e => e.unitType === convertObject.baseUnit)) {
          unitArray.push(new UnitModel({ unitType: convertObject.baseUnit, baseCalcUnit: convertObject.countedFactor, unitAmount: convertObject.countedFactor }))
        }
        if (!unitArray.some(e => e.unitType === convertObject.computedUnit)) {
          unitArray.unshift(new UnitModel({ unitType: baseUnit, baseCalcUnit: 1, unitAmount: 1 }))
        }
      }
    })
    state.converterArray = JSON.parse(JSON.stringify(unitArray))
  },
  CALCULATE_PRODUCT_UNITS: (state, payload) => {
    if (payload.converterData?.unitType === state.productDetails.baseUnit) {
      const indivisibleUnit = state.converterArray.find(el => el.unitType === state.productDetails.indivisibleUnitOfMeasure)
      let calculatedAmount

      state.converterArray.forEach(convertObject => {
        if (convertObject.unitType === payload.converterData.unitType) {
          if (indivisibleUnit && new Decimal(payload.eventValue).times(indivisibleUnit.baseCalcUnit).toNumber() % 1) {
            convertObject.unitAmount = new Decimal(payload.eventValue).times(indivisibleUnit.baseCalcUnit).ceil().times(1).dividedBy(indivisibleUnit.baseCalcUnit).toNumber()
            calculatedAmount = convertObject.unitAmount
          } else {
            convertObject.unitAmount = new Decimal(payload.eventValue).toNumber()

            calculatedAmount = new Decimal(payload.eventValue).toNumber()
          }
        } else {
          convertObject.unitAmount = new Decimal(convertObject.baseCalcUnit).times(calculatedAmount).toNumber()
        }
      })
    } else {
      const indivisibleUnit = state.converterArray.find(el => el.unitType === state.productDetails.indivisibleUnitOfMeasure)
      const expectedIndivisibleUnit = state.converterArray.find(el => el.unitType === state.productDetails.expectedIndivisibleUnitOfMeasure)
      const unitFrom = state.converterArray.find(el => el.unitType === payload.converterData.unitType)
      const baseUnit = state.converterArray.find(el => el.unitType === state.productDetails.baseUnit)

      // Set decimal type numbers for precision
      if (baseUnit && state.converterArray) {
        baseUnit.unitAmount = (new Decimal(1).dividedBy(unitFrom.baseCalcUnit)).times(payload.eventValue)

        let calculatedAmount = baseUnit.unitAmount
        if (indivisibleUnit && new Decimal(baseUnit.unitAmount.times(indivisibleUnit.baseCalcUnit)).toNumber() % 1) {
          calculatedAmount = new Decimal((baseUnit.unitAmount).times(indivisibleUnit.baseCalcUnit).ceil()).times((new Decimal(1).dividedBy(indivisibleUnit.baseCalcUnit)))
        } else if (expectedIndivisibleUnit && new Decimal(baseUnit.unitAmount.times(expectedIndivisibleUnit.baseCalcUnit)).toNumber() % 1) {
          calculatedAmount = new Decimal((baseUnit.unitAmount).times(expectedIndivisibleUnit.baseCalcUnit).ceil()).times((new Decimal(1).dividedBy(expectedIndivisibleUnit.baseCalcUnit)))
        }

        // set rest of the unit values
        state.converterArray.forEach(convertObject => {
          convertObject.unitAmount = new Decimal(convertObject.baseCalcUnit).times(calculatedAmount).toNumber()
        })
      } else {
        if (state.isAfterDiscount) {
          state.totalProductValue = new Decimal(state.productDetails.net).times(payload.eventValue).toDecimalPlaces(2).toNumber()
          state.totalProductBaseValue = new Decimal(state.productDetails.net).times(payload.eventValue).toDecimalPlaces(2).toNumber()
        } else {
          state.totalProductValue = new Decimal(state.productDetails.discountNet).times(payload.eventValue).toDecimalPlaces(2).toNumber()
          state.totalProductBaseValue = new Decimal(state.productDetails.discountNet).times(payload.eventValue).toDecimalPlaces(2).toNumber()
        }
      }
    }
  },
  CALCULATE_PRODUCT_PRICE: (state) => {
    const indivisibleUnit = state.converterArray.find(el => el.unitType === state.productDetails.indivisibleUnitOfMeasure)
    if (indivisibleUnit) {
      if (state.isAfterDiscount) {
        state.totalProductValue = new Decimal(1).dividedBy(indivisibleUnit.baseCalcUnit).times(state.productDetails.net).toDecimalPlaces(2).times(indivisibleUnit.unitAmount).toNumber()
        state.totalProductBaseValue = new Decimal(1).dividedBy(indivisibleUnit.baseCalcUnit).times(state.productDetails.net).toDecimalPlaces(2).times(indivisibleUnit.unitAmount).toNumber()
      } else {
        state.totalProductValue = new Decimal(1).dividedBy(indivisibleUnit.baseCalcUnit).times(state.productDetails.discountNet).toDecimalPlaces(2).times(indivisibleUnit.unitAmount).toNumber()
        state.totalProductBaseValue = new Decimal(1).dividedBy(indivisibleUnit.baseCalcUnit).times(state.productDetails.discountNet).toDecimalPlaces(2).times(indivisibleUnit.unitAmount).toNumber()
      }
    }
  },
  GET_PRODUCT_DESCRIPTION: (state, productDescription) => {
    state.productDescription = productDescription.doc
    state.productName = productDescription.name
  },
  SET_MAIN_IMAGE: (state, productDefaultImage) => {
    state.arrayDownloadedMainImages = [...state.arrayDownloadedMainImages, productDefaultImage]
    for (let i = 0; i < state.productThumbnailsListId.length; i++) {
      state.productThumbnailsListId[i].mainImage = productDefaultImage.mainImage
    }
  },
  GET_DEFAULT_IMAGE: (state, productDefaultImage) => {
    state.productThumbnailsListId[0].mainImage = productDefaultImage
  },
  GET_THUMBNAILS_LIST_ID: (state, productThumbnailsListId) => {
    const arr = Object.values(productThumbnailsListId)
    state.productThumbnailsListId = [...arr]
  },
  GET_THUMBNAIL_SINGLE: (state, thumbnailSingle) => {
    for (let i = 0; i < state.productThumbnailsListId.length; i++) {
      state.productThumbnailsListId[i].thumbnail = thumbnailSingle[i].value
    }
  },
  GET_SIMILAR_PRODUCTS: (state, similarItems) => {
    state.similarProducts = similarItems
  },
  SET_LAST_WATCHED_PRODUCTS: (state, lastWatchedItems) => {
    state.lastWatchedProducts = lastWatchedItems
  },
  SET_FEATURED_PRODUCTS: (state, featuredItems) => {
    state.featuredProducts = featuredItems
  },
  CHANGE_DISCOUNT_STATUS: (state, status) => {
    state.isAfterDiscount = status
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
