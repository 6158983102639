export class Contractor {
  constructor (contractor) {
    this.address = contractor.address
    this.b2bAccess = contractor.b2bAccess
    this.b2bDepartment = contractor.b2bDepartment
    this.code = contractor.code
    this.creditLimit = contractor.creditLimit
    this.maxCreditLimit = contractor.maxCreditLimit
    this.deadline = contractor.deadline
    this.definitionOfPayment = contractor.definitionOfPayment
    this.deliveryThresholdPrice = contractor.deliveryThresholdPrice
    this.shippingPrice = contractor.shippingPrice
    this.enovaId = contractor.enovaId
    this.guid = contractor.guid
    this.id = contractor.id
    this.name = contractor.name
    this.nip = contractor.nip
    this.type = contractor.type
    this.actualLoyaltyPoints = contractor.actualLoyaltyPoints
  }
}
