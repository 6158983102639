import { ShoppingCart } from '@/models/shoppingCart'
import { ShoppingCartForPreview } from '@/models/shoppingCartForPreview'
import { requestStateEnum } from '@/enums/requestStateEnum'
import { SingleOrderProduct } from '@/models/singleOrderProduct'
import { Contractor } from '@/models/actualContractor'
import router from '@/router'

const state = {
  requestStatus: requestStateEnum.NOT_SENT,
  isBlocked: false,
  shoppingCartState: {
    quantityUpdated: false,
    priceUpdated: false,
    isSplitPayment: false
  },
  splitCommodities: [],
  currentShoppingCartId: null,
  unavailableProducts: null,
  shoppingCartsFilter: null,
  shoppingCarts: [],
  shoppingCartsForPreview: [],
  contractorCarts: [],
  transactionShoppingCart: {},
  multipleTransactionShoppingCart: [],
  unavailableTransactionProducts: {},
  actualContractor: {},
  loyaltyPoints: 0
}

export const getters = {
  getUnavailableProducts (state) {
    return state.unavailableProducts
  },
  getIsBlocked (state) {
    return state.isBlocked
  },
  getCurrentShoppingCartsId (state) {
    return state.currentShoppingCartId
  },
  getIsSplitPayment (state) {
    return state.shoppingCartState.isSplitPayment
  },
  getSplitPaymentProducts (state) {
    return state.splitCommodities
  },
  getShoppingCartState (state) {
    return state.shoppingCartState
  },
  getShoppingCarts (state) {
    return state.shoppingCarts
  },
  getShoppingCartsForPreview (state) {
    const filterShoppingCarts = state.shoppingCartsForPreview.filter(shoppingCart => shoppingCart.master === true)
    return filterShoppingCarts
  },
  getContractorCarts (state) {
    return state.contractorCarts
  },
  getShoppingCartsFilter (state) {
    return state.shoppingCartsFilter
  },
  getCurrentShoppingCartId (state) {
    if (state.shoppingCarts.length > 0) {
      return state.currentShoppingCartId
    } else {
      return 'domyslny_koszyk'
    }
  },
  getCurrentShoppingCart (state) {
    if (state.shoppingCarts.length > 0) {
      for (let i = 0; i < state.shoppingCarts.length; i++) {
        if (state.shoppingCarts[i].id === state.currentShoppingCartId) {
          return state.shoppingCarts[i]
        } else if (state.currentShoppingCartId === null) {
          return state.shoppingCarts[0]
        }
      }
    } else {
      return new ShoppingCart(
        {
          id: 'domyslny_koszyk',
          name: 'Koszyk domyślny',
          date: Date.now(),
          orderCommodities: []
        }
      )
    }
  },
  getUnavailableItems (state) {
    if (state.unavailableProducts !== null) {
      return state.unavailableProducts
    } else {
      return new ShoppingCart(
        {
          id: 'domyslny_koszyk',
          name: 'Koszyk domyślny',
          date: Date.now(),
          orderCommodities: []
        }
      )
    }
  },
  getRequestStatusEnum (state) {
    return state.requestStatus
  },
  getTransactionShoppingCart (state) {
    return state.transactionShoppingCart
  },
  getMultipleTransactionShoppingCart (state) {
    return state.multipleTransactionShoppingCart
  },
  getUnavailableTransactionProducts (state) {
    return state.unavailableTransactionProducts
  },
  getActualContractor (state) {
    return state.actualContractor
  },
  getActualLoyaltyPoints (state) {
    return state.actualLoyaltyPoints
  }
}

export const actions = {
  setBlockedUi: ({ commit, dispatch }, payload) => {
    commit('SET_BLOCK_UI_STATE', payload)
  },
  fetchShoppingCarts: async ({ commit, dispatch }, payload) => {
    try {
      const response = await payload.shoppingCartService.fetchBaskets()
      commit('SET_REQUEST_STATUS', requestStateEnum.SUCCESS)
      commit('SET_SHOPPING_CARTS', response)

      await dispatch('snackbarStore/showMessage', 'Pobrano koszyki użytkownika', {
        root: true
      })
      return response
    } catch (e) {
      await dispatch(
        'snackbarStore/showMessage',
        'Wystąpił błąd, proszę spróbować ponownie',
        { root: true }
      )
      commit('SET_REQUEST_STATUS', requestStateEnum.ERROR)
      return Promise.reject(e)
    }
  },
  fetchContractorCarts: async ({ commit, dispatch }, payload) => {
    try {
      const response = await payload.shoppingCartService.fetchContractorBaskets()
      commit('SET_REQUEST_STATUS', requestStateEnum.SUCCESS)
      commit('SET_CONTRACTOR_CARTS', response.data)
      await dispatch('snackbarStore/showMessage', 'Pobrano koszyki kontrahenta', {
        root: true
      })
      return response
    } catch (error) {
      await dispatch(
        'snackbarStore/showMessage',
        'Wystąpił błąd, proszę spróbować ponownie',
        { root: true }
      )
      commit('SET_REQUEST_STATUS', requestStateEnum.ERROR)
      return Promise.reject(error)
    }
  },
  fetchShoppingCartsForPreview: async ({ commit, dispatch }, payload) => {
    try {
      const response = await payload.shoppingCartService.fetchBasketsForPreview()
      commit('SET_CONTRACTOR_CARTS_FOR_PREVIEW', response)
      return response
    } catch (error) {
      await dispatch(
        'snackbarStore/showMessage',
        'Wystąpił błąd podczad pobierania koszyków. Proszę spróbować ponownie',
        { root: true }
      )
    }
  },
  takeOverBasket: async ({ commit, dispatch }, payload) => {
    try {
      const response = await payload.shoppingCartService.takeOverBasket(
        payload.contractorCart.id
      )
      commit('SET_REQUEST_STATUS', requestStateEnum.SUCCESS)
      commit('TAKE_OVER_BASKET', payload.contractorCart)
      commit('SET_CURRENT_SHOPPING_CART_ID', payload.contractorCart.id)
      await dispatch('snackbarStore/showMessage', 'Przejęto koszyk kontrahenta', {
        root: true
      })
      return response
    } catch (error) {
      await dispatch(
        'snackbarStore/showMessage',
        'Wystąpił błąd, proszę spróbować ponownie',
        { root: true }
      )
      commit('SET_REQUEST_STATUS', requestStateEnum.ERROR)
      return Promise.reject(error)
    }
  },
  setCurrentShoppingCartId: async ({ commit, dispatch }, payload) => {
    try {
      if (payload.shoppingCartId !== 'domyslny_koszyk') {
        const response = await payload.shoppingCartService.fetchBasket(
          payload.shoppingCartId
        )
        commit('SET_REQUEST_STATUS', requestStateEnum.SUCCESS)
        commit('SET_CURRENT_SHOPPING_CART_ID', payload.shoppingCartId)
        commit('SAVE_SHOPPING_CART', response)

        commit('UPDATE_SHOPPING_CART_STATUS', { quantityUpdated: false, priceUpdated: false })
        commit('UPDATE_SHOPPING_CART_SPLIT_PAYMENT', { splitPayment: false })
        return response
      } else {
        commit('SET_CURRENT_SHOPPING_CART_ID', 'domyslny_koszyk')
      }
    } catch (e) {
      await dispatch(
        'snackbarStore/showMessage',
        'Wystąpił błąd, proszę spróbować ponownie',
        { root: true }
      )
      commit('SET_REQUEST_STATUS', requestStateEnum.ERROR)
      return Promise.reject(e)
    }
  },
  changeQuantityInBasket: async ({ commit, dispatch }, payload) => {
    try {
      await payload.shoppingCartService.changeQuantity(
        payload.orderId,
        payload.quantityId,
        payload.quantityMap
      )

      commit('CHANGE_QUANTITY', {
        orderId: payload.orderId,
        quantityId: payload.quantityId,
        quantity: payload.quantityMap.quantity,
        indivisibleQuantity: payload.indivisibleQuantity,
        baseUnitPerIndivisible: payload.baseUnitPerIndivisible
      })

      await dispatch('snackbarStore/showMessage', 'Zmieniono ilość produktów', {
        root: true
      })
    } catch (e) {
      await dispatch(
        'snackbarStore/showMessage',
        'Wystąpił błąd, proszę spróbować ponownie',
        { root: true }
      )
      commit('SET_REQUEST_STATUS', requestStateEnum.ERROR)
      return Promise.reject(e)
    }
  },
  setShoppingCarts: (context, shoppingCarts) => {
    context.commit('SET_SHOPPING_CARTS', shoppingCarts)
  },
  filterShoppingCarts: (context, search) => {
    context.commit('FILTER_SHOPPING_CARTS', search)
  },
  saveShoppingCart: async ({ commit, dispatch }, payload) => {
    try {
      const response = await payload.shoppingCartService.renameBasket(payload)
      commit('SET_REQUEST_STATUS', requestStateEnum.SUCCESS)
      commit('SAVE_SHOPPING_CART', payload.shoppingCartInEditNameMode)

      await dispatch(
        'setCurrentShoppingCartId', {
          shoppingCartService: payload.shoppingCartService,
          shoppingCartId: payload.shoppingCartInEditNameMode.id
        }
      )

      await dispatch(
        'snackbarStore/showMessage',
        'Pomyślnie zmieniono nazwę koszyka',
        { root: true }
      )
      return response
    } catch (e) {
      await dispatch(
        'snackbarStore/showMessage',
        'Wystąpił błąd, proszę spróbować ponownie',
        { root: true }
      )
      commit('SET_REQUEST_STATUS', requestStateEnum.ERROR)
      return Promise.reject(e)
    }
  },
  cloneShoppingCart: async ({ commit, dispatch }, payload) => {
    try {
      const response = await payload.shoppingCartService.cloneBasket(payload)
      commit('CLONE_SHOPPING_CART', response)
      await dispatch(
        'setCurrentShoppingCartId', {
          shoppingCartService: payload.shoppingCartService,
          shoppingCartId: response.id
        }
      )

      await dispatch(
        'snackbarStore/showMessage',
        'Powielono koszyk pomyślnie',
        { root: true }
      )
      return response
    } catch (e) {
      await dispatch(
        'snackbarStore/showMessage',
        'Wystąpił błąd, proszę spróbować ponownie',
        { root: true }
      )
      commit('SET_REQUEST_STATUS', requestStateEnum.ERROR)
      return Promise.reject(e)
    }
  },
  addShoppingCart: async ({ commit, dispatch }, payload) => {
    try {
      const response = await payload.shoppingCartService.addBasket(payload.basketName)
      commit('SET_REQUEST_STATUS', requestStateEnum.SUCCESS)
      commit('ADD_SHOPPING_CART', response)

      await dispatch(
        'snackbarStore/showMessage',
        'Pomyślnie dodano nowy koszyk!',
        { root: true }
      )
    } catch (e) {
      commit('SET_REQUEST_STATUS', requestStateEnum.ERROR)
      await dispatch(
        'snackbarStore/showMessage',
        'Wystąpił błąd, proszę spróbować ponownie',
        { root: true }
      )
      return Promise.reject(e)
    }
  },
  removeShoppingCartById: async ({ commit, dispatch }, payload) => {
    try {
      const response = await payload.shoppingCartService.deleteBasket(payload)
      commit('REMOVE_SHOPPING_CART_BY_ID', payload.id)
      await dispatch('snackbarStore/showMessage', 'Usunięto koszyk!', {
        root: true
      })
      return response
    } catch (e) {
      commit('SET_REQUEST_STATUS', requestStateEnum.ERROR)
      await dispatch(
        'snackbarStore/showMessage',
        'Wystąpił błąd, proszę spróbować ponownie',
        { root: true }
      )
      return Promise.reject(e)
    }
  },
  deleteCreditBasket: async ({ commit, dispatch }, payload) => {
    try {
      commit('REMOVE_SHOPPING_CART_BY_ID', payload)
      commit('SET_CURRENT_SHOPPING_CART_ID', 'deleted')
    } catch (error) {
      return Promise.reject(error)
    }
  },
  checkBasketAvailability: async ({ commit, dispatch }, payload) => {
    try {
      const response = await payload.shoppingCartService.divideBasket(payload.basketId, payload.orderType)
      commit('SET_REQUEST_STATUS', requestStateEnum.SUCCESS)
      commit('DIVIDE_SHOPPING_CART', response)
      commit('UPDATE_SHOPPING_CART_STATUS', response)

      await dispatch('snackbarStore/showMessage', 'Zaktualizowano koszyk', { root: true })
    } catch (e) {
      commit('SET_REQUEST_STATUS', requestStateEnum.ERROR)
      await dispatch('snackbarStore/showMessage', 'Wystąpił błąd, proszę spróbować ponownie', { root: true })
      return Promise.reject(e)
    }
  },
  checkSplitPayment: async ({ commit, dispatch }, payload) => {
    try {
      const response = await payload.shoppingCartService.splitPayment(payload.basketId, payload.orderType)
      commit('SET_REQUEST_STATUS', requestStateEnum.SUCCESS)
      commit('UPDATE_SHOPPING_CART_SPLIT_PAYMENT', response)
    } catch (e) {
      console.log(e)
      commit('SET_REQUEST_STATUS', requestStateEnum.ERROR)
      await dispatch('snackbarStore/showMessage', 'Wystąpił błąd, proszę spróbować ponownie', { root: true })
      return Promise.reject(e)
    }
  },
  payForBasket: async ({ commit, dispatch }, payload) => {
    try {
      commit('SET_BLOCK_UI_STATE', true)
      const response = await payload.paymentService.registerPayment(payload.paymentDetails)
      commit('SET_BLOCK_UI_STATE', false)
      commit('SET_REQUEST_STATUS', requestStateEnum.SUCCESS)
      if (response.redirectUrl) {
        window.open(response.redirectUrl, '_self')
      } else {
        await router.push({ name: 'Status of the transaction', query: { orderId: payload.paymentDetails.shopOrderId, invoiceId: response.invoiceId } })
      }

      await dispatch('snackbarStore/showMessage', 'Zaktualizowano koszyk', { root: true })
    } catch (e) {
      commit('SET_REQUEST_STATUS', requestStateEnum.ERROR)
      commit('SET_BLOCK_UI_STATE', false)
      if (e.response.status === 402) {
        await dispatch('snackbarStore/showMessage', 'Proszę opłacić zaległe faktury lub dopłacić do zamówienia!', { root: true })
      } else {
        await dispatch('snackbarStore/showMessage', 'Wystąpił błąd, proszę spróbować ponownie', { root: true })
      }
      return Promise.reject(e)
    }
  },
  addProductToShoppingCart: async ({ commit, dispatch }, payload) => {
    try {
      const response = await payload.shoppingCartService.addProductToBasket(state.currentShoppingCartId, payload.product.id, payload.quantityMap)
      commit('SET_REQUEST_STATUS', requestStateEnum.SUCCESS)
      commit('ADD_PRODUCT_TO_SHOPPING_CART', {
        id: response.data.id,
        product: payload.product,
        quantity: payload.quantityMap.quantity,
        indivisibleQuantity: payload.indivisibleQuantity,
        baseUnitPerIndivisible: payload.baseUnitPerIndivisible
      })

      await dispatch('snackbarStore/showMessage', 'Pomyślnie dodano produkt', { root: true })
    } catch (e) {
      commit('SET_REQUEST_STATUS', requestStateEnum.ERROR)
      await dispatch('snackbarStore/showMessage', 'Wystąpił błąd, proszę spróbować ponownie', { root: true })
      return Promise.reject(e)
    }
  },
  removeProductFromShoppingCart: async ({ commit, dispatch }, payload) => {
    try {
      const response = await payload.shoppingCartService.deleteProductFromBasket(
        payload.productDetails,
        state.currentShoppingCartId
      )
      commit('REMOVE_PRODUCT_FROM_SHOPPING_CART', payload.productDetails)
      await dispatch('snackbarStore/showMessage', 'Usunięto Produkt!', {
        root: true
      })
      return response
    } catch (e) {
      commit('SET_REQUEST_STATUS', requestStateEnum.ERROR)
      await dispatch(
        'snackbarStore/showMessage',
        'Wystąpił błąd, proszę spróbować ponownie',
        { root: true }
      )
      return Promise.reject(e)
    }
  },
  removeProductFromUnavailableShoppingCart: async ({ commit, dispatch }, payload) => {
    try {
      const response = await payload.shoppingCartService.deleteProductFromBasket(
        payload.productDetails,
        payload.shoppingCartId
      )
      commit('REMOVE_PRODUCT_FROM_UNAVAILABLE_SHOPPING_CART', payload.productDetails)
      await dispatch('snackbarStore/showMessage', 'Usunięto Produkt!', {
        root: true
      })
      return response
    } catch (e) {
      commit('SET_REQUEST_STATUS', requestStateEnum.ERROR)
      await dispatch(
        'snackbarStore/showMessage',
        'Wystąpił błąd, proszę spróbować ponownie',
        { root: true }
      )
      return Promise.reject(e)
    }
  },
  moveProductFromShoppingCart: async ({ commit, dispatch }, payload) => {
    try {
      await payload.shoppingCartService.moveCommodityFromBasketTo({
        itemId: payload.itemId,
        orderId: payload.orderId,
        targetBasketId: payload.targetBasketId
      })
      commit(
        'MOVE_COMMODITY_FROM_SHOPPING_CART',
        payload
      )
    } catch (e) {
      commit('SET_REQUEST_STATUS', requestStateEnum.ERROR)
      await dispatch(
        'snackbarStore/showMessage',
        'Wystąpił błąd, proszę spróbować ponownie',
        { root: true }
      )
      return Promise.reject(e)
    }
  },
  fetchTransactionShoppingCart: async ({ commit, dispatch }, payload) => {
    commit('RESET_MULTIPLE_TRANSACTION_SHOPPING_CART')
    if (payload.basketId.length > 36) {
      const basketIds = payload.basketId.split(',')
      try {
        for (let i = 0; i < basketIds.length; i++) {
          const response = await payload.shoppingCartService.fetchBasket(basketIds[i])
          commit('SET_MULTIPLE_TRANSACTION_SHOPPING_CART', response)
        }
      } catch (e) {
        await dispatch(
          'snackbarStore/showMessage',
          'Wystąpił błąd podczas pobierania koszyka transakcji, proszę spróbować ponownie',
          { root: true }
        )
      }
    } else {
      try {
        const response = await payload.shoppingCartService.fetchBasket(payload.basketId)
        commit('SET_TRANSACTION_SHOPPING_CART', response[0])
        commit('SET_UNAVAILABLE_TRANSACTION_SHOPPING_CART', response[1])
      } catch (e) {
        await dispatch(
          'snackbarStore/showMessage',
          'Wystąpił błąd podczas pobierania koszyka transakcji, proszę spróbować ponownie',
          { root: true }
        )
      }
    }
  },
  fetchActualContractor: async ({ commit, dispatch }, payload) => {
    try {
      const response = await payload.shoppingCartService.fetchContractor()
      commit('SET_ACTUAL_CONTRACTOR', response.data)
    } catch (error) {
      await dispatch('snackbarStore/showMessage', 'Wystąpił błąd podczas pobierania danych kontraktora, proszę spróbować ponownie.', { root: true })
    }
  },
  setActualLoyaltyPoints: async ({ commit, dispatch }, payload) => {
    commit('CHANGE_ACTUAL_LOYALTY_POINTS', payload)
  }
}

export const mutations = {
  SET_REQUEST_STATUS: (state, status) => {
    state.requestStatus = status
  },
  SET_BLOCK_UI_STATE: (state, isBlocked) => {
    state.isBlocked = isBlocked
  },
  SET_SHOPPING_CARTS: (state, shoppingCarts) => {
    for (const shoppingCart of shoppingCarts) {
      if (
        !state.shoppingCarts.filter((e) => e.id === shoppingCart.id).length > 0
      ) {
        state.shoppingCarts.push(shoppingCart)
      }
    }
  },
  SET_CONTRACTOR_CARTS_FOR_PREVIEW: (state, cartsForPreview) => {
    const shoppingCarts = []
    for (const shoppingCart of cartsForPreview) {
      shoppingCarts.push(new ShoppingCartForPreview(shoppingCart))
    }
    state.shoppingCartsForPreview = shoppingCarts
  },
  SET_CONTRACTOR_CARTS: (state, contractorCarts) => {
    for (const contractorCart of contractorCarts) {
      if (
        !state.contractorCarts.filter((e) => e.id === contractorCart.id).length > 0
      ) {
        state.contractorCarts.push(new ShoppingCart(JSON.parse(JSON.stringify(contractorCart))))
      }
    }
  },
  TAKE_OVER_BASKET: (state, contractorCart) => {
    for (let i = 0; i < state.contractorCarts.length; i++) {
      if (state.contractorCarts[i].id === contractorCart.id) {
        state.contractorCarts.splice(i, 1)
        state.shoppingCarts.unshift(contractorCart)
        break
      }
    }
  },
  FILTER_SHOPPING_CARTS: (state, search) => {
    state.shoppingCartsFilter = [...state.shoppingCarts].filter(element => element.name.toUpperCase().includes(search.toUpperCase()))
  },
  SET_CURRENT_SHOPPING_CART_ID: (state, shoppingCartId) => {
    if (shoppingCartId === 'deleted') {
      state.currentShoppingCartId = state?.shoppingCarts[0]?.id
    } else {
      state.currentShoppingCartId = shoppingCartId
    }
  },
  SAVE_SHOPPING_CART: (state, shoppingCarts) => {
    if (shoppingCarts.length > 0) {
      for (let i = 0; i < state.shoppingCarts.length; i++) {
        if (state.shoppingCarts[i].id === shoppingCarts[0].id) {
          state.shoppingCarts[i] = new ShoppingCart(JSON.parse(JSON.stringify(shoppingCarts[0])))
        }
      }
      if (shoppingCarts.length > 1) {
        state.unavailableProducts = new ShoppingCart(JSON.parse(JSON.stringify(shoppingCarts[1])))
      } else {
        state.unavailableProducts = null
      }
    } else {
      for (let i = 0; i < state.shoppingCarts.length; i++) {
        if (state.shoppingCarts[i].id === shoppingCarts.id) {
          state.shoppingCarts[i] = new ShoppingCart(JSON.parse(JSON.stringify(shoppingCarts)))
        }
      }
    }
  },
  CLONE_SHOPPING_CART: (state, data) => {
    state.shoppingCarts.unshift(data)
  },
  ADD_SHOPPING_CART: (state, basket) => {
    state.shoppingCarts.unshift(basket)
    state.currentShoppingCartId = basket.id
    state.unavailableProducts = null
    state.shoppingCartState.quantityUpdated = false
    state.shoppingCartState.priceUpdated = false
  },
  REMOVE_SHOPPING_CART_BY_ID: (state, shoppingCartId) => {
    const indexOfElementToRemove = state.shoppingCarts.findIndex((element) => element.id === shoppingCartId)
    if (indexOfElementToRemove !== -1) {
      state.shoppingCarts.splice(indexOfElementToRemove, 1)
    }
  },
  ADD_PRODUCT_TO_SHOPPING_CART: (state, data) => {
    let found = false
    for (let i = 0; i < state.shoppingCarts.length; i++) {
      if (state.shoppingCarts[i].id === state.currentShoppingCartId) {
        if (state.shoppingCarts[i].orderCommodities.length > 0) {
          for (let j = 0; j < state.shoppingCarts[i].orderCommodities.length; j++) {
            if (state.shoppingCarts[i].orderCommodities[j].commodity.id === data.product.id) {
              state.shoppingCarts[i].orderCommodities[j].quantity += data.quantity
              state.shoppingCarts[i].orderCommodities[j].indivisibleQuantity += data.indivisibleQuantity
              found = true
              break
            }
          }
          if (!found) {
            state.shoppingCarts[i].orderCommodities.push(new SingleOrderProduct({ id: data.id, quantity: data.quantity, indivisibleQuantity: data.indivisibleQuantity, baseUnitPerIndivisible: data.baseUnitPerIndivisible, commodity: data.product, unitPrice: data.product.net }))
          }
        } else {
          state.shoppingCarts[i].orderCommodities.push(new SingleOrderProduct({ id: data.id, quantity: data.quantity, indivisibleQuantity: data.indivisibleQuantity, baseUnitPerIndivisible: data.baseUnitPerIndivisible, commodity: data.product, unitPrice: data.product.net }))
          break
        }
      }
    }
  },
  CHANGE_QUANTITY: (state, data) => {
    for (let i = 0; i < state.shoppingCarts.length; i++) {
      if (state.shoppingCarts[i].id === state.currentShoppingCartId) {
        if (state.shoppingCarts[i].orderCommodities.length > 0) {
          for (let j = 0; j < state.shoppingCarts[i].orderCommodities.length; j++) {
            if (state.shoppingCarts[i].orderCommodities[j].id === data.quantityId) {
              state.shoppingCarts[i].orderCommodities[j].quantity = data.quantity
              state.shoppingCarts[i].orderCommodities[j].baseUnitPerIndivisible = data.baseUnitPerIndivisible
              state.shoppingCarts[i].orderCommodities[j].indivisibleQuantity = data.indivisibleQuantity
              break
            }
          }
        }
      }
    }
  },
  UPDATE_SHOPPING_CART_STATUS: (state, data) => {
    state.shoppingCartState.quantityUpdated = data.quantityUpdated
    state.shoppingCartState.priceUpdated = data.priceUpdated
  },
  UPDATE_SHOPPING_CART_SPLIT_PAYMENT: (state, data) => {
    const splitPaymentProducts = []

    data?.splitPaymentCommoditiesId?.forEach(element => {
      const currentShoppingCart = JSON.parse(JSON.stringify(state.shoppingCarts.find(element => element.id === state.currentShoppingCartId)))
      currentShoppingCart.orderCommodities.forEach(orderCommodity => {
        if (orderCommodity.commodity.id === element) {
          splitPaymentProducts.push(orderCommodity)
        }
      })

      state?.unavailableProducts?.orderCommodities?.forEach(unavailableProduct => {
        if (unavailableProduct.commodity.id === element) {
          splitPaymentProducts.push(unavailableProduct)
        }
      })
    })

    state.shoppingCartState.isSplitPayment = data.splitPayment
    state.splitCommodities = splitPaymentProducts
  },
  DIVIDE_SHOPPING_CART: (state, data) => {
    const tempAvailableArray = JSON.parse(JSON.stringify(state.shoppingCarts.find(element => element.id === state.currentShoppingCartId)))
    if (data.quantityUpdated) {
      for (let i = 0; i < state.shoppingCarts.length; i++) {
        if (state.shoppingCarts[i].id === state.currentShoppingCartId) {
          state.shoppingCarts[i] = data.orders[0]
        }
      }
      if (data.orders[1]) {
        state.unavailableProducts = data.orders[1]
      }
    }
    if (data.priceUpdated) {
      for (let i = 0; i < state.shoppingCarts.length; i++) {
        if (state.shoppingCarts[i].id === state.currentShoppingCartId) {
          if (state.shoppingCarts[i].orderCommodities.length > 0) {
            for (let j = 0; j < state.shoppingCarts[i].orderCommodities.length; j++) {
              for (let k = 0; k < data.orders[0].orderCommodities.length; k++) {
                if (state.shoppingCarts[i].orderCommodities[j].id === data.orders[0].orderCommodities[k].id && state.shoppingCarts[i].orderCommodities[j].unitPrice !== data.orders[0].orderCommodities[k].unitPrice) {
                  state.shoppingCarts[i].orderCommodities[j] = data.orders[0].orderCommodities[k]
                  state.shoppingCarts[i].orderCommodities[j].isChanged = true
                }
              }
            }
          }
        }
      }
      for (let i = 0; i < state.shoppingCarts.length; i++) {
        if (state.shoppingCarts[i].id === state.currentShoppingCartId) {
          state.shoppingCarts[i].totalAmount = data.orders[0].totalAmount
          state.shoppingCarts[i].totalAmountWithoutDiscount = data.orders[0].totalAmountWithoutDiscount
        }
      }
      for (let i = 0; i < state?.unavailableProducts?.orderCommodities.length; i++) {
        for (let k = 0; k < tempAvailableArray.orderCommodities.length; k++) {
          if (state.unavailableProducts.orderCommodities[i].id === data?.orders[1]?.orderCommodities[k].id && state.unavailableProducts.orderCommodities[i].unitPrice !== data?.orders[1]?.orderCommodities[k].unitPrice) {
            state.unavailableProducts.orderCommodities[i] = data.orders[1].orderCommodities[k]
            state.unavailableProducts.orderCommodities[i].isChanged = true
          }
        }
      }
      for (let i = 0; i < state.shoppingCarts.length; i++) {
        if (state.shoppingCarts[i].id === state.currentShoppingCartId) {
          state.shoppingCarts[i].totalAmount = data.orders[0].totalAmount
          state.shoppingCarts[i].totalAmountWithoutDiscount = data.orders[0].totalAmountWithoutDiscount
        }
      }
      for (let i = 0; i < state?.unavailableProducts?.orderCommodities.length; i++) {
        for (let k = 0; k < tempAvailableArray.orderCommodities.length; k++) {
          if (state.unavailableProducts.orderCommodities[i].id === data?.orders[1]?.orderCommodities[k].id && state.unavailableProducts.orderCommodities[i].unitPrice !== data?.orders[1]?.orderCommodities[k].unitPrice) {
            state.unavailableProducts.totalAmount = data.orders[1].totalAmount
            state.unavailableProducts.totalAmountWithoutDiscount = data.orders[1].totalAmountWithoutDiscount
          }
        }
      }
    }
  },
  REMOVE_PRODUCT_FROM_SHOPPING_CART: (state, product) => {
    for (let i = 0; i < state.shoppingCarts.length; i++) {
      if (state.shoppingCarts[i].id === state.currentShoppingCartId) {
        if (state.shoppingCarts[i].orderCommodities.length > 0) {
          for (let j = 0; j < state.shoppingCarts[i].orderCommodities.length; j++) {
            if (state.shoppingCarts[i].orderCommodities[j].id === product.id) {
              state.shoppingCarts[i].orderCommodities.splice(j, 1)

              if (product.commodity?.splitPayment === true) {
                const splitIndex = state.splitCommodities.findIndex((item) => item.commodity.id === product.commodity.id)
                if (splitIndex !== -1) {
                  state.splitCommodities.splice(splitIndex, 1)
                }

                if (state.splitCommodities.length === 0) {
                  state.shoppingCartState.isSplitPayment = false
                }
              }
              break
            }
          }
        }
      }
    }
  },
  REMOVE_PRODUCT_FROM_UNAVAILABLE_SHOPPING_CART: (state, product) => {
    for (let j = 0; j < state.unavailableProducts.orderCommodities.length; j++) {
      if (state.unavailableProducts.orderCommodities[j].id === product.id) {
        state.unavailableProducts.orderCommodities.splice(j, 1)

        if (product.commodity?.splitPayment === true) {
          const splitIndex = state.splitCommodities.findIndex((commodityId) => commodityId === product.commodity.id)

          state.splitCommodities.splice(splitIndex, 1)

          if (state.splitCommodities.length === 0) {
            state.shoppingCartState.isSplitPayment = false
          }
        }
        break
      }
    }
  },
  MOVE_COMMODITY_FROM_SHOPPING_CART: (state, data) => {
    for (let i = 0; i < state.shoppingCarts.length; i++) {
      if (state.shoppingCarts[i].id === data.orderId) {
        for (let j = 0; j < state.shoppingCarts[i].orderCommodities.length; j++) {
          if (state.shoppingCarts[i].orderCommodities[j].id === data.itemId) {
            state.shoppingCarts[i].orderCommodities.splice(j, 1)
            break
          }
        }
      }
    }
  },
  SET_TRANSACTION_SHOPPING_CART: (state, data) => {
    state.transactionShoppingCart = new ShoppingCart(data)
  },
  SET_UNAVAILABLE_TRANSACTION_SHOPPING_CART: (state, data) => {
    state.unavailableTransactionProducts = new ShoppingCart(data)
  },
  RESET_MULTIPLE_TRANSACTION_SHOPPING_CART: (state) => {
    state.multipleTransactionShoppingCart = []
  },
  SET_MULTIPLE_TRANSACTION_SHOPPING_CART: (state, data) => {
    const newShoppingCart = new ShoppingCart(data[0])
    if (data.length > 1) {
      newShoppingCart.unavailableCart = new ShoppingCart(data[1])
    }
    state.multipleTransactionShoppingCart.push(newShoppingCart)
  },
  SET_ACTUAL_CONTRACTOR: (state, data) => {
    const loyaltyPoints = data.actualLoyaltyPoints
    state.actualContractor = new Contractor(data)
    if (loyaltyPoints !== undefined) {
      state.actualLoyaltyPoints = loyaltyPoints
    } else {
      state.actualLoyaltyPoints = 0
    }
  },
  CHANGE_ACTUAL_LOYALTY_POINTS: (state, data) => {
    state.actualLoyaltyPoints = data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
