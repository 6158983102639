
export class SingleProduct {
  constructor (commodity) {
    this.id = commodity.id
    this.guid = commodity.guid
    this.code = commodity.code
    this.name = commodity.name
    this.ean = commodity.ean
    this.net = commodity.net
    this.discountNet = commodity.discountNet
    this.baseUnit = commodity.baseUnit
    this.splitPayment = commodity.splitPayment
    this.hasThumbnail = commodity.hasThumbnail
    this.divisibleCommodity = commodity.divisibleCommodity
    this.vatValue = commodity.vatValue
    this.outStorehouse = commodity.outStorehouse
    this.storehouse = commodity.storehouse
    this.vatDefinitions = commodity.vatDefinitions
    this.expectedIndivisibleUnitOfMeasure = commodity.expectedIndivisibleUnitOfMeasure
    this.indivisibleUnitOfMeasure = commodity.indivisibleUnitOfMeasure
    this.isDeleted = commodity.isDeleted
    this.producer = commodity.producer
    this.consignmentStorehouse = commodity.consignmentStorehouse
    this.ispremium = commodity.ispremium
  }

  parseThumbnailsList (thumbnails) {
    const thumbnailsList = []
    if (thumbnails !== undefined) {
      for (const thumbnail of thumbnails) {
        thumbnailsList.push(new ThumbnailsList(thumbnail))
      }
    } else {
      return thumbnailsList
    }

    return thumbnailsList
  }

  parsePath (paths) {
    const pathsList = []
    if (paths !== undefined) {
      for (const path of paths) {
        pathsList.push(new Path(path))
      }
    } else {
      return pathsList
    }

    return pathsList
  }
}

export class Characteristics {
  constructor (item = {}) {
    this.producer = item.producer
    this.lock = item.lock
    this.waterResistance = item.waterResistance
    this.abrasionClass = item.abrasionClass
    this.thread = item.thread
    this.headShape = item.headShape
    this.socket = item.socket
    this.spacing = item.spacing
    this.diameter = item.diameter
    this.colorOfLight = item.colorOfLight
    this.silentClosing = item.silentClosing
    this.openingAngle = item.openingAngle
    this.powerFactor = item.powerFactor
    this.bearingCapacity = item.bearingCapacity
    this.moistureResistance = item.moistureResistance
    this.hiddenWiring = item.hiddenWiring
    this.antibacterial = item.antibacterial
    this.isAvailability = item.isAvailability
    this.profile = item.profile
    this.symbol = item.symbol
    this.color = item.color
    this.material = item.material
    this.intendedUse = item.intendedUse
    this.type = item.type
    this.structure = item.structure
    this.collection = item.collection
    this.length = item.length
    this.guaranteePeriod = item.guaranteePeriod
    this.height = item.height
    this.thickness = item.thickness
    this.width = item.width
  }
}

export class Images {
  constructor (images) {
    this.id = images.id
    this.image = images.image
  }
}
export class CommodityCharacteristic {
  constructor (characteristic) {
    this.name = characteristic.name
    this.value = characteristic.value
  }
}
export class ThumbnailsList {
  constructor (Thumbnails) {
    this.id = Thumbnails.id
    this.name = Thumbnails.name
    this.main = Thumbnails.main
    this.thumbnail = Thumbnails.thumbnail
    this.image = Thumbnails.image
  }
}
export class Path {
  constructor (path) {
    this.id = path.id
    this.name = path.name
    this.children = this.parseChildren(path.children)
  }

  parseChildren (children) {
    const childrenArr = []
    if (children !== undefined) {
      for (const child of children) {
        childrenArr.push(new Children(child))
      }
    } else {
      return childrenArr
    }

    return childrenArr
  }
}
export class Children {
  constructor (child) {
    this.id = child.id
    this.name = child.name
  }
}
