import { filterCharacteristicsTypeEnum } from '@/enums/filterCharacteristicsTypeEnum'

export class FilterCharacteristicModel {
  constructor (characteristic = {}) {
    this.name = this.parseName(characteristic.name)
    this.type = characteristic.type
    this.valuesArray = this.parseCharacteristicsValues(characteristic.values)
    this.min = this.parseMin(characteristic.values)
    this.max = this.parseMax(characteristic.values)
    this.selected = this.parseSelected()
  }

  parseName (value) {
    if (value !== 'length') {
      return value
    } else {
      return 'commodity_length'
    }
  }

  parseCharacteristicsValues (values) {
    if (this.type !== filterCharacteristicsTypeEnum.DOUBLE_SET && this.type !== filterCharacteristicsTypeEnum.BIGDECIMAL_SET) {
      return Array.from(values).map(value => ({ name: value, value }))
    }
    return values
  }

  parseMin (values) {
    if (values.length > 1 && (this.type === filterCharacteristicsTypeEnum.DOUBLE_SET || this.type === filterCharacteristicsTypeEnum.BIGDECIMAL_SET)) {
      return Math.min(...values.map(item => item)) > 0 ? Math.min(...values.map(item => item)) : 0
    }
    return undefined
  }

  parseMax (values) {
    if (values.length > 1 && (this.type === filterCharacteristicsTypeEnum.DOUBLE_SET || this.type === filterCharacteristicsTypeEnum.BIGDECIMAL_SET)) {
      return Math.max(...values.map(item => item))
    }
    return undefined
  }

  parseSelected () {
    if (this.min !== undefined && this.max !== undefined) {
      return [this.min, this.max]
    }
    return undefined
  }
}
