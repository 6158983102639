<template>
  <div class="drawer" :style="{ width: drawerWidth }">
    <div class="user-section p-d-flex p-jc-center" :class="{'resize-user-section-collapsed': collapsed}">
      <div class="p-d-flex p-dir-col p-ai-center p-jc-center">
       <div class="avatar-box p-d-flex p-ai-center p-jc-center">
        <Image v-if='avatar !== 204' v-bind:src="avatar" :class="{'resize-image-collapsed': collapsed}" preview/>
          <div v-else style='width: 40px; height: 40px; background-color: #d2d2d2; border-radius: 20px' class='p-d-flex p-jc-center p-ai-center'><i class="pi pi-user" style="font-size: 1.2rem"></i></div>
       </div>
        <div v-if="collapsed" class="p-d-flex p-dir-col p-ai-center p-jc-center">
        </div>
        <div v-else class="p-d-flex p-dir-col p-ai-center p-jc-center p-mb-2">
          <h4>{{ userProfile.firstName + ' ' + userProfile.lastName  }}</h4>
          <p v-if='userRole !== "Administrator"' class='p-text-center p-mb-2 p-mt-2'>{{ userDetails }}</p>
          <p v-else class='p-mt-2'>{{ userRole }}</p>
          <p>Dostępny kredyt: <b>{{ creditLimit?.actualCreditLimit }} zł</b></p>
        </div>
        <i class="pi pi-bars p-mt-2" @click="toggleDrawer" style="color: #ffffff; font-size: 25px; cursor: pointer;"></i>
      </div>
    </div>
    <div class="menu-section">
      <PanelMenu :model="listViews" :exact="true" :class="{'resize-text-collapsed': collapsed}">
      </PanelMenu>
    </div>
  </div>
</template>

<script>
import { ref, computed, inject, onMounted } from 'vue'
import PanelMenu from 'primevue/panelmenu'
import { drawerWidth, toggleDrawer, collapsed } from '@/components/NavigationDrawer/drawerState'
import { useStore } from 'vuex'
import { setRole } from '@/utils/utils'
import { userRoleInterfaceEnum } from '@/enums/userRoleEnum'
import { itemsAdmin } from '@/components/NavigationDrawer/itemsAdmin'
import { itemsSuperAdmin } from '@/components/NavigationDrawer/itemsSuperAdmin'
import { itemsUser } from '@/components/NavigationDrawer/itemsUser'
import Image from 'primevue/image'

export default {
  name: 'NavigationDrawer',
  components: {
    PanelMenu,
    Image
  },
  setup (props, { emit }) {
    const keyCloak = inject(process.env.VUE_APP_KEYCLOAK_PROVIDE_VARIABLE)

    const store = useStore()
    const menu = ref()
    const userRole = ref('')

    const userService = store.getters['serviceStore/getUserService']
    const userProfile = computed(() => store.getters['userStore/getUserProfile'])
    const userDetails = computed(() => store.getters['userStore/getUserDetails'])
    const creditLimit = computed(() => store.getters['userStore/getCreditLimit'])
    const avatar = computed(() => store.getters['userStore/getAvatarData'])
    const priceStatus = computed({
      get () {
        return store.getters['productStore/getIsAfterDiscount']
      },
      async set () {
        try {
          if (priceStatus.value) {
            await store.dispatch('productStore/changeStateOfDiscountStatus', false)
            await store.dispatch('snackbarStore/showMessage', 'Ustawiono ceny na status: Przed rabatem')
          } else {
            await store.dispatch('productStore/changeStateOfDiscountStatus', true)
            await store.dispatch('snackbarStore/showMessage', 'Ustawiono ceny na status: Po rabacie')
          }
        } catch (error) {
          await store.dispatch('snackbarStore/showMessage', 'Nastąpił błąd w zmianie cen')
        }
      }
    })

    const listViews = ref(null)

    onMounted(async () => {
      userRole.value = setRole(keyCloak.realmAccess.roles)
      switch (userRole.value) {
        case userRoleInterfaceEnum.SUPER_ADMIN: {
          listViews.value = itemsSuperAdmin.value
          break
        }
        case userRoleInterfaceEnum.ADMIN: {
          listViews.value = itemsAdmin.value
          break
        }
        case userRoleInterfaceEnum.USER: {
          listViews.value = itemsUser.value
          break
        }
        default: {
          listViews.value = itemsUser.value
        }
      }
      try {
        await store.dispatch('userStore/fetchMerchantLimit', {
          userService: userService
        })
      } catch (error) {
        await store.dispatch('snackbarStore/showMessage', 'Nastąpił błąd w pobieraniu kontraktora')
      }
      try {
        await store.dispatch('userStore/fetchAvatar', { userService: userService })
      } catch (error) {
        await store.dispatch('snackbarStore/showMessage', 'Wystąpił błąd podczas pobierania pliku')
      }
      try {
        await store.dispatch('userStore/getUserDetails', { userService: userService })
      } catch (error) {
        await store.dispatch('snackbarStore/showMessage', 'Nastąpił błąd w pobieraniu danych użytkownika')
      }
    })

    return {
      menu,
      drawerWidth,
      toggleDrawer,
      collapsed,
      priceStatus,
      userProfile,
      userRole,
      listViews,
      creditLimit,
      avatar,
      userDetails
    }
  }
}
</script>

<style lang="scss" scoped>
::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: #F5F5F5;
}

::-webkit-scrollbar
{
  width: 10px;
  background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #555;
}
.p-togglebutton.p-button{
  background-color: $primary_color;
  border: 1px solid $primary_color;
  border-radius: 0;
  color: white;
  padding: 12px 30px;

  ::v-deep(.p-button-icon){
    color: white;
  }

  &:hover{
    background-color: white;
    border: 1px solid #B72937;
    color: #B72937;
  }
}
.p-togglebutton.p-button.p-highlight {
  background-color: $navigation_black;
  border: 1px solid $primary_color;
  color: white;
  padding: 12px 30px;
  &:hover{
    ::v-deep(.p-button-icon){
      color: $primary_color;
    }
    background-color: white;
    border: 1px solid #B72937;
    color: #B72937;
  }
}
.drawer{
  background-color: $navigation_black;
  position: fixed;
  top: 100px;
  bottom: 0;
  overflow-y: auto;
  transition: 0.3s ease;

  .user-section{
    padding-bottom: 25px;
    transition: 0.3s ease;

    .avatar-box{
      width: 100px;
      height: 100px;

      .p-image{
        width: 60px;
        height: 60px;
      }
      ::v-deep(img) {
        width: 60px;
        height: 60px;
        object-fit: cover;
        border-radius: 35px;
        transition: 0.2s linear;
      }

      .resize-image-collapsed{
        width: 40px;
        height: 40px;
        transition: 0.2s linear;

        ::v-deep(img) {
          margin-bottom: 25px;
          width: 40px;
          height: 40px;
          object-fit: cover;
          border-radius: 25px;
          transition: 0.2s linear;
        }
      }
    }

    .user-info-wrapper{
      height: 65px;
    }
    h4{
      color: white;
    }

    p{
      margin: 5px;
      color: white;
      font-size: 0.9em;
    }
  }

  .resize-user-section-collapsed{
    padding: 15px 0 10px 0;
    transition: 0.3s ease;
  }
  .resize-text-collapsed{
    display: flex;
    flex-direction: column;
    margin-left: 0;
    font-size: 0.6rem !important;
    transition: 0.3s ease;
color: red;
    ::v-deep(.p-panelmenu-header-link){
      .p-menuitem-text{
        display: none;
      }
    }
  }

  ::v-deep(.p-panelmenu) {
    background: $navigation_black;

    .p-panelmenu-header:hover{
      color: black;
    }

    .p-panelmenu-header-link[aria-expanded="true"] {
      background-color: #242424;
    }

    .p-panelmenu-header > a {
      background: $navigation_black;
      border: none;
    }
    .p-panelmenu-header > a > .p-menuitem-icon {
      margin-right: 1rem;
      margin-left: 1rem;
      width: 22px !important;
      text-align: center;
      background-repeat: no-repeat;
    }

    .p-panelmenu-content{
      background: $navigation_black;
      border: none;

      //a[aria-expanded="true"]{
      //  background-color: #242424;
      //
      //  .p-menuitem-text{
      //    color: #FFCBD5;
      //  }
      //}

      .p-menuitem-link{
        color: #ffffff;

        .p-menuitem-text{
          color: #ffffff;
        }
      }
    }

    .product-icon{
      height: 20px;
      width: 18px;
      background-image: url("../../assets/icons/products_icon.svg");
    }

    .cut-icon{
      width: 17.692px;
      height: 19.108px;
      background: url("../../assets/icons/cut_icon.svg");
    }

    .help-icon{
      height: 21px;
      width: 21px;
      background-image: url("../../assets/icons/help_icon.svg");
    }

    .document-icon{
      height: 23px;
      width: 17.692px;
      background-image: url("../../assets/icons/document_icon.svg");
    }

    .handshake-icon{
      height: 18px;
      width: 22px;
      background-image: url("../../assets/icons/handshake_icon.svg");
    }
    .newsletter-icon{
      height: 18px;
      width: 20px;
      background-image: url("../../assets/icons/newsletter_icon.svg");
    }
 .chat-icon{
      height: 22px;
      width: 22px;
      background-image: url("../../assets/icons/chat_icon.svg");
      color: white;
    }
    .p-menuitem-text{
      margin-left: 0.5rem;
      transition: 0.3s ease;
    }

    .p-panelmenu-icon{
      position: absolute;
      right: 0.5em;
    }
    .router-link-active-exact {
      background: #565656 !important;
      border-color: #565656 !important;
    }
  }
}

</style>
