import authContentTypeHeader from '@/services/auth_content_type_header'
import instance from '@/services/api'
import { DepartmentsModel } from '@/models/departmentsModel'

export class DepartmentService {
  async fetchDepartments () {
    try {
      const response = await instance.get('/contractors/departments')
      return Promise.resolve(new DepartmentsModel(response.data))
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async putDepartments (departmentData) {
    try {
      await instance.put('/contractors/departments', departmentData, { headers: authContentTypeHeader() })
      return Promise.resolve(true)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}
