// import { parseArticleDate } from '@/utils/utils'

export class Article {
  constructor (element = {}) {
    this.attachments = element.attachments
    this.content = element.content
    this.id = element.id
    this.image = element.image
    this.lead = element.lead
    this.publishDateStart = element.publishDateStart
    this.publishDateStop = element.publishDateStop
    this.title = element.title
  }
}
