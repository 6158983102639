export class ContractorPreview {
  constructor (contractorPreview) {
    this.actualLoyaltyPoints = contractorPreview.actualLoyaltyPoints
    this.address = contractorPreview.address
    this.b2bAccess = contractorPreview.b2bAccess
    this.b2bDepartment = contractorPreview.b2bDepartment
    this.building = contractorPreview.building
    this.code = contractorPreview.code
    this.creditLimit = contractorPreview.creditLimit
    this.deadline = contractorPreview.deadline
    this.definitionOfPayment = contractorPreview.definitionOfPayment
    this.deliveryThresholdPrice = contractorPreview.deliveryThresholdPrice
    this.enovaId = contractorPreview.enovaId
    this.furniture = contractorPreview.furniture
    this.guid = contractorPreview.guid
    this.id = contractorPreview.id
    this.maxCreditLimit = contractorPreview.maxCreditLimit
    this.name = contractorPreview.name
    this.nip = contractorPreview.nip
    this.shippingPrice = contractorPreview.shippingPrice
    this.type = contractorPreview.type
  }
}
