import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'
import { activedRoutes } from '@/utils/utils'
import { userRoleEnum } from '@/enums/userRoleEnum'

const setActivedRoutes = (next, to) => {
  to.matched.forEach((record) => {
    activedRoutes.splice(0, activedRoutes.length)
    if (record.children.length > 0) {
      return
    }
    // eslint-disable-next-line array-callback-return
    record.meta.breadcrumb.map((_, i, arr) => {
      activedRoutes.push(arr[i])
    })
  })
  next()
}

const onBeforeRouteCheck = async (to, _, next, userRole) => {
  switch (userRole) {
    case userRoleEnum.SUPER_ADMIN: {
      const isSuperAdmin = await store.getters['userStore/getIsSuperAdmin']
      if (to.meta.protect && !isSuperAdmin) {
        next({ name: 'NotFound' })
      } else if ((to.meta.protect && isSuperAdmin) || !to.meta.protect) {
        setActivedRoutes(next, to)
      }
      return
    }
    case userRoleEnum.ADMIN: {
      const isAdmin = await store.getters['userStore/getIsAdmin']
      if (to.meta.protect && !isAdmin) {
        next({ name: 'NotFound' })
      } else if ((to.meta.protect && isAdmin) || !to.meta.protect) {
        setActivedRoutes(next, to)
      }
      return
    }
    case userRoleEnum.USER: {
      const isUser = await store.getters['userStore/getIsUser']
      if (to.meta.protect && !isUser) {
        next({ name: 'NotFound' })
      } else if ((to.meta.protect && isUser) || !to.meta.protect) {
        setActivedRoutes(next, to)
      }
      return
    }
    default:
      next({ name: 'NotFound' })
  }
}

export const routes = [
  {
    path: '/',
    alias: '/home',
    name: 'Home',
    component: () => import(/* webpackChunkName: "Home" */ '@/views/HomeView.vue'),
    meta: {
      title: 'Abler Omnichannel - Strona domowa',
      breadcrumb: [{ label: 'Strona główna', path: '/', parent: null }],
      routeName: 'Witaj',
      metaTags: [
        {
          name: 'description',
          content: 'Strona domowa aplikacji Omnichannel Abler.'
        },
        {
          property: 'og:description',
          content: 'Strona domowa aplikacji Omnichannel Abler.'
        }
      ]
    },
    beforeEnter: (to, _, next) => onBeforeRouteCheck(to, _, next, userRoleEnum.USER)
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "NotFound" */ '@/components/NotFound.vue'),
    meta: {
      title: 'Abler Omnichannel - Strona nie istnieje',
      breadcrumb: [
        { label: 'Strona główna', path: '/', parent: null },
        { label: 'Nie znaleziono', path: '/not-found', parent: null }
      ],
      routeName: 'Strona nie istnieje',
      metaTags: [
        {
          name: 'description',
          content: 'Strona nie istnieje.'
        },
        {
          property: 'og:description',
          content: 'Strona nie istnieje.'
        }
      ]
    },
    beforeEnter: (to, _, next) => onBeforeRouteCheck(to, _, next, userRoleEnum.USER)
  },
  {
    path: '/avatar-management',
    name: 'Avatar Management',
    component: () => import(/* webpackChunkName: "AvatarManagementView" */ '@/views/AvatarManagementView.vue'),
    meta: {
      title: 'Abler Omnichannel - Konfiguracja avatara',
      breadcrumb: [
        { label: 'Strona główna', path: '/', parent: null },
        { label: 'Konfiguracja avatara', path: '/avatar-management', parent: 'Root' }
      ],
      routeName: 'Konfiguruj avatara',
      metaTags: [
        {
          name: 'description',
          content: 'Strona zawiera konfigurację avatara użytkownika.'
        },
        {
          property: 'og:description',
          content: 'Strona zawiera konfigurację avatara użytkownika.'
        }
      ]
    },
    beforeEnter: (to, _, next) => onBeforeRouteCheck(to, _, next, userRoleEnum.USER)
  },
  {
    path: '/carousel-config',
    name: 'Carousel config',
    component: () => import(/* webpackChunkName: "CarouselConfigView" */ '@/admin_panel/CarouselConfigView.vue'),
    meta: {
      protect: true,
      title: 'Abler Omnichannel - Konfiguracja karuzeli',
      breadcrumb: [
        { label: 'Strona główna', path: '/', parent: null },
        { label: 'Konfiguracja karuzeli', path: '/carousel-config', parent: 'Root' }
      ],
      routeName: 'Konfiguruj karuzele',
      metaTags: [
        {
          name: 'description',
          content: 'Strona zawiera konfigurację karuzeli strony domowej.'
        },
        {
          property: 'og:description',
          content: 'Strona zawiera konfigurację karuzeli strony domowej.'
        }
      ]
    },
    beforeEnter: (to, _, next) => onBeforeRouteCheck(to, _, next, userRoleEnum.ADMIN)
  },
  {
    path: '/articles-config-view',
    name: 'Articles config view',
    props: (route) => ({ ...route.params, start: route.query.from, end: route.query.to }),
    component: () => import(/* webpackChunkName: "ArticlesConfigView" */ '@/admin_panel/ArticlesConfigView.vue'),
    meta: {
      protect: true,
      title: 'Abler Omnichannel - Konfiguracja aktualności',
      breadcrumb: [
        { label: 'Strona główna', path: '/', parent: null },
        { label: 'Aktualność', path: '/articles', parent: 'Root' },
        { label: 'Edycja aktualności', path: '/articles-config-view', parent: 'Articles' }
      ],
      routeName: 'Edycja aktualności',
      metaTags: [
        {
          name: 'description',
          content: 'Strona zawiera listę aktualności znajdujące się w aplikacji Abler Omnichannel dostępnych do edycji.'
        },
        {
          property: 'og:description',
          content: 'Strona zawiera listę aktualności znajdujące się w aplikacji Abler Omnichannel dostępnych do edycji.'
        }
      ]
    },
    beforeEnter: (to, _, next) => onBeforeRouteCheck(to, _, next, userRoleEnum.ADMIN)
  },
  {
    path: '/article-new',
    name: 'Article new',
    props: (route) => ({ ...route.params, articleId: route.query.articleId }),
    component: () => import(/* webpackChunkName: "ArticleConfigView" */ '@/admin_panel/ArticleConfigView.vue'),
    meta: {
      protect: true,
      title: 'Abler Omnichannel - Konfiguracja aktualności',
      breadcrumb: [
        { label: 'Strona główna', path: '/', parent: null },
        { label: 'Aktualności', path: '/articles', parent: 'Root' },
        { label: 'Dodaj aktualność', path: '/article-new', parent: 'Articles' }
      ],
      metaTags: [
        {
          name: 'description',
          content: 'Strona umożliwia dodawanie i edycję aktualności.'
        },
        {
          property: 'og:description',
          content: 'Strona umożliwia dodawanie i edycję aktualności.'
        }
      ]
    },
    beforeEnter: (to, _, next) => onBeforeRouteCheck(to, _, next, userRoleEnum.ADMIN)
  },
  {
    path: '/article-config',
    name: 'Article config',
    props: (route) => ({ ...route.params, articleId: route.query.articleId }),
    component: () => import(/* webpackChunkName: "ArticleConfigView" */ '@/admin_panel/ArticleConfigView.vue'),
    meta: {
      protect: true,
      title: 'Abler Omnichannel - Konfiguracja aktualności',
      breadcrumb: [
        { label: 'Strona główna', path: '/', parent: null },
        { label: 'Aktualności', path: '/articles', parent: 'Root' },
        { label: 'Edycja aktualności', path: '/articles-config-view?from=0&to=9', parent: 'Aktualności' },
        { label: 'Nazwa aktualności', path: '/article-config', parent: 'Edycja aktualności' }
      ],
      routeName: 'Modyfikacja / Dodanie aktualności',
      metaTags: [
        {
          name: 'description',
          content: 'Strona umożliwia dodawanie i edycję aktualności.'
        },
        {
          property: 'og:description',
          content: 'Strona umożliwia dodawanie i edycję aktualności.'
        }
      ]
    },
    beforeEnter: async (to, _, next) => {
      const currentArticles = await JSON.parse(JSON.stringify(store.getters['articleStore/getCurrentArticle']))
      const lastElement = to.meta?.breadcrumb?.map(element => element).at(-1)
      // const lastElement = to.meta?.breadcrumb.map((element) => element).pop()
      lastElement.label = currentArticles?.title
      onBeforeRouteCheck(to, _, next, userRoleEnum.ADMIN)
    }
  },
  {
    path: '/articles',
    name: 'Articles',
    props: (route) => ({ ...route.params, start: route.query.from, end: route.query.to }),
    component: () => import(/* webpackChunkName: "AllArticlesView" */ '@/views/AllArticlesView.vue'),
    meta: {
      title: 'Abler Omnichannel - Wszystkie aktualności',
      breadcrumb: [
        { label: 'Strona główna', path: '/', parent: null },
        { label: 'Aktualności', path: '/articles', parent: 'Root' }
      ],
      routeName: 'Przegląd aktualności',
      metaTags: [
        {
          name: 'description',
          content: 'Strona zawiera wszystkie aktualności znajdujące się w aplikacji Abler Omnichannel.'
        },
        {
          property: 'og:description',
          content: 'Strona zawiera wszystkie aktualności znajdujące się w aplikacji Abler Omnichannel.'
        }
      ]
    },
    beforeEnter: (to, _, next) => onBeforeRouteCheck(to, _, next, userRoleEnum.USER)
  },
  {
    path: '/article/:articleId',
    name: 'Article',
    props: true,
    component: () => import(/* webpackChunkName: "ArticleView" */ '@/views/ArticleView.vue'),
    meta: {
      title: 'Abler Omnichannel - Aktualność',
      breadcrumb: [
        { label: 'Strona główna', path: '/', parent: null },
        { label: 'Aktualności', path: '/articles', parent: 'Root' },
        { label: 'Detale aktualności', path: '#', parent: 'Aktualności' }
      ],
      routeName: 'Przegląd aktualności',
      metaTags: [
        {
          name: 'description',
          content: 'Strona zawiera artykuł znajdujący się w aplikacji Abler Omnichannel.'
        },
        {
          property: 'og:description',
          content: 'Strona zawiera artykuł znajdujący się w aplikacji Abler Omnichannel.'
        }
      ]
    },
    beforeEnter: async (to, _, next) => {
      const currentArticles = await JSON.parse(JSON.stringify(store.getters['articleStore/getCurrentArticle']))
      const lastElement = to.meta?.breadcrumb?.map((element) => element).at(-1)
      lastElement.label = currentArticles?.title
      await onBeforeRouteCheck(to, _, next, userRoleEnum.USER)
    }
  },
  {
    path: '/chat',
    name: 'Chat',
    component: () => import(/* webpackChunkName: "MessagesView" */ '@/views/MessagesView.vue'),
    meta: {
      title: 'Abler Omnichannel - Chaty',
      breadcrumb: [
        { label: 'Strona główna', path: '/', parent: null },
        { label: 'Chat', path: '/chat', parent: 'Root' }
      ],

      routeName: 'Przegląd pokoi chatu',
      metaTags: [
        {
          name: 'description',
          content: 'Strona zawiera chaty użytkownika z innymi użytkownikami aplikacji Abler Omnichannel.'
        },
        {
          property: 'og:description',
          content: 'Strona zawiera chaty użytkownika z innymi użytkownikami aplikacji Abler Omnichannel.'
        }
      ]
    },
    beforeEnter: (to, _, next) => onBeforeRouteCheck(to, _, next, userRoleEnum.USER)
  },
  {
    path: '/chat-room',
    name: 'Chat room',
    props: (route) => ({ ...route.params, chatRoomId: route.query.chatRoomId, topic: route.query.topic, currentStatus: route.query.currentStatus }),
    component: () => import(/* webpackChunkName: "ChatRoomView" */ '@/views/ChatRoomView.vue'),
    meta: {
      title: 'Abler Omnichannel - Chat',
      breadcrumb: [
        { label: 'Strona główna', path: '/', parent: null },
        { label: 'Chat', path: '/chat', parent: 'Root' },
        { label: 'Chat room', path: '/chat-room', parent: 'Chat' }
      ],

      routeName: 'Pokój czatu',
      metaTags: [
        {
          name: 'description',
          content:
            'Strona pozwala na konwersację z użytkownikami aplikacji Abler Omnichannel przy wykorzystaniu komunikatora.'
        },
        {
          property: 'og:description',
          content:
            'Strona pozwala na konwersację z użytkownikami aplikacji Abler Omnichannel przy wykorzystaniu komunikatora.'
        }
      ]
    },
    beforeEnter: (to, _, next) => onBeforeRouteCheck(to, _, next, userRoleEnum.USER)
  },
  {
    path: '/cutting-boards',
    name: 'Cutting boards',
    component: () => import(/* webpackChunkName: "CuttingBoardsView" */ '@/views/CuttingBoardsView.vue'),
    meta: {
      title: 'Abler Omnichannel - Rozkrój',
      breadcrumb: [
        { label: 'Strona główna', path: '/', parent: null },
        { label: 'Rozkrój płyt', path: '/cutting-boards', parent: 'Root' }
      ],
      routeName: 'Rozkrój płyt',
      metaTags: [
        {
          name: 'description',
          content: 'Strona pozwala na rozkrój materiałów.'
        },
        {
          property: 'og:description',
          content: 'Strona pozwala na rozkrój materiałów.'
        }
      ]
    },
    beforeEnter: (to, _, next) => onBeforeRouteCheck(to, _, next, userRoleEnum.USER)
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: () => import(/* webpackChunkName: "FaqClientView" */ '@/admin_panel/FaqClientView.vue'),
    meta: {
      title: 'Abler Omnichannel - FAQ',
      breadcrumb: [
        { label: 'Strona główna', path: '/', parent: null },
        { label: 'FAQ', path: '/faq', parent: 'Root' }
      ],

      routeName: 'Najczęściej zadawane pytania',
      metaTags: [
        {
          name: 'description',
          content: 'Strona zawiera najczęściej zadawane pytania.'
        },
        {
          property: 'og:description',
          content: 'Strona zawiera najczęściej zadawane pytania.'
        }
      ]
    },
    beforeEnter: (to, _, next) => onBeforeRouteCheck(to, _, next, userRoleEnum.USER)
  },
  {
    path: '/downloads',
    name: 'Downloads',
    component: () => import(/* webpackChunkName: "DownloadsView" */ '@/views/DownloadsView.vue'),
    meta: {
      title: 'Abler Omnichannel - Do pobrania',
      breadcrumb: [
        { label: 'Strona główna', path: '/', parent: null },
        { label: 'Do pobrania', path: '/downloads', parent: 'Root' }
      ],

      routeName: 'Przegląd dokumentów do pobrania',
      metaTags: [
        {
          name: 'description',
          content: 'Strona zawiera dokumenty dostępne do pobrania w aplikacji.'
        },
        {
          property: 'og:description',
          content: 'Strona zawiera dokumenty dostępne do pobrania w aplikacji.'
        }
      ]
    },
    beforeEnter: (to, _, next) => onBeforeRouteCheck(to, _, next, userRoleEnum.USER)
  },
  {
    path: '/regulations-config',
    name: 'Regulations config',
    component: () => import(/* webpackChunkName: "RegulationsConfigView" */ '@/admin_panel/RegulationsConfigView.vue'),
    meta: {
      protect: true,
      title: 'Abler Omnichannel - Konfiguracja dokumentów',
      breadcrumb: [
        { label: 'Strona główna', path: '/', parent: null },
        { label: 'Do pobrania', path: '/downloads', parent: 'Root' },
        { label: 'Konfiguracja plików', path: '/regulations-config', parent: 'Do pobrania' }
      ],

      routeName: 'Konfiguracja plików',
      metaTags: [
        {
          name: 'description',
          content: 'Strona umożliwia konfigurację dokumentów dostępnych w aplikacji Abler Omnichannel.'
        },
        {
          property: 'og:description',
          content: 'Strona umożliwia konfigurację dokumentów dostępnych w aplikacji Abler Omnichannel.'
        }
      ]
    },
    beforeEnter: (to, _, next) => onBeforeRouteCheck(to, _, next, userRoleEnum.ADMIN)
  },
  {
    path: '/loyalty-program',
    name: 'Loyalty program',
    component: () => import(/* webpackChunkName: "LoyaltyProgramView" */ '@/views/LoyaltyProgramView.vue'),
    meta: {
      title: 'Abler Omnichannel - Program lojalnościowy',
      breadcrumb: [
        { label: 'Strona główna', path: '/', parent: null },
        { label: 'Program lojalnościowy', path: '/loyalty-program', parent: 'Root' }
      ],
      routeName: 'Program lojalnościowy',
      metaTags: [
        {
          name: 'description',
          content: 'Strona zawiera nagrody dostępne w programie lojalnościowym.'
        },
        {
          property: 'og:description',
          content: 'Strona zawiera nagrody dostępne w programie lojalnościowym.'
        }
      ]
    },
    beforeEnter: (to, _, next) => onBeforeRouteCheck(to, _, next, userRoleEnum.USER)
  },
  {
    path: '/loyalty-program-config',
    name: 'Loyalty program config',
    component: () => import(/* webpackChunkName: "LoyaltyProgramConfigView" */ '@/admin_panel/LoyaltyProgramConfigView.vue'),
    meta: {
      protect: true,
      title: 'Abler Omnichannel - Konfiguracja programu lojalnościowego',
      breadcrumb: [
        { label: 'Strona główna', path: '/', parent: null },
        { label: 'Program lojalnościowy', path: '/loyalty-program', parent: 'Root' },
        { label: 'Edycja programu', path: '/loyalty-program-config', parent: 'Loyalty' }
      ],

      routeName: 'Konfiguracja programu lojalnościowego',
      metaTags: [
        {
          name: 'description',
          content: 'Strona umożliwia dodawanie i edycję produktów programu lojalnościowego.'
        },
        {
          property: 'og:description',
          content: 'Strona umożliwia dodawanie i edycję produktów programu lojalnościowego.'
        }
      ]
    },
    beforeEnter: (to, _, next) => onBeforeRouteCheck(to, _, next, userRoleEnum.ADMIN)
  },
  {
    path: '/loyalty-program-categories-config',
    name: 'Loyalty program categories config',
    component: () => import(/* webpackChunkName: "LoyaltyProgramCategoriesConfigView" */ '@/admin_panel/LoyaltyProgramCategoriesConfigView.vue'),
    meta: {
      protect: true,
      title: 'Abler Omnichannel - Konfiguracja kategorii programu lojalnościowego',
      breadcrumb: [
        { label: 'Strona główna', path: '/', parent: null },
        { label: 'Program lojalnościowy', path: '/loyalty-program', parent: 'Root' },
        { label: 'Edycja kategorii', path: '/loyalty-program-categories-config', parent: 'Loyalty' }
      ],
      routeName: 'Konfiguracja kategorii',
      metaTags: [
        {
          name: 'description',
          content: 'Strona umożliwia dodawanie i edycję kategorii programu lojalnościowego.'
        },
        {
          property: 'og:description',
          content: 'Strona umożliwia dodawanie i edycję kategorii programu lojalnościowego.'
        }
      ]
    },
    beforeEnter: (to, _, next) => onBeforeRouteCheck(to, _, next, userRoleEnum.ADMIN)
  },
  {
    path: '/loyalty-program-item-config',
    name: 'Loyalty program item config',
    props: (route) => ({ ...route.params, prizeId: route.query.prizeId }),
    component: () => import(/* webpackChunkName: "LoyaltyProgramItemConfigView" */ '@/admin_panel/LoyaltyProgramItemConfigView.vue'),
    meta: {
      protect: true,
      title: 'Abler Omnichannel - Konfiguracja produktu programu lojalnościowego',
      breadcrumb: [
        { label: 'Edycja programu', path: '/loyalty-program-config', parent: 'Root' },
        { label: 'Dodaj nagrodę', path: '/loyalty-program-item-config', parent: 'Loyalty' }
      ],
      routeName: 'Konfiguracja produktu',
      metaTags: [
        {
          name: 'description',
          content: 'Strona umożliwia dodawanie i edycję produktu programu lojalnościowego.'
        },
        {
          property: 'og:description',
          content: 'Strona umożliwia dodawanie i edycję produktu programu lojalnościowego.'
        }
      ]
    },
    beforeEnter: (to, _, next) => onBeforeRouteCheck(to, _, next, userRoleEnum.ADMIN)
  },
  {
    path: '/loyalty-program-orders',
    name: 'Loyalty program orders',
    component: () => import(/* webpackChunkName: "LoyaltyProgramOrdersView" */ '@/views/LoyaltyProgramOrdersView.vue'),
    meta: {
      title: 'Abler Omnichannel - Zamówienia programu lojalnościowego',
      breadcrumb: [
        { label: 'Strona główna', path: '/', parent: null },
        { label: 'Program lojalnościowy', path: '/loyalty-program', parent: 'Root' },
        { label: 'Zamówione nagrody', path: '/loyalty-program-orders', parent: 'Loyalty' }
      ],
      routeName: 'Przegląd zamówień',
      metaTags: [
        {
          name: 'description',
          content: 'Strona zawiera zamówienia programu lojalnościowego.'
        },
        {
          property: 'og:description',
          content: 'Strona zawiera zamówienia programu lojalnościowego.'
        }
      ]
    },
    beforeEnter: (to, _, next) => onBeforeRouteCheck(to, _, next, userRoleEnum.USER)
  },
  {
    path: '/newsletter',
    name: 'Newsletter',
    component: () => import(/* webpackChunkName: "NewsletterView" */ '@/views/NewsletterView.vue'),
    meta: {
      title: 'Abler Omnichannel - Zapis do newslettera',
      breadcrumb: [
        { label: 'Strona główna', path: '/', parent: null },
        { label: 'Newsletter', path: '/newsletter', parent: 'Root' }
      ],
      routeName: 'Dołącz do newslettera',
      metaTags: [
        {
          name: 'description',
          content: 'Strona umożliwia zapis do newslettera.'
        },
        {
          property: 'og:description',
          content: 'Strona umożliwia zapis do newslettera.'
        }
      ]
    },
    beforeEnter: (to, _, next) => onBeforeRouteCheck(to, _, next, userRoleEnum.USER)
  },
  {
    path: '/newsletter-regulations',
    name: 'Newsletter regulations',
    component: () => import(/* webpackChunkName: "NewsletterRegulationsConfigView" */ '@/admin_panel/NewsletterRegulationsConfigView.vue'),
    meta: {
      protect: true,
      title: 'Abler Omnichannel - Konfiguracja regulaminów newslettera',
      breadcrumb: [
        { label: 'Strona główna', path: '/', parent: null },
        { label: 'Newsletter', path: '/newsletter', parent: 'Root' },
        { label: 'Zgody', path: '/newsletter-regulations', parent: 'Newsletter' }
      ],

      routeName: 'Modyfikacja zgód',
      metaTags: [
        {
          name: 'description',
          content: 'Strona umożliwia konfigurację regulaminów koniecznych do zaakceptowania newslettera.'
        },
        {
          property: 'og:description',
          content: 'Strona umożliwia konfigurację regulaminów koniecznych do zaakceptowania newslettera.'
        }
      ]
    },
    beforeEnter: (to, _, next) => onBeforeRouteCheck(to, _, next, userRoleEnum.ADMIN)
  },
  {
    path: '/faq-configuration',
    name: 'FAQ config',
    component: () => import(/* webpackChunkName: "faqConfigView" */ '@/admin_panel/FaqConfigView.vue'),
    meta: {
      protect: true,
      title: 'Abler Omnichannel - Konfiguracja FAQ',
      breadcrumb: [
        { label: 'Strona główna', path: '/', parent: null },
        { label: 'FAQ', path: '/faq', parent: 'Root' },
        { label: 'Edycja FAQ', path: '/faq-configuration', parent: 'Faq' }
      ],

      routeName: 'Konfiguracja FAQ',
      metaTags: [
        {
          name: 'description',
          content: 'Strona umożliwia konfigurację najczęściej zadawanych pytań.'
        },
        {
          property: 'og:description',
          content: 'Strona umożliwia konfigurację najczęściej zadawanych pytań.'
        }
      ]
    },
    beforeEnter: (to, _, next) => onBeforeRouteCheck(to, _, next, userRoleEnum.ADMIN)
  },
  {
    path: '/newsletter-configuration',
    name: 'Newsletter config',
    component: () => import(/* webpackChunkName: "NewsletterConfigView" */ '@/admin_panel/NewsletterConfigView.vue'),
    meta: {
      protect: true,
      title: 'Abler Omnichannel - Konfiguracja wysyłanych newsletterów',
      breadcrumb: [
        { label: 'Strona główna', path: '/', parent: null },
        { label: 'Newsletter', path: '/newsletter', parent: 'Root' },
        { label: 'Tworzenie newslettera', path: '/newsletter-configuration', parent: 'Newsletter' }
      ],
      routeName: 'Konfiguracja',
      metaTags: [
        {
          name: 'description',
          content: 'Strona umożliwia konfigurację i wysyłanie newsletterów do użytkowników aplikacji.'
        },
        {
          property: 'og:description',
          content: 'Strona umożliwia konfigurację i wysyłanie newsletterów do użytkowników aplikacji.'
        }
      ]
    },
    beforeEnter: (to, _, next) => onBeforeRouteCheck(to, _, next, userRoleEnum.ADMIN)
  },
  {
    path: '/newsletter-sign-off-successful',
    name: 'Newsletter Sign Off',
    component: () => import(/* webpackChunkName: "NewsletterSignOff" */ '@/components/NewsletterSignOff.vue'),
    meta: {
      protect: true,
      title: 'Abler Omnichannel - Wypisano z newslettera',
      breadcrumb: [
        { label: 'Strona główna', path: '/', parent: null },
        { label: 'Wypisanie', path: '/newsletter-sign-off-successful', parent: 'Root' }
      ],
      routeName: 'Wypisano z neslettera',
      metaTags: [
        {
          name: 'description',
          content: 'Strona informuje o wypisaniu z newslettera.'
        },
        {
          property: 'og:description',
          content: 'Strona informuje o wypisaniu z newslettera.'
        }
      ]
    },
    beforeEnter: (to, _, next) => onBeforeRouteCheck(to, _, next, userRoleEnum.ADMIN)
  },
  {
    path: '/newsletter-template',
    name: 'Newsletter template',
    component: () => import(/* webpackChunkName: "NewsletterTemplate" */ '@/admin_panel/NewsletterTemplate.vue'),
    meta: {
      protect: true,
      title: 'Abler Omnichannel - Szablony newsletterów',
      breadcrumb: [
        { label: 'Strona główna', path: '/', parent: null },
        { label: 'Newsletter', path: '/newsletter', parent: 'Root' },
        { label: 'Szablon newslettera', path: '/newsletter-template', parent: 'Newsletter' }
      ],
      routeName: 'Modyfikacja szablonu',
      metaTags: [
        {
          name: 'description',
          content: 'Strona umożliwia tworzenie i zapis szablonów newslettera.'
        },
        {
          property: 'og:description',
          content: 'Strona umożliwia tworzenie i zapis szablonów newslettera.'
        }
      ]
    },
    beforeEnter: (to, _, next) => onBeforeRouteCheck(to, _, next, userRoleEnum.ADMIN)
  },
  {
    path: '/newsletter-categories-configuration',
    name: 'Newsletter categories config',
    component: () => import(/* webpackChunkName: "NewsletterCategoriesConfigView" */ '@/admin_panel/NewsletterCategoriesConfigView.vue'),
    meta: {
      protect: true,
      title: 'Abler Omnichannel - Konfiguracja kategorii newsletterów',
      breadcrumb: [
        { label: 'Strona główna', path: '/', parent: null },
        { label: 'Newsletter', path: '/newsletter', parent: 'Root' },
        { label: 'Zarządzanie kategoriami', path: '/newsletter-categories-configuration', parent: 'Newsletter' }
      ],
      routeName: 'Zarządzanie kategoriami',
      metaTags: [
        {
          name: 'description',
          content: 'Strona umożliwia tworzenie i edycję kategorii newsletterów.'
        },
        {
          property: 'og:description',
          content: 'Strona umożliwia tworzenie i edycję kategorii newsletterów.'
        }
      ]
    },
    beforeEnter: (to, _, next) => onBeforeRouteCheck(to, _, next, userRoleEnum.ADMIN)
  },
  {
    path: '/orders',
    name: 'Orders',
    component: () => import(/* webpackChunkName: "OrdersView" */ '@/views/OrdersView.vue'),
    meta: {
      title: 'Abler Omnichannel - Zamówienia',
      breadcrumb: [
        { label: 'Strona główna', path: '/', parent: null },
        { label: 'Zamówienia', path: '/orders', parent: 'Root' }
      ],
      routeName: 'Zamówienia',
      metaTags: [
        {
          name: 'description',
          content: 'Strona zawiera zamówienia użytkownika.'
        },
        {
          property: 'og:description',
          content: 'Strona zawiera zamówienia użytkownika.'
        }
      ]
    },
    beforeEnter: (to, _, next) => onBeforeRouteCheck(to, _, next, userRoleEnum.USER)
  },
  {
    path: '/account',
    name: 'Account',
    component: () => import(/* webpackChunkName: "AccountView" */ '@/views/AccountView.vue'),
    meta: {
      title: 'Abler Omnichannel - Ustawienia konta',
      breadcrumb: [
        { label: 'Strona główna', path: '/', parent: null },
        { label: 'O mnie', path: '/account', parent: 'Root' }
      ],
      routeName: 'Ustawienia konta',
      metaTags: [
        {
          name: 'description',
          content: 'Strona zawiera informacje dotyczące użytkownika.'
        },
        {
          property: 'og:description',
          content: 'Strona zawiera informacje dotyczące użytkownika.'
        }
      ]
    },
    beforeEnter: (to, _, next) => onBeforeRouteCheck(to, _, next, userRoleEnum.USER)
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "AboutView" */ '@/views/AboutView.vue')
  },
  {
    path: '/products',
    name: 'Products',
    props: (route) => ({ ...route.params, start: route.query.from, pathQuery: route.query.pathPhrase, pathId: route.query.pathId, end: route.query.to, rows: route.query.rows, phrase: route.query.phrase, expanded: route.query.expanded, waterResistance: route.query.waterResistance, lock: route.query.lock, spacing: route.query.spacing, color: route.query.color, thickness: route.query.thickness, abrasionClass: route.query.abrasionClass, headShape: route.query.headShape, socket: route.query.socket, profile: route.query.profile, length: route.query.length, antibacterial: route.query.antibacterial, collection: route.query.collection, thread: route.query.thread, type: route.query.type, structure: route.query.structure, moistureResistance: route.query.moistureResistance, openingAngle: route.query.openingAngle, material: route.query.material, diameter: route.query.diameter, kit: route.query.kit, width: route.query.width, producer: route.query.producer, bearingCapacity: route.query.bearingCapacity, silentClosing: route.query.silentClosing, height: route.query.height, colorOfLight: route.query.colorOfLight, powerFactor: route.query.powerFactor, hiddenWiring: route.query.hiddenWiring, symbol: route.query.symbol, intendedUse: route.query.intendedUse, guaranteePeriod: route.query.guaranteePeriod, sort: route.query.sort }),
    component: () => import(/* webpackChunkName: "ProductsView" */ '@/views/ProductsView.vue'),
    meta: {
      title: 'Abler Omnichannel - Wszystkie produkty',
      breadcrumb: [
        { label: 'Strona główna', path: '/', parent: null },
        { label: 'Produkty', path: '/products', parent: 'Root' }
      ],
      routeName: 'Katalog produktów',
      metaTags: [
        {
          name: 'description',
          content: 'Strona zawiera produkty dostępne w aplikacji Abler Omnichannel.'
        },
        {
          property: 'og:description',
          content: 'Strona zawiera produkty dostępne w aplikacji Abler Omnichannel.'
        }
      ]
    },
    beforeEnter: async (to, _, next) => {
      await store.dispatch('productStore/setSorting',
        {
          label: 'Sortowanie domyślne',
          value: { name: 'default', order: 'desc' }
        })
      onBeforeRouteCheck(to, _, next, userRoleEnum.USER)
    }
  },
  {
    path: '/product/:id',
    name: 'ProductDetails',
    props: true,
    component: () => import(/* webpackChunkName: "ProductDetailsView" */ '@/views/ProductDetailsView.vue'),
    meta: {
      title: 'Abler Omnichannel - Detale produktu',
      breadcrumb: [
        { label: 'Strona główna', path: '/', parent: null },
        { label: 'Produkty', path: '/products', parent: 'Root' },
        { label: 'Produkt', path: '#', parent: 'Produkty' }
      ],
      routeName: 'Informacje o produkcie',
      metaTags: [
        {
          name: 'description',
          content: 'Strona zawiera detale wybranego produktu.'
        },
        {
          property: 'og:description',
          content: 'Strona zawiera detale wybranego produktu.'
        }
      ]
    },
    beforeEnter: async (to, _, next) => onBeforeRouteCheck(to, _, next, userRoleEnum.ADMIN)
  },
  {
    path: '/faqClientView',
    name: 'faqClientView',
    component: () => import(/* webpackChunkName: "FaqClientView" */ '@/admin_panel/FaqClientView.vue'),
    meta: {
      title: 'Abler Omnichannel - FAQ',
      breadcrumb: [
        { label: 'Strona główna', path: '/', parent: null },
        { label: 'FAQ', path: '/faq', parent: 'Root' },
        { label: 'Edycja FAQ', path: '/faqClientView', parent: 'Faq' }
      ],
      routeName: 'Najczęściej zadawane pytania',
      metaTags: [
        {
          name: 'description',
          content: 'Strona zawiera najczęściej zadawane pytania.'
        },
        {
          property: 'og:description',
          content: 'Strona zawiera najczęściej zadawane pytania.'
        }
      ]
    },
    beforeEnter: (to, _, next) => onBeforeRouteCheck(to, _, next, userRoleEnum.USER)
  },
  {
    path: '/cart-preview',
    name: 'CartPreview',
    component: () => import(/* webpackChunkName: "CartPreviewVue" */ '@/views/CartPreviewView.vue'),
    meta: {
      protect: true,
      title: 'Abler Omnichannel - Podgląd koszyków',
      routeName: 'Widok podlądu koszyków',
      breadcrumb: [
        { label: 'Strona główna', path: '/', parent: null },
        { label: 'Podgląd koszyków', path: '/cart-preview', parent: 'Root' }
      ],
      metaTags: [
        {
          name: 'description',
          content: 'Strona zawiera informacje o koszykach przypisanych w ramach kontraktora'
        },
        {
          property: 'og:description',
          content: 'Strona zawiera informacje o koszykach przypisanych w ramach kontraktora'
        }
      ]
    },
    beforeEnter: (to, _, next) => onBeforeRouteCheck(to, _, next, userRoleEnum.ADMIN)
  },
  {
    path: '/shopping-cart',
    name: 'Shopping Cart',
    component: () => import(/* webpackChunkName: "ShoppingCartView" */ '@/views/ShoppingCartView.vue'),
    children: [
      {
        path: '',
        name: 'shopping cart',
        component: () => import(/* webpackChunkName: "ShoppingCartStepOne" */ '@/components/shopping_cart/step_one/ShoppingCartStepOne'),
        meta: {
          title: 'Abler Omnichannel - Koszyk- Pierwszy krok',
          routeName: 'Krok 1 - sprawdź koszyk',
          breadcrumb: [
            { label: 'Strona główna', path: '/', parent: null },
            { label: 'Koszyk', path: '/shopping-cart', parent: 'Root' },
            { label: 'Pierwszy krok', path: '/shopping-cart', parent: 'Root' }
          ],
          metaTags: [
            {
              name: 'description',
              content: 'Strona zawiera koszyki dostępne dla użytkownika.'
            },
            {
              property: 'og:description',
              content: 'Strona zawiera koszyki dostępne dla użytkownika.'
            }
          ]
        },
        beforeEnter: (to, _, next) => onBeforeRouteCheck(to, _, next, userRoleEnum.USER)
      },
      {
        path: 'shopping-invoice',
        name: 'Shopping Invoice',
        props: (route) => ({ ...route.params, orderType: route.query.orderType }),
        component: () => import(/* webpackChunkName: "ShoppingCartStepTwo" */ '@/components/shopping_cart/step_two/ShoppingCartStepTwo'),
        meta: {
          title: 'Abler Omnichannel - koszyk krok 2',
          routeName: 'Krok 2 - wybierz opcje płatności i dostawy',
          breadcrumb: [
            { label: 'Strona główna', path: '/', parent: null },
            { label: 'Koszyk', path: '/shopping-cart', parent: 'Root' },
            { label: 'Drugi krok', path: '/shopping-cart/shopping-invoice', parent: 'Koszyk' }
          ],
          metaTags: [
            {
              name: 'description',
              content: 'Strona zawiera koszyki dostępne dla użytkownika.'
            },
            {
              property: 'og:description',
              content: 'Strona zawiera koszyki dostępne dla użytkownika.'
            }
          ]
        },
        beforeEnter: (to, _, next) => onBeforeRouteCheck(to, _, next, userRoleEnum.ADMIN)
      },
      {
        path: 'shopping-summary',
        name: 'Shopping summary',
        component: () => import(/* webpackChunkName: "ShoppingCartStepThree" */ '@/components/shopping_cart/step_three/ShoppingCartStepThree'),
        meta: {
          title: 'Abler Omnichannel - podsumowanie',
          routeName: 'Podsumowanie zamówienia'
        }
      }
    ],
    meta: {
      title: 'Abler Omnichannel - Koszyk',
      routeName: 'Koszyk',
      metaTags: [
        {
          name: 'description',
          content: 'Strona zawiera koszyki dostępne dla użytkownika.'
        },
        {
          property: 'og:description',
          content: 'Strona zawiera koszyki dostępne dla użytkownika.'
        }
      ]
    }
  },
  {
    path: '/transaction-status',
    name: 'Status of the transaction',
    props: (route) => ({ ...route.params, basketId: route.query.orderId, invoiceId: route.query.invoiceId, onlinePaymentId: route.query.onlinePaymentId, isPreview: route.query.isPreview }),
    component: () => import(/* webpackChunkName: "TransactionStatusView" */ '@/views/TransactionStatusView'),
    meta: {
      title: 'Abler Omnichannel - Status transakcji',
      breadcrumb: [
        { label: 'Strona główna', path: '/', parent: null },
        { label: 'Status transakcji', path: '#', parent: 'Root' }
      ],
      routeName: 'Status transakcji',
      metaTags: [
        {
          name: 'description',
          content: 'Strona informuje o aktualnym statusie transakcji.'
        },
        {
          property: 'og:description',
          content: 'Strona informuje o aktualnym statusie transakcji.'
        }
      ]
    },
    beforeEnter: (to, _, next) => onBeforeRouteCheck(to, _, next, userRoleEnum.USER)
  },
  {
    path: '/resume-payment',
    name: 'Status of the Payment',
    props: (route) => ({ ...route.params, basketId: route.query.orderId, invoiceId: route.query.invoiceId, resumePayment: route.query.resumePayment, onlinePaymentId: route.query.onlinePaymentId }),
    component: () => import(/* webpackChunkName: "ResumePaymentView" */ '@/views/ResumePaymentView.vue'),
    meta: {
      title: 'Abler Omnichannel - Status transakcji',
      breadcrumb: [
        { label: 'Strona główna', path: '/', parent: null },
        { label: 'Status transakcji', path: '#', parent: 'Root' }
      ],
      routeName: 'Status transakcji',
      metaTags: [
        {
          name: 'description',
          content: 'Strona informuje o aktualnym statusie transakcji.'
        },
        {
          property: 'og:description',
          content: 'Strona informuje o aktualnym statusie transakcji.'
        }
      ]
    },
    beforeEnter: (to, _, next) => onBeforeRouteCheck(to, _, next, userRoleEnum.USER)
  },
  {
    path: '/resume-invoice',
    name: 'Status of the Invoice',
    props: (route) => ({ ...route.params, invoiceId: route.query.invoiceId, basketId: route.query.orderId, resumePayment: route.query.resumePayment, onlinePaymentId: route.query.onlinePaymentId }),
    component: () => import(/* webpackChunkName: "ResumeInvoicesView" */ '@/views/ResumeInvoiceView.vue'),
    meta: {
      title: 'Abler Omnichannel - Status transakcji',
      breadcrumb: [
        { label: 'Strona główna', path: '/', parent: null },
        { label: 'Status transakcji', path: '#', parent: 'Root' }
      ],
      routeName: 'Status transakcji',
      metaTags: [
        {
          name: 'description',
          content: 'Strona informuje o aktualnym statusie transakcji.'
        },
        {
          property: 'og:description',
          content: 'Strona informuje o aktualnym statusie transakcji.'
        }
      ]
    },
    beforeEnter: (to, _, next) => onBeforeRouteCheck(to, _, next, userRoleEnum.USER)
  },
  {
    path: '/external-transaction-status',
    name: 'Status of the external payment',
    props: (route) => ({ ...route.params, invoiceId: route.query.invoiceId }),
    component: () => import(/* webpackChunkName: "ExternalTransactionStatusView" */ '@/views/ExternalTransactionStatusView'),
    meta: {
      title: 'Abler Omnichannel - Status płatności',
      breadcrumb: [
        { label: 'Strona główna', path: '/', parent: null },
        { label: 'Status płatności', path: '#', parent: 'Root' }
      ],
      routeName: 'Status płatności',
      metaTags: [
        {
          name: 'description',
          content: 'Strona informuje o aktualnym statusie płatności zewnętrznych faktur.'
        },
        {
          property: 'og:description',
          content: 'Strona informuje o aktualnym statusie płatności zewnętrznych faktur.'
        }
      ]
    },
    beforeEnter: (to, _, next) => onBeforeRouteCheck(to, _, next, userRoleEnum.USER)
  },
  {
    path: '/resume-payment-status',
    name: 'Status of the resume payment',
    props: (route) => ({ ...route.params, basketId: route.query.orderId }),
    component: () => import(/* webpackChunkName: "ResumePaymentStatusView" */ '@/views/ResumePaymentStatusView'),
    meta: {
      title: 'Abler Omnichannel - Status ponownej płatności',
      breadcrumb: [
        { label: 'Strona główna', path: '/', parent: null },
        { label: 'Status ponownej płatności', path: '#', parent: 'Root' }
      ],
      routeName: 'Status ponownej płatności',
      metaTags: [
        {
          name: 'description',
          content: 'Strona informuje o aktualnym statusie ponownej płatności.'
        },
        {
          property: 'og:description',
          content: 'Strona informuje o aktualnym statusie ponownej płatności.'
        }
      ]
    },
    beforeEnter: (to, _, next) => onBeforeRouteCheck(to, _, next, userRoleEnum.USER)
  },
  {
    path: '/regulations/:regulationId',
    name: 'regulations',
    props: true,
    component: () => import(/* webpackChunkName: "Regulations" */ '@/views/Regulations'),
    meta: {
      title: 'Abler Omnichannel - Regulamin',
      breadcrumb: [
        { label: 'Strona główna', path: '/', parent: null },
        { label: 'Regulamin', path: '#', parent: 'Root' }
      ],
      routeName: 'Informacje o regulaminie',
      metaTags: [
        {
          name: 'description',
          content: 'Strona zawiera informacje o regulaminie.'
        },
        {
          property: 'og:description',
          content: 'Strona zawiera informacje o regulaminie.'
        }
      ]
    },
    beforeEnter: (to, _, next) => onBeforeRouteCheck(to, _, next, userRoleEnum.ADMIN)
  },
  {
    path: '/regulations/config',
    name: 'RegulationsConfig',
    component: () => import(/* webpackChunkName: "RegulationsSiteConfigView" */ '@/views/RegulationsSiteConfigView.vue'),
    meta: {
      protect: true,
      title: 'Abler Omnichannel - Konfiguracja Stopki',
      breadcrumb: [
        { label: 'Strona główna', path: '/', parent: null },
        { label: 'Regulamin', path: '/regulations/config', parent: 'Root' }
      ],
      routeName: 'Konfiguracja regulaminów',
      metaTags: [
        {
          name: 'description',
          content: 'Strona umożliwia konfigurację elementów stopki.'
        },
        {
          property: 'og:description',
          content: 'Strona umożliwia konfigurację elementów stopki.'
        }
      ]
    },
    beforeEnter: (to, _, next) => onBeforeRouteCheck(to, _, next, userRoleEnum.ADMIN)
  },
  {
    path: '/invoices_management',
    name: 'InvoicesManagement',
    component: () => import(/* webpackChunkName: "InvoicesManagementView" */ '@/views/InvoicesManagementView.vue'),
    props: (route) => ({ invoiceId: route.query.invoiceId, activeId: route.query.activeId }),
    meta: {
      title: 'Abler Omnichannel - Zarządzanie fakturami',
      breadcrumb: [
        { label: 'Strona główna', path: '/', parent: null },
        { label: 'Faktury', path: '/invoices_management', parent: 'Root' }
      ],
      routeName: 'Zarządzanie fakturami',
      metaTags: [
        {
          name: 'description',
          content: 'Strona umożliwia zarządzanie fakturami.'
        },
        {
          property: 'og:description',
          content: 'Strona umożliwia zarządzanie fakturami.'
        }
      ]
    },
    beforeEnter: (to, _, next) => onBeforeRouteCheck(to, _, next, userRoleEnum.ADMIN)
  },
  {
    path: '/departments_management',
    name: 'Departments Management',
    component: () => import(/* webpackChunkName: "DepartmentsConfigView" */ '@/admin_panel/DepartmentsConfigView.vue'),
    meta: {
      protect: true,
      title: 'Abler Omnichannel - Modyfikacja oddziałów',
      breadcrumb: [
        { label: 'Strona główna', path: '/', parent: null },
        { label: 'Modyfikacja oddziałów', path: '/departments_management', parent: 'Root' }
      ],
      routeName: 'Modyfikacja oddziałów',
      metaTags: [
        {
          name: 'description',
          content: 'Strona umożliwia zarządzanie listą oddziałów.'
        },
        {
          property: 'og:description',
          content: 'Strona umożliwia zarządzanie listą oddziałów.'
        }
      ]
    },
    beforeEnter: (to, _, next) => onBeforeRouteCheck(to, _, next, userRoleEnum.SUPER_ADMIN)
  },
  {
    path: '/logs',
    name: 'Logs',
    component: () => import(/* webpackChunkName: "LogView" */ '@/admin_panel/LogView.vue'),
    meta: {
      protect: true,
      title: 'Abler Omnichannel - Widok logów',
      breadcrumb: [
        { label: 'Strona główna', path: '/', parent: null },
        { label: 'Widok logów', path: '/logs', parent: 'Root' }
      ],
      routeName: 'Widok logów',
      metaTags: [
        {
          name: 'description',
          content: 'Strona umożliwia monitorowanie listy logów.'
        },
        {
          property: 'og:description',
          content: 'Strona umożliwia monitorowanie listy logów.'
        }
      ]
    },
    beforeEnter: (to, _, next) => onBeforeRouteCheck(to, _, next, userRoleEnum.ADMIN)
  },
  {
    path: '/register',
    name: 'RegisterLogin',
    component: () => import(/* webpackChunkName: "RegisterLoginView" */ '@/views/RegisterLoginView.vue'),
    meta: {
      title: 'Abler Omnichannel - Rejestracja/Logowanie użytkownika',
      routeName: 'Rejestracja i logowanie użytkownika',
      metaTags: [
        {
          name: 'description',
          content: 'Strona umożliwia rejestracje i logowanie użytkownika.'
        }
      ]
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title)

  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags)

  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags)

  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title
  }

  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map((el) => el.parentNode.removeChild(el))

  if (!nearestWithMeta) return next()

  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement('meta')

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key])
      })

      tag.setAttribute('data-vue-router-controlled', '')

      return tag
    })
    .forEach((tag) => document.head.appendChild(tag))

  next()
})

export default router
