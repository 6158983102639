import { RegulationsFileModel } from '@/models/regulationsFileModel'

export class RegulationsCategoryModel {
  constructor (regulationsData = {}) {
    this.id = regulationsData.id
    this.title = regulationsData.title
    this.content = regulationsData.content
    this.regulationCategory = regulationsData.regulationCategory
    this.isAccepted = regulationsData.isAccepted ? regulationsData.isAccepted : false
    this.files = this.parseFiles(regulationsData.files)
  }

  parseFiles (files) {
    const filesList = []
    for (const file of files) {
      filesList.push(new RegulationsFileModel(file))
    }
    return filesList
  }
}
