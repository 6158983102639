<template>
  <div class="basketItemWrapper p-d-flex p-dir-col p-jc-between p-ai-start">
    <p class="product-name link-wrapper" @click="handleGoToProduct(product.commodity.id)">{{ product.commodity.name }}</p>
    <div class="p-d-flex p-flex-row p-jc-between p-ai-center" style="width: 100%">
      <div class="firstItemSection">
        <div class="p-d-flex p-flex-row">
          <span>Ilość:</span>
          <p class="p-ml-1 amountButton" @click="openModal(product)">{{ product.quantity }} {{ product.commodity.baseUnit }}.</p>
        </div>
      </div>
      <div class="p-d-flex p-dir-row p-dir-row p-ai-center">
        <h4>{{ priceStatus ? product.commodity.discountNet : product.commodity.net }}zł/szt. <span style='font-weight: 400'>netto</span></h4>
        <Button v-tooltip.top="'Usuń'" class="p-button-text p-button-rounded"  @click="toggleRemoveCarts">
          <CustomIcon width="20" height="21" viewBox="0 0 20 21" iconColor="#161614" iconName="ikona usuwania"><trashcan-icon/></CustomIcon>
        </Button>
      </div>
    </div>
  </div>
  <Dialog v-if="displayModal" :header='currentCommodity.name' v-model:visible="displayModal" :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{ 'max-width': '600px', width: '40vw'}" :modal="true">
    <PriceDetailsModal :isChangeQuantity="true" :id="currentCommodity.id" :quantityItem="currentQuantity" @closeModal="closeModal" />
    <template #footer>
      <Button label="Zamknij okno" icon="pi pi-times" @click="closeModal" class="p-button-text"/>
    </template>
  </Dialog>
  <ConfirmationModal
    :text="'Czy jesteś pewien, że chcesz usunąć ' + product.commodity.name + '?'"
    v-model:visible="removeCarts"
    :noPressed="toggleRemoveCarts"
    :yesPressed="removeSelectedProduct"
  />
</template>

<script>
import CustomIcon from '@/components/CustomIcon'
import TrashcanIcon from '@/assets/icons/trashcan-icon'
import Button from 'primevue/button'
import Tooltip from 'primevue/tooltip'
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import ConfirmationModal from '@/components/modals/ConfirmationModal'
import PriceDetailsModal from '@/components/products/PriceDetailsModal'
import Dialog from 'primevue/dialog'
import router from '@/router'

export default {
  name: 'ShoppingCartProductItem',
  components: {
    Button,
    CustomIcon,
    Dialog,
    ConfirmationModal,
    PriceDetailsModal,
    TrashcanIcon
  },
  directives: {
    tooltip: Tooltip
  },
  props: {
    product: {
      type: Object,
      required: true
    },
    toggleShoppingBasketOverlayPanel: {
      type: Function,
      required: true
    }
  },
  setup (props) {
    const store = useStore()
    const shoppingCartService = store.getters['serviceStore/getShoppingCartService']
    const priceStatus = computed(() => store.getters['productStore/getIsAfterDiscount'])
    const isLoading = ref(false)
    const removeCarts = ref(false)
    const displayModal = ref(false)
    const currentCommodity = ref(null)
    const currentQuantity = ref(null)

    const removeSelectedProduct = async () => {
      isLoading.value = true
      try {
        await store.dispatch('shoppingCartStore/removeProductFromShoppingCart', { shoppingCartService: shoppingCartService, productDetails: props.product })
      } catch (e) {
        isLoading.value = false
      }
      isLoading.value = false
    }

    const openModal = (data) => {
      currentCommodity.value = data.commodity
      currentQuantity.value = data
      displayModal.value = true
    }

    const closeModal = () => {
      currentCommodity.value = null
      currentQuantity.value = null
      displayModal.value = false
    }

    const toggleRemoveCarts = () => {
      removeCarts.value = !removeCarts.value
    }
    const handleGoToProduct = async (id) => {
      try {
        props.toggleShoppingBasketOverlayPanel()
        await router.push({
          name: 'ProductDetails',
          params: {
            id
          }
        })
      } catch (error) {
      }
    }

    return {
      toggleRemoveCarts,
      removeCarts,
      currentQuantity,
      currentCommodity,
      openModal,
      handleGoToProduct,
      closeModal,
      displayModal,
      removeSelectedProduct,
      priceStatus
    }
  }
}
</script>

<style lang="scss" scoped>

.basketItemWrapper {
  border-bottom: 1px solid #cfcfcf;
  padding-top: 15px;
  padding-bottom: 10px;

  .amountButton{
    cursor: pointer;
  }
  .amountButton:hover{
    color: $primary_color !important;
  }

  .product-name{
    cursor: pointer;
  }

  p {
    font-weight: 600;
    width: 100%;
    white-space: nowrap;
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  span {
    font-weight: lighter;
  }

  .firstItemSection {
    text-align: left;
  }

  .secondItemSection {
    text-align: right;
    display: grid;
  }
}
</style>
