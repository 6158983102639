import { parseDateArray } from '@/utils/utils'
import { LogCategoryEnum } from '@/enums/logCategoryEnum'

export class LogModel {
  constructor (logModel = {}) {
    this.combinedMessage = this.parseMessage(logModel)
    this.messageDetails = logModel.message
    this.logCreateDate = parseDateArray(logModel.logCreateDate)
    this.category = LogCategoryEnum[logModel.category]
    this.contractorEnovaId = logModel.contractorEnovaId
    this.invoiceGUID = logModel.invoiceGUID
    this.invoiceNumber = logModel.invoiceNumber
    this.apiHttpCode = logModel.apiHttpCode
    this.invoiceRequestDateTime = logModel.invoiceRequestDateTime ? parseDateArray(logModel.invoiceRequestDateTime) : ''
    this.level = logModel.level
    this.orderId = logModel.orderId
    this.paymentId = logModel.paymentId
  }

  parseMessage (logModel) {
    const category = logModel.category
    const contractorEnovaId = logModel.contractorEnovaId
    const invoiceGUID = logModel.invoiceGUID
    const invoiceNumber = logModel.invoiceNumber
    const apiHttpCode = logModel.apiHttpCode
    const invoiceRequestDateTime = logModel.invoiceRequestDateTime
    const level = logModel.level
    const orderId = logModel.orderId
    const paymentId = logModel.paymentId
    const parsedMessage =
    (category ? `Kategoria: ${LogCategoryEnum[category]}, ` : '') +
    (invoiceNumber ? `Faktura: ${invoiceNumber}, ` : '') +
    (orderId ? `ID zamówienia: ${orderId}, ` : '') +
    (paymentId ? `ID płatności: ${paymentId}, ` : '') +
    (apiHttpCode ? `Kod HTTP: ${apiHttpCode}, ` : '') +
    (invoiceGUID ? `GUID Faktury: ${invoiceGUID}, ` : '') +
    (contractorEnovaId ? `ID kontraktora enovy: ${contractorEnovaId}, ` : '') +
    (invoiceRequestDateTime ? `Czas requestu faktury: ${parseDateArray(invoiceRequestDateTime)}, ` : '') +
    (level ? `Poziom: ${level}, ` : '')
    return parsedMessage
  }
}
