import authHeader from '@/services/auth_header'
import acceptHeader from '@/services/aceppt_header'
import { ConverterDataModel } from '@/models/converterDataModel'
import instance from '@/services/api'
import { FilterCharacteristicModel } from '@/models/FilterCharacteristicModel'
import authContentTypeHeader from '@/services/auth_content_type_header'
import { ProductsList } from '@/models/productsList'
import { filterCharacteristicsTypeEnum } from '@/enums/filterCharacteristicsTypeEnum'

export class ProductsService {
  async getCategoryCharacteristics (productsFilterData) {
    try {
      const response = await instance.get('/catalog/' + productsFilterData, { headers: authContentTypeHeader() })

      const characteristicsList = []
      response.data.forEach(characteristic => {
        const singleCharacteristic = new FilterCharacteristicModel(characteristic)

        // Change if backend doesn't send the storehouse data
        if (characteristic.name !== 'kagStorehouse' && characteristic.name !== 'bygStorehouse' && characteristic.name !== 'gdgStorehouse' && characteristic.name !== 'brgStorehouse' && characteristic.name !== 'plgStorehouse' && characteristic.name !== 'togStorehouse' && characteristic.name !== 'outStorehouse' && characteristic.name !== 'slgStorehouse' && characteristic.name !== 'ingStorehouse') {
          if (singleCharacteristic.type === filterCharacteristicsTypeEnum.STRING_SET || singleCharacteristic.type === filterCharacteristicsTypeEnum.BOOLEAN_SET) {
            characteristicsList.push(new FilterCharacteristicModel(characteristic))
          } else if (singleCharacteristic.valuesArray.length > 1 && (singleCharacteristic.type === filterCharacteristicsTypeEnum.DOUBLE_SET || singleCharacteristic.type === filterCharacteristicsTypeEnum.BIGDECIMAL_SET)) {
            characteristicsList.push(new FilterCharacteristicModel(characteristic))
          }
        }
      })

      return Promise.resolve(characteristicsList)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async getCommodities (productsFilterData) {
    try {
      const response = await instance.post('/commodity/all', productsFilterData, { headers: authContentTypeHeader() })
      return Promise.resolve(new ProductsList(response.data))
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async getThumbnail (id) {
    try {
      const response = await instance.get('/thumbnail/' + id + '/default', {
        responseType: 'blob',
        headers: acceptHeader()
      })
      return Promise.resolve(response.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async getThumbnails (commodities) {
    const array = await Promise.allSettled(commodities.commodityDtoList.map((commodity) => commodity.hasThumbnail ? this.getThumbnail(commodity.id) : Promise.reject(Error('No thumbnail'))))
    return array
  }

  async getFeaturedThumbnails (commodities) {
    const array = await Promise.allSettled(commodities.map((commodity) => this.getThumbnail(commodity.id)))
    return array
  }

  async getProductThumbnails (thumbnailsArray) {
    const productArray = await Promise.allSettled(thumbnailsArray.map((element) => this.getThumbnailSingle(element.id)))
    return productArray
  }

  async getCommodityCategory () {
    try {
      const response = await instance.get('/commodity/category', {
        headers: authHeader()
      })

      const categories = JSON.parse(JSON.stringify(response.data))

      const iterate = (obj) => {
        Object.keys(obj).forEach(key => {
          if (key === 'path') {
            // Change if backend changes handling path again
            // const parts = obj[key].split('\\')
            // const output = parts.join('\\\\')
            // obj[key] = output
          }
          if (key === 'name') obj.label = obj[key]
          if (key === 'id') obj.key = obj[key]
          obj.collapsedIcon = 'pi-plus'
          obj.expandedIcon = 'pi-minus'
          if (typeof obj[key] === 'object' && obj[key] !== null) {
            iterate(obj[key], obj.key)
          }
        })
        Object.keys(obj).forEach(children => {
          if (children === 'children') {
            obj[children].sort((a, b) => {
              if (/^[0-9]*$/.test(a.name.toLowerCase()) || /^[0-9]*$/.test(b.name.toLowerCase())) {
                return a.name - b.name
              } else if (a.name.toLowerCase() > b.name.toLowerCase()) {
                return 1
              } else if (a.name.toLowerCase() < b.name.toLowerCase()) {
                return -1
              } else {
                return 0
              }
            })
          }
        })
      }

      iterate(categories)
      categories.sort((a, b) => {
        if (/^[0-9]*$/.test(a.name.toLowerCase()) || /^[0-9]*$/.test(b.name.toLowerCase())) {
          return a.name - b.name
        } else if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1
        } else if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1
        } else {
          return 0
        }
      })
      categories.unshift({
        children: [],
        id: 0,
        key: 0,
        label: 'WSZYSTKIE',
        path: '\\'
      })

      return Promise.resolve(categories)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async getFilterCharacteristicsByCategory (payload) {
    try {
      const response = (await import('@/api/mock/data/characteristics.json')).default
      const characteristicsList = []
      response.forEach(value => {
        characteristicsList.push(new FilterCharacteristicModel(value))
      })
      return Promise.resolve(characteristicsList)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async getImage (commodityId, imageId) {
    try {
      const response = await instance.get(`/commodity/enova/${commodityId}/image/${imageId}`, {
        headers: authHeader()
      })
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async getProductById (id) {
    try {
      const response = await instance.get(`/commodity/enova/${id}`, {
        headers: acceptHeader()
      })
      return Promise.resolve(response.data)
    } catch (error) {
      return Promise.reject(error.response)
    }
  }

  async getProductConverter (commodityId) {
    try {
      const response = await instance.get(`/converter/${commodityId}`, {
        headers: authHeader()
      })
      const converterList = []
      response.data.forEach(value => {
        converterList.push(new ConverterDataModel(value))
      })
      return Promise.resolve(converterList)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async getThumbnailSingle (id) {
    try {
      const response = await instance.get(`/thumbnail/${id}/single`, {
        responseType: 'blob',
        headers: acceptHeader()
      })
      return Promise.resolve(URL.createObjectURL(response.data))
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async getMainImage (id) {
    try {
      const response = await instance.get(`/file/enova/${id}`, {
        responseType: 'blob',
        headers: acceptHeader()
      })
      return Promise.resolve(URL.createObjectURL(response.data))
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async getDefaultImage (id) {
    try {
      const response = await instance.get(`/file/enova/commodity/${id}/default`, {
        responseType: 'blob',
        headers: acceptHeader()
      })
      return Promise.resolve(URL.createObjectURL(response.data))
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async getThumbnailsListId (commodityId) {
    try {
      const response = await instance.get(`/thumbnail/${commodityId}`, {
        headers: authHeader()
      })
      return Promise.resolve(response.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async getPremiumProducts () {
    try {
      const response = await instance.get('/commodity/type/premium', {
        headers: authHeader()
      })
      return Promise.resolve(response.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async getSimilarProducts () {
    try {
      const response = await instance.get('/commodity', {
        headers: authHeader()
      })
      return Promise.resolve(response.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async getLastWatchedProducts () {
    try {
      const response = await instance.get('/commodity', {
        headers: authHeader()
      })
      return Promise.resolve(response.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async getFeaturedProducts () {
    try {
      const response = await instance.get('/commodity', {
        headers: authHeader()
      })
      return Promise.resolve(response.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}
