import SecureLS from 'secure-ls'
const ls = new SecureLS({ isCompression: false })
const article = ls.get('vuex')

const state = article.articleStore?.lead
  ? {
      currentArticle: article.articleStore.lead,
      articles: null,
      lead: article.articleStore.currentArticle
    }
  : {
      currentArticle: null,
      articles: null,
      lead: ''
    }

const getters = {
  getCurrentArticle (state) {
    return state.currentArticle
  },
  getArticles (state) {
    return state.articles
  },
  getArticlesFromTo: (state) => (from, to) => {
    return state.articles.articles.slice(from, to)
  },
  getTotalRecords (state) {
    return state.articles.count
  }
}

const actions = {
  fetchArticle: async ({ commit, dispatch }, payload) => {
    try {
      const response = await payload.articleService.fetchArticle(payload.articleId)
      commit('SET_CURRENT_ARTICLE', response)
      if (response.image !== undefined) {
        const imageFile = await payload.articleService.fetchArticleImage(payload.articleId)
        commit('SET_ARTICLE_IMAGE', imageFile)
      }
    } catch (e) {
      await dispatch('snackbarStore/showMessage', 'Wystąpił błąd, proszę spróbować ponownie', { root: true })
      return Promise.reject(e)
    }
  },
  fetchArticles: async ({ commit, dispatch }, payload) => {
    try {
      const response = await payload.articleService.fetchArticles(payload.start, payload.end)
      commit('SET_ARTICLES', response)
    } catch (e) {
      await dispatch('snackbarStore/showMessage', 'Wystąpił błąd, proszę spróbować ponownie', { root: true })
      return Promise.reject(e)
    }
  },
  fetchAdminArticles: async ({ commit, dispatch }, payload) => {
    try {
      const response = await payload.articleService.fetchAdminArticles(payload.start, payload.end)
      commit('SET_ARTICLES', response)
    } catch (e) {
      await dispatch('snackbarStore/showMessage', 'Wystąpił błąd, proszę spróbować ponownie', { root: true })
      return Promise.reject(e)
    }
  },
  saveNewArticle: async ({ commit, dispatch }, payload) => {
    try {
      const response = await payload.articleService.postArticle(payload.articleData)
      await dispatch('snackbarStore/showMessage', 'Dodano nową aktualność', {
        root: true
      })
      return Promise.resolve(response)
    } catch (e) {
      await dispatch('snackbarStore/showMessage', 'Wystąpił błąd, proszę spróbować ponownie', { root: true })
      return Promise.reject(e)
    }
  },
  updateArticle: async ({ commit, dispatch }, payload) => {
    try {
      const response = await payload.articleService.updateArticle(payload.articleData)
      await dispatch('snackbarStore/showMessage', 'Zapisano aktualność', {
        root: true
      })
      return Promise.resolve(response)
    } catch (e) {
      await dispatch('snackbarStore/showMessage', 'Wystąpił błąd, proszę spróbować ponownie', { root: true })
      return Promise.reject(e)
    }
  },
  fetchImage: async ({ commit, dispatch }, payload) => {
    try {
      const response = await payload.articleService.fetchArticleImage(payload.articleId)
      commit('SET_ARTICLE_IMAGE', response)

      return response
    } catch (e) {
      await dispatch('snackbarStore/showMessage', 'Wystąpił błąd, proszę spróbować ponownie', { root: true })
      return Promise.reject(e)
    }
  },
  addImage: async ({ commit, dispatch }, payload) => {
    try {
      const response = await payload.articleService.addImage(payload.articleData, payload.articleId)
      return Promise.resolve(response)
    } catch (e) {
      await dispatch('snackbarStore/showMessage', 'Wystąpił błąd, proszę spróbować ponownie', { root: true })
      return Promise.reject(e)
    }
  },
  addAttachment: async ({ commit, dispatch }, payload) => {
    try {
      const response = await payload.articleService.addAttachment(payload.attachmentData, payload.articleId)
      return Promise.resolve(response)
    } catch (e) {
      await dispatch('snackbarStore/showMessage', 'Wystąpił błąd, proszę spróbować ponownie', { root: true })
      return Promise.reject(e)
    }
  },
  deleteArticle: async ({ commit, dispatch }, payload) => {
    try {
      const response = await payload.articleService.deleteArticle(payload.articleId)
      commit('DELETE_ARTICLE', payload.articleId)
      await dispatch('snackbarStore/showMessage', 'Usunięto aktualność', {
        root: true
      })
      return Promise.resolve(response)
    } catch (e) {
      await dispatch('snackbarStore/showMessage', 'Wystąpił błąd, proszę spróbować ponownie', { root: true })
      return Promise.reject(e)
    }
  },
  deleteAttachment: async ({ commit, dispatch }, payload) => {
    try {
      const response = await payload.articleService.deleteAttachment(payload.articleId, payload.attachmentId)
      commit('DELETE_FILE', payload.indexToRemove)
      await dispatch('snackbarStore/showMessage', 'Usunięto plik', {
        root: true
      })
      return Promise.resolve(response)
    } catch (e) {
      await dispatch('snackbarStore/showMessage', 'Wystąpił błąd, proszę spróbować ponownie', { root: true })
      return Promise.reject(e)
    }
  }
}

const mutations = {
  SET_CURRENT_ARTICLE: (state, data) => {
    state.currentArticle = data
    state.lead = data.lead
  },
  SET_ARTICLE_IMAGE: (state, data) => {
    state.currentArticle.image.imageFile = data
  },
  SET_ARTICLES: (state, data) => {
    state.articles = data
  },
  SAVE_NEW_ARTICLE: (state, data) => {
    state.articles.articles.push(data)
  },
  UPDATE_ARTICLE: (state, data) => {
    let found = false

    for (let i = 0; i < state.articles.articles.length; i++) {
      if (state.articles.articles[i].id === data.id) {
        state.articles.articles[i] = data
        found = true
        break
      }
    }
    if (found === false) {
      state.articles.articles.push(data)
    }
  },
  DELETE_ARTICLE: (state, data) => {
    for (let i = 0; i < state.articles.articles.length; i++) {
      if (state.articles.articles[i].id === data) {
        state.articles.articles.splice(i, 1)
        break
      }
    }
  },
  DELETE_FILE: (state, data) => {
    state.currentArticle.attachments.splice(data, 1)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
