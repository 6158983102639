import { DepartmentModel } from '@/models/departmentModel'
import { v4 as uuidv4 } from 'uuid'

const state = {
  departments: []
}

const getters = {
  getDepartments (state) {
    return state.departments
  }
}

const actions = {
  fetchDepartmentsList: async (context, payload) => {
    try {
      const departmentsFromApi = await payload.departmentService.fetchDepartments()
      context.commit('SET_DEPARTMENTS_LIST', departmentsFromApi)
    } catch (e) {
      await Promise.reject(e)
    }
  },
  updateDepartmentsList: async ({ commit, dispatch }, payload) => {
    try {
      await payload.departmentService.putDepartments(payload.departmentsData)
      commit('SET_DEPARTMENTS_LIST', payload.departmentsData)
      await dispatch('snackbarStore/showMessage', 'Pomyślnie zapisano oddziały.', { root: true })
    } catch (e) {
      await dispatch(
        'snackbarStore/showMessage',
        'Wystąpił błąd, proszę spróbować ponownie',
        { root: true }
      )
      await Promise.reject(e)
    }
  },
  addNewDepartment: async ({ commit, dispatch }, payload) => {
    try {
      commit('ADD_NEW_DEPARTMENT', payload.newDepartment)
      await dispatch('snackbarStore/showMessage', 'Dodano oddział, zapisz zmiany!', { root: true })
    } catch (e) {
      await Promise.reject(e)
    }
  },
  removeDepartment: async ({ commit, dispatch }, payload) => {
    try {
      commit('REMOVE_DEPARTMENT', payload.index)
      await dispatch('snackbarStore/showMessage', 'Usunięto oddział, zapisz zmiany!', { root: true })
    } catch (e) {
      await Promise.reject(e)
    }
  }
}

const mutations = {
  SET_DEPARTMENTS_LIST: (state, departmentsFromApi) => {
    state.departments = departmentsFromApi
  },
  ADD_NEW_DEPARTMENT: (state, newDepartment) => {
    state.departments.departments.push(new DepartmentModel({ id: uuidv4(), name: newDepartment.name, email: newDepartment.email }))
    state.departments.departments.sort((a, b) => a.name.localeCompare(b.name))
  },
  REMOVE_DEPARTMENT: (state, departmentIndex) => {
    state.departments.departments.splice(departmentIndex, 1)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
