import { handleRequestError } from '@/utils/utils'
import authHeader from '@/services/auth_header'
import authContentTypeHeader from '@/services/auth_content_type_header'
import authMultipartHeader from '@/services/auth_multipart_header'
import { PrizeCategory, PrizeModel } from '@/models/prizeModel'
import { OrdersList } from '@/models/ordersList'
import instance from '@/services/api'

export class LoyaltyProgramService {
  async fetchAllLoyaltyProgramProducts () {
    try {
      const response = await instance.get('/loyalty-program', { headers: authHeader() })
      const prizeList = []
      for (const prize of response.data) {
        prizeList.push(new PrizeModel(prize))
      }
      return Promise.resolve(prizeList)
    } catch (error) {
      await handleRequestError(error)
    }
  }

  async updatePrize (prizeData) {
    try {
      await instance.put('/loyalty-program', prizeData, { headers: authContentTypeHeader() })
      return Promise.resolve(true)
    } catch (error) {
      await handleRequestError(error)
    }
  }

  async addPrize (prizeData) {
    try {
      await instance.post('/loyalty-program', prizeData, { headers: authContentTypeHeader() })
      return Promise.resolve(true)
    } catch (error) {
      await handleRequestError(error)
    }
  }

  async deletePrize (prizeId) {
    try {
      await instance.delete('/loyalty-program/delete/' + prizeId, { headers: authHeader() })
      return Promise.resolve(true)
    } catch (error) {
      await handleRequestError(error)
    }
  }

  async orderPrize (prizeID) {
    try {
      await instance.post('/loyalty-program/' + prizeID, { headers: authHeader() })
      return Promise.resolve(true)
    } catch (error) {
      await handleRequestError(error)
    }
  }

  async fetchOrders () {
    try {
      const response = await instance.get('/loyalty-program/show/ordered', { headers: authHeader() })
      return new OrdersList(response.data)
    } catch (error) {
      await handleRequestError(error)
    }
  }

  async editPrizeOrder (orderData) {
    try {
      await instance.put('/loyalty-program/show/ordered/edit?New%20order%20status=' + orderData.status + '&Order%20id=' + orderData.id, orderData, { headers: authContentTypeHeader() })
      return Promise.resolve(true)
    } catch (error) {
      await handleRequestError(error)
    }
  }

  async fetchPrizeOrder (prizeId) {
    try {
      const response = await instance.get('/loyalty-program/order/' + prizeId, { headers: authHeader() })
      return Promise.resolve(response.data)
    } catch (error) {
      await handleRequestError(error)
    }
  }

  async fetchPrizeCategories () {
    try {
      const response = await instance.get('/loyalty-program/prize-category', { headers: authHeader() })
      const prizeCategoriesList = []
      for (const prizeCategory of response.data) {
        prizeCategoriesList.push(new PrizeCategory(prizeCategory))
      }
      return Promise.resolve(prizeCategoriesList)
    } catch (error) {
      await handleRequestError(error)
    }
  }

  async postPrizeCategories (prizeCategoriesData) {
    try {
      await instance.post('/loyalty-program/prize-category', prizeCategoriesData, { headers: authContentTypeHeader() })
      return Promise.resolve(true)
    } catch (error) {
      await handleRequestError(error)
    }
  }

  async deletePrizeCategory (id) {
    try {
      await instance.delete('/loyalty-program/prize-category/' + id, { headers: authHeader() })
      return Promise.resolve(true)
    } catch (error) {
      await handleRequestError(error)
    }
  }

  async postPrizeImage (prizeImageData) {
    try {
      const response = await instance.post('/loyalty-program/prize-image', prizeImageData, { headers: authMultipartHeader() })
      return Promise.resolve(response)
    } catch (error) {
      await handleRequestError(error)
    }
  }

  async fetchSinglePrize (prizeId) {
    try {
      const response = await instance.get('/loyalty-program/show/' + prizeId, { headers: authHeader() })
      return Promise.resolve(new PrizeModel(response.data))
    } catch (error) {
      await handleRequestError(error)
    }
  }

  async fetchPrizesByCategory (categoryId) {
    try {
      const response = await instance.get('/loyalty-program/' + categoryId, { headers: authHeader() })
      const prizeList = []
      for (const prize of response.data) {
        prizeList.push(new PrizeModel(prize))
      }
      return Promise.resolve(prizeList)
    } catch (error) {
      await handleRequestError(error)
    }
  }
}
