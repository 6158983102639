<template>
    <NavigationBar @synchronizeUsers = synchronizeUsers />
    <NavigationDrawer v-if="!isMobile" @synchronizeUsers = synchronizeUsers />
    <div class="router-wrapper p-d-flex p-dir-col p-jc-between" :style="!isMobile ? { 'margin-left': drawerWidth } : 'margin-left: 0'">
      <Breadcrumbs />
      <div style="flexGrow:1">
        <router-view />
      </div>
      <Footer />
    </div>
    <Snackbar v-if="snackbarLabel" :label="snackbarLabel" />
    <!--  <Chat/>-->
  <Dialog v-model:visible="isBlocked" :closable="false" modal header="Przetwarzanie zamówienia" :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{ 'max-width': '600px', width: '40vw'}">
    <div class="p-d-flex p-flex-column p-justify-center p-align-center">
      <span>Proszę czekać, trwa przetwarzanie twojego zamówienia.</span>
      <ProgressSpinner class="p-mt-2 p-mb-2" style="width: 3rem; height: 3rem"/>
    </div>
  </Dialog>
</template>
<script>
import { activedRoutes } from '@/utils/utils'
import { computed, inject, onMounted } from 'vue'
import { useStore } from 'vuex'
import Dialog from 'primevue/dialog'
import Snackbar from '@/components/Snackbar'
import ProgressSpinner from 'primevue/progressspinner'
// import Chat from '@/components/Chat'
import NavigationBar from '@/components/NavigationBar'
import NavigationDrawer from '@/components/NavigationDrawer/NavigationDrawer'
import { drawerWidth } from '@/components/NavigationDrawer/drawerState'
import Footer from '@/components/Footer'
import Breadcrumbs from '@/components/Breadcrumbs'

export default {
  name: 'App',
  components: {
    NavigationDrawer,
    NavigationBar,
    ProgressSpinner,
    Snackbar,
    Footer,
    Breadcrumbs,
    Dialog
    // Chat
  },
  setup () {
    const store = useStore()
    const snackbarLabel = computed(() => store.state.snackbarStore.label)
    const keyCloak = inject(process.env.VUE_APP_KEYCLOAK_PROVIDE_VARIABLE)
    const userService = store.getters['serviceStore/getUserService']

    const isMobile = computed(() => {
      return screen.width < 768
    })
    const isBlocked = computed(() => store.getters['shoppingCartStore/getIsBlocked'])

    onMounted(async () => {
      const userProfile = await keyCloak.loadUserProfile()
      const userRole = keyCloak.realmAccess.roles
      await store.dispatch('userStore/getUserInformation', {
        userProfile: userProfile,
        userRole: userRole
      })
    })

    const synchronizeUsers = async () => {
      try {
        await userService.synchronizeUsers()
        store.dispatch('snackbarStore/showMessage', 'Synchronizacja zakończona')
      } catch (e) {
        store.dispatch('snackbarStore/showMessage', 'Nastąpił błąd w synchronizacji')
      }
    }

    return {
      snackbarLabel,
      isBlocked,
      drawerWidth,
      activedRoutes,
      isMobile,
      synchronizeUsers
    }
  }
}
</script>
<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@100;200;300;400;500;600;700;800;900&family=Lato:wght@900&display=swap');
* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Barlow, serif;
}

body {
  font-family: Barlow, serif;
  overflow-x: auto;
  background-color: #f8f8f8;
  margin: 0;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  padding: 0;
  margin: 0;
}
.router-wrapper {
  transition: 0.3s ease;
  min-height: calc(100vh - 100px);
}
.router-footer {
  position: relative;
}
.p-tooltip-text {
  background: #b72937 !important;
}
.p-tooltip-arrow {
  border-top-color: #b72937 !important;
}
::-webkit-scrollbar {
  border: 1px solid white;
  width: 8px;
}
::-webkit-scrollbar-thumb {
  border-radius: 0px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}
.link-wrapper{
  text-decoration: none;
  color: #000
}
.link-wrapper:hover{
  color: $primary_color !important;
}
.p-overlaypanel .p-overlaypanel-close {
  background: $navigation_black !important;
}
</style>
