import { OrderUser } from '@/models/orderUser'
import { translatePrizeOrderStatus } from '@/utils/utils'

export class SingleOrder {
  constructor (element) {
    this.id = element.id
    this.status = element.status
    this.prizeName = element.prizeName
    this.prizeId = element.prizeId
    this.prizePrice = element.prizePrice
    this.shopUser = new OrderUser(element.shopUser)
    this.orderingDate = this.parseDate(element.orderingDate)
    this.pendingDate = this.parseDate(element.pendingDate)
    this.issueDate = this.parseDate(element.issueDate)
    this.status = translatePrizeOrderStatus(element.status)
  }

  parseDate (date) {
    if (date !== undefined) {
      return new Date(date[0], date[1] - 1, date[2], date[3], date[4], date[5])
    }
  }
}
