export class Address {
  constructor (address) {
    this.id = address.id
    this.name = address.name
    this.lastName = address.lastName
    this.street = address.street
    this.city = address.city
    this.zipCode = address.zipCode
    this.apartmentNumber = address.apartmentNumber
    this.buildingNumber = address.buildingNumber
    this.companyName = address.companyName
    this.shippingAddressId = address.shippingAddressId
    this.chosen = address.chosen
    this.phoneNumber = address.phoneNumber
  }
}
