import router from '@/router'
import { ref } from 'vue'
import { generateRandomNumber } from '@/utils/utils'

export const calendarTranslationPl =
  {
    firstDayOfWeek: 0,
    dayNames: ['Niedziela', 'Poniedziałek', 'Wtorek', 'Środa', 'Czwartek', 'Piątek', 'Sobota'],
    dayNamesShort: ['Nie', 'Pon', 'Wt', 'Śr', 'Czw', 'Pt', 'So'],
    dayNamesMin: ['N', 'P', 'W', 'Ś', 'Cz', 'P', 'S'],
    monthNames: ['Styczeń', 'Luty', 'Marzec', 'Kwiecień', 'Maj', 'Czerwiec', 'Lipiec', 'Sierpień', 'Wrzesień', 'Październik', 'Listopad', 'Grudzień'],
    monthNamesShort: ['Sty', 'Lut', 'Mar', 'Kwi', 'Maj', 'Cze', 'Lip', 'Sie', 'Wrz', 'Paź', 'Lis', 'Gru'],
    today: 'Dzisiaj',
    clear: 'Wyczyść',
    dateFormat: 'dd.mm.yy',
    weekHeader: 'Tydzień'
  }

export const chartCostPerCategory = {
  multiAxisData: {
    labels: ['Drzwi', 'Kuchenne', 'Budowlane', 'Podłogi', 'Panele'],
    datasets: [
      {
        label: 'Ilość produktów (szt.)',
        backgroundColor: [
          '#EC407A',
          '#AB47BC',
          '#42A5F5',
          '#7E57C2',
          '#66BB6A',
          '#FFCA28',
          '#26A69A'
        ],
        yAxisID: 'y-axis-1',
        data: [65, 59, 80, 81, 56, 55, 10]
      },
      {
        label: 'Kwota (zł)',
        backgroundColor: '#78909C',
        yAxisID: 'y-axis-2',
        data: [800, 3000, 2500, 2100, 1500, 255, 2500]
      }
    ]
  },
  options: {
    responsive: true,
    tooltips: {
      mode: 'index',
      intersect: true
    },
    scales: {
      yAxes: [
        {
          type: 'linear',
          display: true,
          position: 'left',
          id: 'y-axis-1',
          ticks: {
            min: 0,
            max: 200
          }
        },
        {
          type: 'linear',
          display: true,
          position: 'right',
          id: 'y-axis-2',
          gridLines: {
            drawOnChartArea: false
          },
          ticks: {
            min: 0,
            max: 3000
          }
        }
      ]
    }
  }
}

export const chartAccountStats = {
  options: {
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        fontSize: 12
      }
    }
  },
  labels: ['Zrealizowane zamówienia', 'Zamówienia w trakcie', 'Zamówienia anulowane'],
  datasets: [
    {
      data: [generateRandomNumber(100, 300), generateRandomNumber(30, 60), generateRandomNumber(10, 30)],
      backgroundColor: [
        '#66BB6A',
        '#ffdc26',
        '#ff2626'
      ],
      hoverBackgroundColor: [
        '#81C784',
        '#ffe529',
        '#ff4d4d'
      ]
    }
  ]
}
export const data = [
  { id: '1000', code: 'f230fh0g3', name: 'Bamboo Watch', description: 'Product Description', image: 'panele3.jpg', price: 65, category: 'Accessories', quantity: 24, inventoryStatus: 'INSTOCK', rating: 5 },
  { id: '1001', code: 'nvklal433', name: 'Black Watch', description: 'Product Description', image: 'panele.jpg', price: 72, category: 'Accessories', quantity: 61, inventoryStatus: 'INSTOCK', rating: 4 }
]
export const carouselBestProducts = [
  {
    name: 'Szafa',
    price: 569,
    image: 'szafa.jpg'
  },
  {
    name: 'Lóżko',
    price: 899,
    image: 'lozko.jpg'
  },
  {
    name: 'Pakiet 3 taboretów',
    price: 350,
    image: 'taborety.jpg'
  }
]

export const productCategoryNavigationItems = [
  {
    label: 'Podłogi, podkłady, listwy',
    command: () => router.push({
      name: 'Products',
      params: {
        category: 'podlogi-podklady-listwy'
      }
    }),
    items: [
      {
        label: 'Panele podłogowe',
        command: () => router.push({
          name: 'Products',
          params: {
            category: 'podlogi-podklady-listwy_panele-podlogowe'
          }
        }),
        items: [
          {
            label: 'Swiss Krono',
            command: () => router.push({
              name: 'Products',
              params: {
                category: 'podlogi-podklady-listwy_panele-podlogowe_swiss-krono'
              }
            })
          },
          {
            label: 'AB Premium',
            command: () => router.push({
              name: 'Products',
              params: {
                category: 'podlogi-podklady-listwy_panele-podlogowe_ab-premium'
              }
            })
          },
          {
            label: 'Kaindl',
            command: () => router.push({
              name: 'Products',
              params: {
                category: 'podlogi-podklady-listwy_panele-podlogowe_kaindl'
              }
            })
          },
          {
            label: 'ter Hürne',
            command: () => router.push({
              name: 'Products',
              params: {
                category: 'podlogi-podklady-listwy_panele-podlogowe_ter-hurne'
              }
            })
          },
          {
            label: 'Krono Original',
            command: () => router.push({
              name: 'Products',
              params: {
                category: 'podlogi-podklady-listwy_panele-podlogowe_krono-original'
              }
            })
          },
          {
            label: 'Villeroy & Boch',
            command: () => router.push({
              name: 'Products',
              params: {
                category: 'podlogi-podklady-listwy_panele-podlogowe_villeroy-boch'
              }
            })
          }
        ]
      },
      {
        label: 'Podłogi drewniane',
        command: () => router.push({
          name: 'Products',
          params: {
            category: 'podlogi-podklady-listwy_podlogi_drewniane'
          }
        })
      },
      {
        label: 'Panele winylowe LVT',
        command: () => router.push({
          name: 'Products',
          params: {
            category: 'podlogi-podklady-listwy_panele-winylowe-lvt'
          }
        })
      },
      {
        label: 'Listwy',
        command: () => router.push({
          name: 'Products',
          params: {
            category: 'podlogi-podklady-listwy_listwy'
          }
        }),
        items: [
          {
            label: 'Przypodłogowe',
            command: () => router.push({
              name: 'Products',
              params: {
                category: 'podlogi-podklady-listwy_listwy_przypodlogowe'
              }
            })
          },
          {
            label: 'Dekoracyjne',
            command: () => router.push({
              name: 'Products',
              params: {
                category: 'podlogi-podklady-listwy_listwy_dekoracyjne'
              }
            })
          }
        ]
      },
      {
        label: 'Podkłady podłogowe i folie izolacyjne',
        command: () => router.push({
          name: 'Products',
          params: {
            category: 'podlogi-podklady-listwy_podklady-podlogowe-i-folie-izolacyjne'
          }
        })
      }
    ]
  },
  {
    label: 'Drzwi',
    command: () => router.push({
      name: 'Products',
      params: {
        category: 'drzwi'
      }
    })
  },
  {
    label: 'Blaty kuchenne',
    command: () => router.push({
      name: 'Products',
      params: {
        category: 'blaty-kuchenne'
      }
    })
  },
  {
    label: 'Płyty meblowe',
    command: () => router.push({
      name: 'Products',
      params: {
        category: 'plyty-meblowe'
      }
    }),
    items: [
      {
        label: 'Płyty laminowane',
        command: () => router.push({
          name: 'Products',
          params: {
            category: 'plyty-meblowe_plyty-laminowane'
          }
        }),
        items: [
          {
            label: 'Swiss Krono - Kolekcja Standardowa',
            command: () => router.push({
              name: 'Products',
              params: {
                category: 'plyty-meblowe_plyty-laminowane_swiss-krono-kolekcja-standardowa'
              }
            })
          },
          {
            label: 'Swiss Krono - Kolekcja Symfonia',
            command: () => router.push({
              name: 'Products',
              params: {
                category: 'plyty-meblowe_plyty-laminowane_swiss-krono-kolekcja-symfonia'
              }
            })
          },
          {
            label: 'Swiss Krono - Kolekcja One World',
            command: () => router.push({
              name: 'Products',
              params: {
                category: 'plyty-meblowe_plyty-laminowane_swiss-krono-kolekcja-one-world'
              }
            })
          }
        ]
      },
      {
        label: 'Płyty MDF',
        command: () => router.push({
          name: 'Products',
          params: {
            category: 'plyty-meblowe_plyty-mdf'
          }
        })
      },
      {
        label: 'Płyty pleśniowe',
        command: () => router.push({
          name: 'Products',
          params: {
            category: 'plyty-meblowe_plyty-plesniowe'
          }
        })
      },
      {
        label: 'Płyty akrylowe',
        command: () => router.push({
          name: 'Products',
          params: {
            category: 'plyty-meblowe_plyty-laminowane'
          }
        })
      },
      {
        label: 'Płyty wiórowe',
        command: () => router.push({
          name: 'Products',
          params: {
            category: 'plyty-meblowe_plyty-wiorowe'
          }
        })
      },
      {
        label: 'Płyty niezapalne STOP FIRE',
        command: () => router.push({
          name: 'Products',
          params: {
            category: 'plyty-meblowe_plyty-niezapalne-stop-fire'
          }
        })
      },
      {
        label: 'Płyty SWISSCDF',
        command: () => router.push({
          name: 'Products',
          params: {
            category: 'plyty-meblowe_plyty_swisscdf'
          }
        })
      },
      {
        label: 'Sklejki',
        command: () => router.push({
          name: 'Products',
          params: {
            category: 'plyty-meblowe_sklejki'
          }
        })
      }
    ]
  },
  {
    label: 'Fronty meblowe',
    command: () => router.push({
      name: 'Products',
      params: {
        category: 'fronty-meblowe'
      }
    })
  },
  {
    label: 'Okucia i akcesoria meblowe',
    command: () => router.push({
      name: 'Products',
      params: {
        category: 'okucia-i-akcesoria-meblowe'
      }
    }),
    items: [
      {
        label: 'Produkty Blum',
        command: () => router.push({
          name: 'Products',
          params: {
            category: 'okucia-i-akcesoria-meblowe_prodykty-blum'
          }
        })
      },
      {
        label: 'Zawiasy, podnośniki i systemy szuflad',
        command: () => router.push({
          name: 'Products',
          params: {
            category: 'okucia-i-akcesoria-meblowe_zawiasy-podnosniki-i-systemy-szuflad'
          }
        })
      },
      {
        label: 'Systemy do drzwi przesuwnych',
        command: () => router.push({
          name: 'Products',
          params: {
            category: 'okucia-i-akcesoria-meblowe_systemy-do-drzwi-przesuwnych'
          }
        })
      },
      {
        label: 'Klamki',
        command: () => router.push({
          name: 'Products',
          params: {
            category: 'okucia-i-akcesoria-meblowe_klamki'
          }
        })
      },
      {
        label: 'Oświetlenie do mebli',
        command: () => router.push({
          name: 'Products',
          params: {
            category: 'okucia-i-akcesoria-meblowe_oswietlenie-do-mebli'
          }
        })
      },
      {
        label: 'Obrzeża meblowe',
        command: () => router.push({
          name: 'Products',
          params: {
            category: 'okucia-i-akcesoria-meblowe_obrzeza-meblowe'
          }
        })
      },
      {
        label: 'Śruby i inne elementy złączne',
        command: () => router.push({
          name: 'Products',
          params: {
            category: 'okucia-i-akcesoria-meblowe_sruby-i-inne-elementy-zlaczne'
          }
        })
      }
    ]
  },
  {
    label: 'Materiały budowlane',
    command: () => router.push({
      name: 'Products',
      params: {
        category: 'materialy-budowlane'
      }
    }),
    items: [
      {
        label: 'Płyty OSB',
        command: () => router.push({
          name: 'Products',
          params: {
            category: 'materialy-budowlane_plyty-osb'
          }
        })
      },
      {
        label: 'Drewno i płyty izolacyjne',
        command: () => router.push({
          name: 'Products',
          params: {
            category: 'materialy-budowlane_drewno-i-plyty-izolacyjne'
          }
        })
      }
    ]
  },
  {
    label: 'Materiały wykończeniowe',
    command: () => router.push({
      name: 'Products',
      params: {
        category: 'materialy-wykonczeniowe'
      }
    }),
    items: [
      {
        label: 'Panele ścienne',
        command: () => router.push({
          name: 'Products',
          params: {
            category: 'materialy-wykonczeniowe_panele-scienne'
          }
        })
      },
      {
        label: 'Elementy dekoracyjne',
        command: () => router.push({
          name: 'Products',
          params: {
            category: 'materialy-wykonczeniowe_elementy-dekoracyjne'
          }
        })
      }
    ]
  },
  {
    label: 'Wyposażenie wnętrz',
    command: () => router.push({
      name: 'Products',
      params: {
        category: 'wyposazenie-wnetrz'
      }
    }),
    items: [
      {
        label: 'Systemy segregacji odpadów',
        command: () => router.push({
          name: 'Products',
          params: {
            category: 'wyposazenie-wnetrz_systemy-segregacji-odpadow'
          }
        })
      },
      {
        label: 'Zlewozmywaki, baterie i armatury',
        command: () => router.push({
          name: 'Products',
          params: {
            category: 'wyposazenie-wnetrz_zlewozmywaki-baterie-i-armatury'
          }
        })
      }
    ]
  },
  {
    label: 'Urządzenia i środki do konserwacji',
    command: () => router.push({
      name: 'Products',
      params: {
        category: 'urzadzenia-i-srodki-do-konserwacji'
      }
    }),
    items: [
      {
        label: 'Mopy i pady aplikujące',
        command: () => router.push({
          name: 'Products',
          params: {
            category: 'urzadzenia-i-srodki-do-konserwacji_mopy-i-pady-aplikujace'
          }
        })
      }
    ]
  },
  {
    label: 'Narzędzia',
    command: () => router.push({
      name: 'Products',
      params: {
        category: 'narzędzia'
      }
    }),
    items: [
      {
        label: 'Narzędzia i akcesoria Festool',
        command: () => router.push({
          name: 'Products',
          params: {
            category: 'narzędzia_narzedzie-i-akcesoria-festool'
          }
        })
      }
    ]
  }
]

export const treeMenuItems = ref([
  {
    key: 'podlogi-podklady-listwy',
    categoryDisplayNames: ['Podłogi, podkłady, listwy'],
    label: 'Podłogi, podkłady, listwy',
    children: [
      {
        key: 'podlogi-podklady-listwy_panele-podlogowe',
        categoryDisplayNames: ['Podłogi, podkłady, listwy', 'Panele podłogowe'],
        label: 'Panele podłogowe',
        children: [
          {
            key: 'podlogi-podklady-listwy_panele-podlogowe_swiss-krono',
            categoryDisplayNames: ['Podłogi, podkłady, listwy', 'Panele podłogowe', 'Swiss Krono'],
            label: 'Swiss Krono'
          },
          {
            key: 'podlogi-podklady-listwy_panele-podlogowe_ab-premium',
            categoryDisplayNames: ['Podłogi, podkłady, listwy', 'Panele podłogowe', 'AB Premium'],
            label: 'AB Premium'
          },
          {
            key: 'podlogi-podklady-listwy_panele-podlogowe_kaindl',
            categoryDisplayNames: ['Podłogi, podkłady, listwy', 'Panele podłogowe', 'Kaindl'],
            label: 'Kaindl'
          },
          {
            key: 'podlogi-podklady-listwy_panele-podlogowe_ter-hurne',
            categoryDisplayNames: ['Podłogi, podkłady, listwy', 'Panele podłogowe', 'ter Hürne'],
            label: 'ter Hürne'
          },
          {
            key: 'podlogi-podklady-listwy_panele-podlogowe_krono-original',
            categoryDisplayNames: ['Podłogi, podkłady, listwy', 'Panele podłogowe', 'Krono Original'],
            label: 'Krono Original'
          },
          {
            key: 'podlogi-podklady-listwy_panele-podlogowe_villeroy-boch',
            categoryDisplayNames: ['Podłogi, podkłady, listwy', 'Panele podłogowe', 'Villeroy & Boch'],
            label: 'Villeroy & Boch'
          }
        ]
      },
      {
        key: 'podlogi-podklady-listwy_podlogi_drewniane',
        categoryDisplayNames: ['Podłogi, podkłady, listwy', 'Podłogi drewniane'],
        label: 'Podłogi drewniane'
      },
      {
        key: 'podlogi-podklady-listwy_panele-winylowe-lvt',
        categoryDisplayNames: ['Podłogi, podkłady, listwy', 'Panele winylowe LVT'],
        label: 'Panele winylowe LVT'
      },
      {
        key: 'podlogi-podklady-listwy_listwy',
        categoryDisplayNames: ['Podłogi, podkłady, listwy', 'Listwy'],
        label: 'Listwy',
        children: [
          {
            key: 'podlogi-podklady-listwy_listwy_przypodlogowe',
            categoryDisplayNames: ['Podłogi, podkłady, listwy', 'Listwy', 'Przypodłogowe'],
            label: 'Przypodłogowe'
          },
          {
            key: 'podlogi-podklady-listwy_listwy_dekoracyjne',
            categoryDisplayNames: ['Podłogi, podkłady, listwy', 'Listwy', 'Dekoracyjne'],
            label: 'Dekoracyjne'
          }
        ]
      },
      {
        key: 'podlogi-podklady-listwy_podklady-podlogowe-i-folie-izolacyjne',
        categoryDisplayNames: ['Podłogi, podkłady, listwy', 'Podkłady podłogowe i folie izolacyjne'],
        label: 'Podkłady podłogowe i folie izolacyjne'
      }
    ]
  },
  {
    key: 'drzwi',
    categoryDisplayNames: ['Drzwi'],
    label: 'Drzwi'
  },
  {
    key: 'blaty-kuchenne',
    label: 'Blaty kuchenne',
    categoryDisplayNames: ['Blaty kuchenne']
  },
  {
    key: 'plyty-meblowe',
    label: 'Płyty meblowe',
    categoryDisplayNames: ['Płyty meblowe'],
    children: [
      {
        key: 'plyty-meblowe_plyty-laminowane',
        label: 'Płyty laminowane',
        categoryDisplayNames: ['Płyty meblowe', 'Płyty laminowane'],
        children: [
          {
            key: 'plyty-meblowe_plyty-laminowane_swiss-krono-kolekcja-standardowa',
            label: 'Swiss Krono - Kolekcja Standardowa',
            categoryDisplayNames: ['Płyty meblowe', 'Płyty laminowane', 'Swiss Krono - Kolekcja Standardowa']
          },
          {
            key: 'plyty-meblowe_plyty-laminowane_swiss-krono-kolekcja-symfonia',
            label: 'Swiss Krono - Kolekcja Symfonia',
            categoryDisplayNames: ['Płyty meblowe', 'Płyty laminowane', 'Swiss Krono - Kolekcja Symfonia']
          },
          {
            key: 'plyty-meblowe_plyty-laminowane_swiss-krono-kolekcja-one-world',
            label: 'Swiss Krono - Kolekcja One World',
            categoryDisplayNames: ['Płyty meblowe', 'Płyty laminowane', 'Swiss Krono - Kolekcja One World']
          }
        ]
      },
      {
        key: 'plyty-meblowe_plyty-mdf',
        label: 'Płyty MDF',
        categoryDisplayNames: ['Płyty meblowe', 'Płyty MDF']
      },
      {
        key: 'plyty-meblowe_plyty-plesniowe',
        label: 'Płyty pleśniowe',
        categoryDisplayNames: ['Płyty meblowe', 'Płyty pleśniowe']
      },
      {
        key: 'plyty-meblowe_plyty-akrylowe',
        label: 'Płyty akrylowe',
        categoryDisplayNames: ['Płyty meblowe', 'Płyty akrylowe']
      },
      {
        key: 'plyty-meblowe_plyty-wiorowe',
        label: 'Płyty wiórowe',
        categoryDisplayNames: ['Płyty meblowe', 'Płyty wiórowe']
      },
      {
        key: 'plyty-meblowe_plyty-niezapalne-stop-fire',
        label: 'Płyty niezapalne STOP FIRE',
        categoryDisplayNames: ['Płyty meblowe', 'Płyty niezapalne STOP FIRE']
      },
      {
        key: 'plyty-meblowe_plyty-swisscdf',
        label: 'Płyty SWISSCDF',
        categoryDisplayNames: ['Płyty meblowe', 'Płyty SWISSCDF']
      },
      {
        key: 'plyty-meblowe_sklejki',
        label: 'Sklejki',
        categoryDisplayNames: ['Płyty meblowe', 'Sklejki']
      }
    ]
  },
  {
    key: 'fronty-meblowe',
    label: 'Fronty meblowe',
    categoryDisplayNames: ['Fronty meblowe']
  },
  {
    key: 'okucia-i-akcesoria-meblowe',
    label: 'Okucia i akcesoria meblowe',
    categoryDisplayNames: ['Okucia i akcesoria meblowe'],
    children: [
      {
        key: 'okucia-i-akcesoria-meblowe_prodykty-blum',
        label: 'Produkty Blum',
        categoryDisplayNames: ['Produkty Blum']
      },
      {
        key: 'okucia-i-akcesoria-meblowe_zawiasy-podnosniki-i-systemy-szuflad',
        label: 'Zawiasy, podnośniki i systemy szuflad',
        categoryDisplayNames: ['Produkty Blum', 'Zawiasy, podnośniki i systemy szuflad']
      },
      {
        key: 'okucia-i-akcesoria-meblowe_systemy-do-drzwi-przesuwnych',
        label: 'Systemy do drzwi przesuwnych',
        categoryDisplayNames: ['Produkty Blum', 'Systemy do drzwi przesuwnych']
      },
      {
        key: 'okucia-i-akcesoria-meblowe_klamki',
        label: 'Klamki',
        categoryDisplayNames: ['Produkty Blum', 'Klamki']
      },
      {
        key: 'okucia-i-akcesoria-meblowe_oswietlenie-do-mebli',
        label: 'Oświetlenie do mebli',
        categoryDisplayNames: ['Produkty Blum', 'Oświetlenie do mebli']
      },
      {
        key: 'okucia-i-akcesoria-meblowe_obrzeza-meblowe',
        label: 'Obrzeża meblowe',
        categoryDisplayNames: ['Produkty Blum', 'Obrzeża meblowe']
      },
      {
        key: 'okucia-i-akcesoria-meblowe_sruby-i-inne-elementy-zlaczne',
        label: 'Śruby i inne elementy złączne',
        categoryDisplayNames: ['Produkty Blum', 'Śruby i inne elementy złączne']
      }
    ]
  },
  {
    key: 'materialy-budowlane',
    label: 'Materiały budowlane',
    categoryDisplayNames: ['Materiały budowlane'],
    children: [
      {
        key: 'materialy-budowlane_plyty-osb',
        label: 'Płyty OSB',
        categoryDisplayNames: ['Materiały budowlane', 'Płyty OSB']
      },
      {
        key: 'materialy-budowlane_drewno-i-plyty-izolacyjne',
        label: 'Drewno i płyty izolacyjne',
        categoryDisplayNames: ['Materiały budowlane', 'Drewno i płyty izolacyjne']
      }
    ]
  },
  {
    key: 'materialy-wykonczeniowe',
    label: 'Materiały wykończeniowe',
    categoryDisplayNames: ['Materiały wykończeniowe'],
    children: [
      {
        key: 'materialy-wykonczeniowe_panele-scienne',
        label: 'Panele ścienne',
        categoryDisplayNames: ['Materiały wykończeniowe', 'Panele ścienne']
      },
      {
        key: 'materialy-wykonczeniowe_elementy-dekoracyjne',
        label: 'Elementy dekoracyjne',
        categoryDisplayNames: ['Materiały wykończeniowe', 'Elementy dekoracyjne']
      }
    ]
  },
  {
    key: 'wyposazenie-wnetrz',
    label: 'Wyposażenie wnętrz',
    categoryDisplayNames: ['Wyposażenie wnętrz'],
    children: [
      {
        key: 'wyposazenie-wnetrz_systemy-segregacji-odpadow',
        label: 'Systemy segregacji odpadów',
        categoryDisplayNames: ['Wyposażenie wnętrz', 'Systemy segregacji odpadów']
      },
      {
        key: 'wyposazenie-wnetrz_zlewozmywaki-baterie-i-armatury',
        label: 'Zlewozmywaki, baterie i armatury',
        categoryDisplayNames: ['Wyposażenie wnętrz', 'Zlewozmywaki, baterie i armatury']
      }
    ]
  },
  {
    key: 'urzadzenia-i-srodki-do-konserwacji',
    label: 'Urządzenia i środki do konserwacji',
    categoryDisplayNames: ['Urządzenia i środki do konserwacji'],
    children: [
      {
        key: 'urzadzenia-i-srodki-do-konserwacji_mopy-i-pady-aplikujace',
        label: 'Mopy i pady aplikujące',
        categoryDisplayNames: ['Urządzenia i środki do konserwacji', 'Mopy i pady aplikujące']
      }
    ]
  },
  {
    key: 'narzędzia',
    label: 'Narzędzia',
    categoryDisplayNames: ['Narzędzia'],
    children: [
      {
        key: 'narzędzia_narzedzie-i-akcesoria-festool',
        label: 'Narzędzia i akcesoria Festool',
        categoryDisplayNames: ['Narzędzia', 'Narzędzia i akcesoria Festool']
      }
    ]
  }
]
)

export const navigationItems = ref([
  {
    label: 'Oferta',
    icon: 'pi pi-fw pi-briefcase',
    items: productCategoryNavigationItems
  },
  {
    label: 'Zamówienia',
    icon: 'pi pi-fw pi-money-bill',
    command: () => router.push('/orders')
  },
  {
    label: 'Usługi',
    icon: 'pi pi-fw pi-key'
  },
  {
    label: 'O firmie',
    icon: 'pi pi-fw pi-home',
    command: () => router.push('/about')
  },
  {
    label: 'Oddziały',
    icon: 'pi pi-fw pi-map-marker',
    items: [
      {
        label: 'Abler Toruń'
      },
      {
        label: 'Abler Bydgoszcz'
      },
      {
        label: 'Abler Inowrocław'
      },
      {
        label: 'Abler Brodnica'
      },
      {
        label: 'Abler Kalisz'
      },
      {
        label: 'Abler Płock'
      },
      {
        label: 'Abler Słupsk'
      },
      {
        label: 'Abler Gdynia'
      }
    ]
  },
  {
    label: 'Nowości',
    icon: 'pi pi-fw pi-tags'
  }
])

export const eternalToken = 'eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICI5ZVQxcFA4Ujk3OElLb1lyNFNKdklmdFlmVzFEc05EbjQ3c2Z0UTdiZTlvIn0.eyJleHAiOjE2MzcxNDQxNDQsImlhdCI6MTYwNTYwODE0NCwianRpIjoiZWFiY2M0NWMtYTg4Yi00N2JjLTk5MzItNzJkMzI4OTJkNDkwIiwiaXNzIjoiaHR0cDovLzgzLjEyLjE1NS4yMDI6ODMwMi9hdXRoL3JlYWxtcy9zaG9wIiwiYXVkIjoicmVhbG0tbWFuYWdlbWVudCIsInN1YiI6ImZjNTUwYzRjLTE2OWYtNDY0OS1hYTM4LTk3YTc5YmQyODliMyIsInR5cCI6IkJlYXJlciIsImF6cCI6InNob3AtYmFja2VuZCIsInNlc3Npb25fc3RhdGUiOiI0N2I2NzRlNy0zZTkzLTRlYWMtYTI2MC0xNjczODI1ZDNhY2MiLCJhY3IiOiIxIiwicmVhbG1fYWNjZXNzIjp7InJvbGVzIjpbImFkbWluIl19LCJyZXNvdXJjZV9hY2Nlc3MiOnsicmVhbG0tbWFuYWdlbWVudCI6eyJyb2xlcyI6WyJ2aWV3LWlkZW50aXR5LXByb3ZpZGVycyIsInZpZXctcmVhbG0iLCJtYW5hZ2UtaWRlbnRpdHktcHJvdmlkZXJzIiwiaW1wZXJzb25hdGlvbiIsInJlYWxtLWFkbWluIiwiY3JlYXRlLWNsaWVudCIsIm1hbmFnZS11c2VycyIsInF1ZXJ5LXJlYWxtcyIsInZpZXctYXV0aG9yaXphdGlvbiIsInF1ZXJ5LWNsaWVudHMiLCJxdWVyeS11c2VycyIsIm1hbmFnZS1ldmVudHMiLCJtYW5hZ2UtcmVhbG0iLCJ2aWV3LWV2ZW50cyIsInZpZXctdXNlcnMiLCJ2aWV3LWNsaWVudHMiLCJtYW5hZ2UtYXV0aG9yaXphdGlvbiIsIm1hbmFnZS1jbGllbnRzIiwicXVlcnktZ3JvdXBzIl19fSwic2NvcGUiOiJwcm9maWxlIGVtYWlsIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJuYW1lIjoidGVzdCB0ZXN0IiwicHJlZmVycmVkX3VzZXJuYW1lIjoidGVzdEB3cC5wbCIsImdpdmVuX25hbWUiOiJ0ZXN0IiwiZmFtaWx5X25hbWUiOiJ0ZXN0IiwiZW1haWwiOiJ0ZXN0QHdwLnBsIn0.YQwT6PV8TtGgHhMqOHpEjLHyE9tBNABKMUQYqdiegjNL5O_TBtLMgnigknF31YhOEqFRVULGRVat0o037Y7J-XJES3QGp38CT-587jtuE9YTNyXPU4zx8LkoPOvIu3awJI7pFfaKcDmwWwKagnVYpPmtA8SLySAWCGn4yPm0BJEITQWKZUJXclY7VKgswxXzqpheBU-tLUNBccewmyieBaLsu_gWmhKqlbTTjaCFJebpBStH7sPsW9VdR-kuIqN8cWrTj0VYk_tXgzmxosG6j7jNQCJgphO7_J_CLeAUIhKJNPJ4XhWq3PROPV2TJv2tLtVISsiGwbqU5NoJVJNcig'
