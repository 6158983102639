import authContentTypeHeader from '@/services/auth_content_type_header'
import authHeader from '@/services/auth_header'
import instance from '@/services/api'

export class FaqService {
  async fetchFaqData () {
    try {
      const response = await instance.get('/faq', { headers: authHeader() })
      return Promise.resolve(response.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async sendFaqData (faq) {
    try {
      await instance.post('/faq', faq, { headers: authContentTypeHeader() })
      return Promise.resolve(1)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}
