<template>
  <div class="snackbar show-bar">
    <p>{{ label }}</p>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Snackbar',
  props: {
    label: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.snackbar {
  min-width: 280px;
  background-color: #333;
  color: #fff;
  text-align: left;
  border-radius: 4px;
  padding: 16px;
  position: fixed;
  z-index: 1;
  right: 30px;
  bottom: 30px;
  visibility: visible;
  animation: fadein 0.5s;
  display: block;
  -webkit-box-shadow: 0 0 43px -9px rgba(0,0,0,0.75);
  -moz-box-shadow: 0 0 43px -9px rgba(0,0,0,0.75);
  box-shadow: 0 0 43px -9px rgba(0,0,0,0.75);
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}
</style>
