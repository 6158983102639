import { createApp, ref, watch } from 'vue'
import App from './App.vue'
import InitialApp from './InitialApp.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css'
import './utils/validators'
import './styles/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'
import 'primeflex/primeflex.css'
import { keycloakOptions } from '@/services/keycloakConfig'
import Keycloak from 'keycloak-js'
import PrimeVue from 'primevue/config'
import setupInterceptor from '@/services/setupInterceptor'

// eslint-disable-next-line new-cap
const keycloak = Keycloak(keycloakOptions)

const lsStatus = JSON.parse(window.localStorage.getItem('initializeApp'))
const params = new Proxy(new URLSearchParams(window.location.search), {
  get: (searchParams, prop) => searchParams.get(prop)
})

const isBack = JSON.parse(params.is_back)
const statusValue = ref(lsStatus || false)

export const changeStatus = (value) => {
  statusValue.value = value
  window.location.search = ''
}

const initializeWithKeycloak = () => {
  keycloak.init({ onLoad: 'login-required', checkLoginIframe: false }).then(async (isAuthorized) => {
  // Initialize services
    setupInterceptor(keycloak)

    await store.dispatch('serviceStore/initializeServices')

    const app = createApp(App)
    app.provide(process.env.VUE_APP_KEYCLOAK_PROVIDE_VARIABLE, keycloak)
    app.use(PrimeVue, {
      locale: {
        dayNames: ['Niedziela', 'Poniedziałek', 'Wtorek', 'Środa', 'Czwartek', 'Piątek', 'Sobota'],
        dayNamesShort: ['Ndz', 'Pon', 'Wt', 'Śr', 'Czw', 'Pt', 'Sob'],
        dayNamesMin: ['Ndz', 'Pon', 'Wt', 'Śr', 'Czw', 'Pt', 'Sob'],
        monthNames: ['Styczeń', 'Luty', 'Marzec', 'Kwiecień', 'Maj', 'Czerwiec', 'Lipiec', 'Sierpień', 'Wrzesień', 'Październik', 'Listopad', 'Grudzień'],
        monthNamesShort: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
        chooseYear: 'Wybierz rok',
        chooseMonth: 'Wybierz miesiąc',
        chooseDate: 'Wybierz datę',
        prevDecade: 'Poprzednia dekada',
        nextDecade: 'Następna dekada',
        prevYear: 'Poprzedni rok',
        nextYear: 'Następny rok',
        prevMonth: 'Poprzedni miesiąc',
        nextMonth: 'Następny miesiąc',
        prevHour: 'Poprzednia godzina',
        nextHour: 'Następna godzina',
        prevMinute: 'Poprzednia minuta',
        nextMinute: 'Następna minuta',
        today: 'Dzisiaj',
        weekHeader: 'Tydzień',
        firstDayOfWeek: 0,
        dateFormat: 'dd/mm/yy'
      }
    })
    app.use(store)
    app.component('QuillEditor', QuillEditor)
    app.use(router)
    await app.mount('#app')
  }).catch((e) => {
    document.getElementById('app').innerHTML = '' +
      '<div>Błąd serwera: ' + '' +
      '<strong>' + e + '' +
      '</strong>' + ' ' +
      '</div>'
  })

  setInterval(() => {
    keycloak.updateToken(70).catch(() => {
      store.dispatch('snackbarStore/showMessage', 'Brak połączenia z serwerem.')
    })
  }, 15000)
}

let initialApp

if (statusValue.value !== true || isBack === true) {
  initialApp = createApp(InitialApp, { isBack: isBack })
  initialApp.use(PrimeVue)
  initialApp.use(router)
  initialApp.mount('#app')
} else {
  initializeWithKeycloak()
}

watch(statusValue, (newValue) => {
  if (newValue === true && isBack !== true) {
    initialApp.unmount()
    initializeWithKeycloak()
  }
})
