<template>
  <div class="container p-d-flex p-jc-center p-ac-center p-mt-2">
    <div class="link p-d-flex" v-for="(regulation, index) in footerRegulations" :key="regulation">
        <router-link class="link-wrapper" :to="{ name: 'regulations', params: {regulationId: regulation.id}}">
          {{ regulation.title}}
        </router-link>
        <div class="divider" v-if="index !== footerRegulations.length - 1">|</div>
    </div>
    </div>
</template>

<script>
import { useStore } from 'vuex'
import { ref, onMounted, computed } from 'vue'
import { regulationsCategory } from '@/enums/regulationsEnum'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Footer',

  setup () {
    const store = useStore()
    const regulationsService = store.getters['serviceStore/getRegulationsService']
    const footerRegulations = computed(() => store.getters['regulationsStore/getRegulationsByCategory'](regulationsCategory.Footer))
    const isLoading = ref('')

    onMounted(async () => {
      isLoading.value = true
      try {
        await store.dispatch('regulationsStore/fetchRegulationsCategory', {
          regulationsService: regulationsService,
          category: regulationsCategory.Footer
        })
      } catch (error) {
        await store.dispatch(
          'snackbarStore/showMessage',
          'Wystąpił błąd podczas pobierania stopki, proszę spróbować ponownie!'
        )
      }
      isLoading.value = false
    })

    return {
      footerRegulations
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
    height: 80px;
    flex-wrap: wrap;
    text-indent: 30px;
    background-color: #fff;
    color: rgb(109, 109, 109);
    .link {
      margin: 5px 0 5px 0;
        a{
            text-decoration: none;
            color: rgb(109, 109, 109);
        }
    }
}

@media screen and (max-width: 820px) {
  .container {
    height: 150px;
    .link {
      margin: 10px 0 10px 0;
    }
  }
}

@media screen and (max-width: 330px) {
  .container {
    height: 160px;
    text-indent: 10px;
    .link {
      margin: 12px 0 12px 0;
    }
  }
}
</style>
