import acceptHeader from '@/services/aceppt_header'
import instance from '@/services/api'

export class LogService {
  async fetchLogData (category) {
    try {
      const response = await instance.get(`/enova/logs?category=${category}`, { headers: acceptHeader() })
      return Promise.resolve(response.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}
