export class OrderModel {
  constructor (orderData = {}) {
    this.creditPaymentAmount = orderData.creditPaymentAmount
    this.id = orderData.id
    this.initDate = this.parseDate(orderData.initDate)
    this.lastModificationDate = this.parseDate(orderData.lastModificationDate)
    this.name = orderData.name
    this.ownDelivery = orderData.ownDelivery
    this.shippingPrice = orderData.shippingPrice
    this.shopOrderStatus = orderData.shopOrderStatus
    this.splitPayment = orderData.splitPayment
    this.shopUserName = orderData.shopUserName
    this.totalAmount = orderData.totalAmount
    this.resumeOnlinePaymentId = orderData.resumeOnlinePaymentId
    this.resumeOrderId = orderData.resumeOrderId
    this.unavailableOrderId = orderData.unavailableOrderId
    this.invoiceId = orderData.invoiceId
    this.orders = []
  }

  parseDate (date) {
    date = new Date(date)
    return date
  }
}
