import { ref, computed } from 'vue'

export const collapsed = ref(!(window.innerWidth > 1200))
export const toggleDrawer = () => {
  (collapsed.value = !collapsed.value)
  width.value = `${
    collapsed.value ? DRAWER_WIDTH_COLLAPSED : DRAWER_WIDTH
  }px`
}

export const DRAWER_WIDTH = 300
export const DRAWER_WIDTH_COLLAPSED = 100
const width = ref(window.innerWidth >= 481 ? window.innerWidth > 1200 ? '300px' : '100px' : '0')

const mediaQueryWide = window.matchMedia('(min-width: 1200px)')
const mediaQuery = window.matchMedia('(min-width: 768px)')
mediaQueryWide.addEventListener('change', () => handleTabletChange(mediaQueryWide))
mediaQuery.addEventListener('change', () => handleTabletChange(mediaQuery))

function handleTabletChange (e) {
  if (e.matches && e.media === '(min-width: 768px)') {
    collapsed.value = true
  } else if (e.matches && e.media === '(min-width: 1200px)') {
    collapsed.value = false
  } else if (!e.matches && e.media === '(min-width: 1200px)') {
    collapsed.value = true
  } else if (!e.matches && e.media === '(min-width: 768px)') {
    width.value = '0'
    return
  }
  width.value = `${
    collapsed.value ? DRAWER_WIDTH_COLLAPSED : DRAWER_WIDTH
  }px`
}

export const drawerWidth = computed(() => width.value)
