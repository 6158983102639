import axiosInstance from './api'

const setup = (keycloak) => {
  axiosInstance.interceptors.request.use(
    (config) => {
      if (keycloak) {
        config.headers.Authorization = 'Bearer ' + keycloak.token
      }
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )
}

export default setup
