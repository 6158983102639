import { LogModel } from '@/models/logModel'

const state = {
  logs: []
}

const getters = {
  getLogs (state) {
    return state.logs
  }
}

const actions = {
  fetchAllLogs: async ({ commit, dispatch }, payload) => {
    try {
      const response = await payload.logService.fetchLogData(payload.category)
      commit('FETCH_LOGS', response)
    } catch (error) {
      await dispatch(
        'snackbarStore/showMessage',
        'Wystąpił błąd, proszę spróbować ponownie',
        { root: true }
      )
    }
  }
}

const mutations = {
  FETCH_LOGS: (state, logs) => {
    state.logs = []
    for (const log of logs) {
      state.logs.push(new LogModel(log))
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
