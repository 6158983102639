export class FetchProductsQueryModel {
  constructor (query) {
    this.abrasionClass = query.abrasionClass
    this.antibacterial = query.antibacterial
    this.bearingCapacity = query.bearingCapacity
    this.collection = query.collection
    this.color = query.color
    this.colorOfLight = query.colorOfLight
    this.diameter = query.diameter
    this.to = query.end
    this.expanded = query.expanded
    this.guaranteePeriod = query.guaranteePeriod
    this.headShape = query.headShape
    this.height = query.height
    this.hiddenWiring = query.hiddenWiring
    this.intendedUse = query.intendedUse
    this.kit = query.kit
    this.length = query.commodity_length
    this.lock = query.lock
    this.pathPhrase = query.pathPhrase !== undefined ? query.pathPhrase : ''
    this.material = query.material
    this.moistureResistance = query.moistureResistance
    this.openingAngle = query.openingAngle
    this.pathId = query.pathId
    this.phrase = query.phrase !== undefined ? query.phrase : ''
    this.powerFactor = query.powerFactor
    this.producer = query.producer
    this.profile = query.profile
    this.silentClosing = query.silentClosing
    this.socket = query.socket
    this.spacing = query.spacing
    this.from = query.start
    this.structure = query.structure
    this.symbol = query.symbol
    this.thickness = query.thickness
    this.thread = query.thread
    this.type = query.type
    this.waterResistance = query.waterResistance
    this.width = query.width
    this.price = query.price
    this.availability = query.availability
    this.isAvailability = query.isAvailability
    this.commoditySort = query.commoditySort
  }
}
