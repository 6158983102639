export class NewsletterModel {
  constructor (newsletterData = {}) {
    this.id = newsletterData.id
    this.name = newsletterData.name
    this.sentDate = newsletterData.sentDate
    this.initSendDate = newsletterData.initSendDate
    this.sentAmount = newsletterData.sentAmount
    this.totalAmount = newsletterData.totalAmount
    this.status = newsletterData.status
    this.categories = newsletterData.categories
    this.header = newsletterData.header
    this.content = newsletterData.content
    this.footer = newsletterData.footer
    this.templateId = newsletterData.templateId
  }
}
