import { LoyaltyProgramService } from '@/services/loyaltyProgramService'
import { ArticleService } from '@/services/articleService'
import { CarouselService } from '@/services/carouselService'
import { ChatService } from '@/services/chatService'
import { FaqService } from '@/services/faqService'
import { NewsletterService } from '@/services/newsletterService'
import { PaymentService } from '@/services/paymentService'
import { RegulationsService } from '@/services/regulationsService'
import { ShoppingCartService } from '@/services/shoppingCartService'
import { UserService } from '@/services/userService'
import { AddressService } from '@/services/addressService'
import { ProductsService } from '@/services/productsService'
import { DepartmentService } from '@/services/departmentService'
import { LogService } from '@/services/logService'

const state = {
  addressService: null,
  departmentService: null,
  articleService: null,
  carouselService: null,
  chatService: null,
  faqService: null,
  loyaltyService: null,
  newsletterService: null,
  paymentService: null,
  productsService: null,
  regulationsService: null,
  shoppingCartService: null,
  userService: null,
  registerService: null,
  logService: null
}

const getters = {
  getAddressService (state) {
    return state.addressService
  },
  getDepartmentService (state) {
    return state.departmentService
  },
  getArticleService (state) {
    return state.articleService
  },
  getCarouselService (state) {
    return state.carouselService
  },
  getChatService (state) {
    return state.chatService
  },
  getFaqService (state) {
    return state.faqService
  },
  getLoyaltyProgramService (state) {
    return state.loyaltyService
  },
  getNewsletterService (state) {
    return state.newsletterService
  },
  getPaymentService (state) {
    return state.paymentService
  },
  getProductsService (state) {
    return state.productsService
  },
  getRegulationsService (state) {
    return state.regulationsService
  },
  getShoppingCartService (state) {
    return state.shoppingCartService
  },
  getUserService (state) {
    return state.userService
  },
  getLogService (state) {
    return state.logService
  }
}

const actions = {
  initializeServices: (context) => {
    context.commit('ADD_SERVICES')
  }
}

const mutations = {
  ADD_SERVICES: (state) => {
    state.addressService = new AddressService()
    state.departmentService = new DepartmentService()
    state.articleService = new ArticleService()
    state.carouselService = new CarouselService()
    state.chatService = new ChatService()
    state.faqService = new FaqService()
    state.loyaltyService = new LoyaltyProgramService()
    state.newsletterService = new NewsletterService()
    state.paymentService = new PaymentService()
    state.productsService = new ProductsService()
    state.regulationsService = new RegulationsService()
    state.shoppingCartService = new ShoppingCartService()
    state.userService = new UserService()
    state.logService = new LogService()
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
