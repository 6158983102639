export const filterCharacteristicNameEnum = {
  price: 'Cena',
  availability: 'Dostępność',
  waterResistance: 'Wodoodporność',
  lock: 'Zamek',
  spacing: 'Rozstaw',
  color: 'Kolor',
  thickness: 'Grubość',
  abrasionClass: 'Klasa ścieralności',
  headShape: 'Kształt główki',
  socket: 'Gniazdo',
  profile: 'Profil',
  commodity_length: 'Długość',
  antibacterial: 'Antybakteryjność',
  isAvailability: 'Dostępność',
  collection: 'Kolekcja',
  thread: 'Gwint',
  type: 'Typ',
  structure: 'Struktura',
  moistureResistance: 'Wilgocioodporność',
  openingAngle: 'Kąt otwarcia',
  material: 'Materiał',
  diameter: 'Średnica',
  colorOfLight: 'Barwa światła',
  kit: 'Komplet',
  width: 'Szerokość',
  producer: 'Producent',
  bearingCapacity: 'Nośność',
  silentClosing: 'Cichy domyk',
  height: 'Wysokość',
  powerFactor: 'Współczynnik mocy',
  hiddenWiring: 'Ukryte okablowanie',
  symbol: 'Symbol',
  intendedUse: 'Przeznaczenie',
  guaranteePeriod: 'Okres Gwarancji'
}
