<template>
<div class="container">
  <div v-if='!registerView' class="card">
    <Card style="width: 25rem; margin-bottom: 2em; text-align: center">
      <template #title>
        <div style="border-bottom: 1px solid #000; padding: 10px 0 20px 0">
          <img src="../src/assets/abler_logo.png" alt="logo">
        </div>
      </template>
      <template #content>
        <div class='start-View-Buttons'>
          <Button class="p-button p-component p-mt-4 p-mb-4" style="width: 160px; padding-left: 40px;" @click=changeToLogin>Logowanie</Button>
          <Button class="p-button p-component p-mt-4 p-mb-4" style="width: 160px; padding-left: 40px;" @click=changeToRegister>Rejestracja</Button>
        </div>
      </template>
    </Card>
  </div>
  <div v-else class="register-card">
    <Card style="max-width: 50rem; margin-bottom: 2em; text-align: center">
      <template #title>
        <div style="border-bottom: 1px solid #000; padding: 10px 0 20px 0">
          <img src="../src/assets/abler_logo.png" alt="logo">
        </div>
        <h4 class="p-text-center p-mt-5 p-mb-4 p-text-uppercase">Zgłoszenie rejestracji użytkownika</h4>
        <div class="p-mb-2 p-mt-2 p-d-flex" style="width: 100%">
          <Form class="p-d-flex p-flex-sm-wrap p-flex-nowrap p-flex-sm-row p-flex-column p-jc-sm-around p-ai-sm-stretch p-ai-center" style="width: 100%" @submit="submitUserRegistration" @keydown.enter.prevent.self refInput="e" id="myform">
            <div class="input-wrapper">
              <LabeledInput name="name" placeholder="Imię" v-model:modelValue="currentlyAdding.name" rules="required|text"/>
            </div>
            <div class="input-wrapper">
              <LabeledInput name="surname" placeholder="Nazwisko" v-model:modelValue="currentlyAdding.surname" rules="required|text"/>
            </div>
            <div class="input-wrapper">
              <LabeledInput name="position" placeholder="Stanowisko" v-model:modelValue="currentlyAdding.position" rules="required|textSpace"/>
            </div>
            <div class="input-wrapper">
              <LabeledInput name="email"  placeholder="E-mail" v-model:modelValue="currentlyAdding.email" rules="required|email"/>
            </div>
            <div class="input-wrapper">
              <LabeledInput name="phoneNumber" placeholder="Numer telefonu" v-model:modelValue="currentlyAdding.phoneNumber" rules="required|phoneNumber"/>
            </div>
            <div class="input-wrapper">
              <LabeledInput name="companyName" placeholder="Nazwa firmy" v-model:modelValue="contractor.name" rules="required|noSpecial"/>
            </div>
            <div class="input-wrapper">
              <LabeledInput name="nip" placeholder="NIP" v-model:modelValue="contractor.nip" rules="required|fiscalCode"/>
            </div>
            <div class="input-wrapper">
              <LabeledInput name="address" placeholder="Adres" v-model:modelValue="contractor.address" rules="required|noSpecial"/>
            </div>
            <div class="input-wrapper p-mb-4">
              <Dropdown
                v-model:modelValue="currentlyAdding.contractorDepartmentId"
                :options='departmentsOptions'
                optionLabel="name"
                placeholder='Oddział'
                class='dropdown'
                :filter="true"
                :showClear="false"
              >
              <template #value="slotProps">
                  <div v-if="slotProps.value">
                    <div>{{ slotProps.value.name }}</div>
                  </div>
                  <span v-else>
                    {{ slotProps.placeholder }}
                  </span>
              </template>
              <template #option="slotProps">
                <div>{{ slotProps.option.name }}</div>
              </template>
              </Dropdown>
            </div>
            <div class="input-wrapper p-my-3" :class='!checkRegulation ? "regulation" : ""' style='text-align: left;'>
              <Checkbox id="zgoda" v-model='currentlyAdding.isRegulationAccepted' :binary='true' :required='true' rules="accept"/>
                <label v-if="!isFolded" for="zgoda">
                  Wyrażam zgodę na używanie przez Abler sp. z o.o. środków komunikacji elektronicznej
                  <span style='color: #B72937; cursor: pointer;' @click='isFolded = !isFolded'>
                    rozwiń...
                  </span>
                </label>
                <label v-else>
                  Wyrażam zgodę na używanie przez Abler sp. z o.o. środków komunikacji elektronicznej oraz telekomunikacyjnych urządzeń końcowych w celu przesyłania mi informacji handlowych oraz prowadzenia marketingu (np. newsletter, wiadomości SMS, kontakt telefoniczny) przez Abler sp. z o.o., podmioty powiązane i partnerów biznesowych. Przyjmuję do wiadomości, że Abler sp. z o.o. wykorzystuje moje dane osobowe zgodnie z
                  <a href='https://bit.ly/3pu8GnV' target='_blank'>
                    Regulaminem
                  </a>
                  <p style='color: #B72937; cursor: pointer;' @click='isFolded = !isFolded'>
                    zwiń...
                  </p>
                </label>
            </div>
            <div class="button-section p-d-flex p-justify-end p-mb-4">
              <Button class="p-button p-component" @click=goBack >{{ isBack ? 'Logowanie' : 'Wróć'}}</Button>
            </div>
            <div class='button-section p-d-flex p-justify-start p-mb-4'>
              <Button style="border-radius: 0px" type="submit" :disabled="isSaving" :loading="isSaving" label="Wyślij" @click="permissionToSend()"/>
            </div>
          </Form>
        </div>
          <Message v-if="showWarning" severity="warn" :life="10000" :sticky="false">Uzupełnij poprawnie wszystkie pola!</Message>
          <Message v-if="showSuccess" severity="success" :life="20000" :sticky="false">Dziękujemy za zgłoszenie Twojej rejestracji do platformy B2B. Niebawem skontaktuje się z Tobą przedstawiciel z Twojego regionu.</Message>
          <Message v-if="showWrong" severity="warn" :life="5000" :sticky="false">Coś poszło nie tak, lub został nie dawno złożony proces rejestracji, który jest w trakcie realizacji. Spróbuj ponownie później.</Message>
          <Message v-if="showRegulationError" severity="warn" :life="5000" :sticky="false">W celu ukończenia procesu rejestracji wymagana jest zgoda na przetwarzanie danych osobowych.</Message>
      </template>
    </Card>
  </div>
</div>
<div class="filler"></div>
</template>

<script>
import { ref, reactive, onMounted } from 'vue'
import Card from 'primevue/card'
import Button from 'primevue/button'
import Message from 'primevue/message'
import Dropdown from 'primevue/dropdown'
import Checkbox from 'primevue/checkbox'
import { Form, useForm } from 'vee-validate'
import LabeledInput from '@/components/LabeledInput.vue'
import { changeStatus } from '@/main'
import { RegisterService } from '@/services/registerService'

export default {
  name: 'InitialApp',
  props: {
    isBack: {
      type: Boolean,
      required: false
    }
  },
  components: {
    Form,
    Card,
    Button,
    LabeledInput,
    Message,
    Dropdown,
    Checkbox
  },
  setup (props) {
    const registerService = new RegisterService()
    const registerView = ref(false)
    const showWarning = ref(false)
    const showSuccess = ref(false)
    const showWrong = ref(false)
    const showWait = ref(false)
    const showRegulationError = ref(false)
    const isSaving = ref(false)
    const isFolded = ref(false)
    const checkRegulation = ref(true)

    const departmentsOptions = ref([])
    const e = ref(null)

    onMounted(() => {
      registerView.value = props.isBack
      if (registerView.value === true) {
        changeToRegister()
      }
    })

    const { handleSubmit, validate } = useForm()

    const InitialValuesContractor = {
      name: '',
      nip: '',
      address: ''
    }

    const InitialValuesCurrentlyAdding = {
      name: '',
      surname: '',
      position: '',
      email: '',
      phoneNumber: '',
      contractorDepartmentId: '',
      isRegulationAccepted: false
    }

    const contractor = reactive({ ...InitialValuesContractor })
    const currentlyAdding = reactive({ ...InitialValuesCurrentlyAdding })

    const changeToLogin = () => {
      changeStatus(true)
      window.localStorage.setItem('initializeApp', 'true')
    }

    const changeToRegister = async () => {
      registerView.value = true
      try {
        const departmentsData = await registerService.fetchDepartments()
        departmentsOptions.value = departmentsData.data.departments
      } catch (e) {
        departmentsOptions.value = []
      }
    }

    const goBack = () => {
      if (props.isBack) {
        changeToLogin()
      } else {
        registerView.value = false
      }
    }

    const permissionToSend = async () => {
      const { valid } = await validate()
      if (!valid) {
        showWarning.value = true
      }
    }

    const submitUserRegistration = handleSubmit(async (_, { resetForm }) => {
      if (!currentlyAdding.isRegulationAccepted) {
        showRegulationError.value = true
        checkRegulation.value = false
      } else {
        if (currentlyAdding.contractorDepartmentId === '' || currentlyAdding.contractorDepartmentId === null) {
          showWarning.value = true
          return false
        }
        try {
          isSaving.value = true
          showWarning.value = false
          currentlyAdding.contractorDepartmentId = currentlyAdding.contractorDepartmentId.id
          await registerService.addNewUser({ ...currentlyAdding, contractor })
          resetForm()
          Object.assign(contractor, InitialValuesContractor)
          Object.assign(currentlyAdding, InitialValuesCurrentlyAdding)
          showSuccess.value = true
          isSaving.value = false
        } catch (error) {
          resetForm()
          Object.assign(contractor, InitialValuesContractor)
          Object.assign(currentlyAdding, InitialValuesCurrentlyAdding)
          showWrong.value = true
          isSaving.value = false
        }
      }
    })

    return {
      registerView,
      changeToLogin,
      changeToRegister,
      goBack,
      currentlyAdding,
      submitUserRegistration,
      isSaving,
      permissionToSend,
      contractor,
      e,
      showWarning,
      showSuccess,
      showWrong,
      showWait,
      showRegulationError,
      departmentsOptions,
      isFolded,
      checkRegulation
    }
  }
}
</script>

<style lang="scss" scoped>
.register-card {
  @media(max-width: 576px) {
    margin-top: 300px;
    width: 90% !important;
    ::v-deep(.p-card) {
      width: 100% !important;
    }
  }
}
.card {
  @media(max-width: 576px) {
    width: 90% !important;
    ::v-deep(.p-card) {
      width: 100% !important;
    }
  }
}
::v-deep(.p-card .p-card-title) {
  font-size: 1rem;
}
.registration-wrapper{
  background-color: white;
}
.input-wrapper {
  width: 40%;

  @media(max-width: 576px) {
    width: 80%;
    justify-content: center !important;
  }
}
.regulation {
  border: 1px solid #B72937;
  padding: 0.5em
}
.dropdown {
  width: 100%;
  height: 39px;
  margin-top: 17px;
  padding-top: 1%;
  padding-bottom: 1%;
  border: 1px solid #B72937;
  border-radius: 0px !important;
  text-align: left;
  font-size: 0.8rem;
  span {
    font-size: 0.8rem;
  }
  div {
    font-size: 0.8rem;
  }
  ::v-deep(.p-inputtext) {
    font-style: none;
    font-size: 0;
  }
}
.button-section{
  width: 40%;
  @media(max-width: 576px) {
    width: 80%;
    justify-content: center !important;
  }
}
.center {
  @media(max-width: 500px) {
  text-align: center;
    width: 100%;
  }
}
.container {
  display: flex;
  width: 100vw;
  height: 100vh;
  margin: 0 auto 0 auto;
  justify-content: center;
  align-items: center;
  background-image: url('../src/assets/wood.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.filler {
  @media(max-width: 576px) {
    width: 100vw;
    height: 50vh;
    margin: 0 auto 0 auto;
    justify-content: center;
    align-items: center;
    background-image: url('../src/assets/wood.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}
.start-View-Buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.message {
  text-align: left;
  display: flex;
  width: 80%;
  align-items: start;
  flex-direction: column;
}
.form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.input {
  width: 80%;
  margin: 10px 0 10px 0;
}
.p-button {
  background-color: #B72937;
  border: 1px solid #B72937;
  border-radius: 0;
  color: white;
  padding: 12px 30px;
}
.p-button:hover {
  background-color: white !important;
  border: 1px solid #B72937 !important;
  color: #B72937 !important;
}
.forgot-password:hover {
  border: none !important;
  color: none !important;
}
</style>
